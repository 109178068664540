import React from 'react';
import { useNavigate } from 'react-router-dom';
import { encryptKeys, encryptStorage, getRouteBasedOnRoles } from "../../constant";
import NoRecordsFoundComponent from '../../reusable_components/no_records_found_component';
import { useContext } from "react";
import { overallContext } from "../../context/Context";

/**PS_08 -PS_10 Renders the applications based on the user privilege and navigates to the default page based on the application choosen */
const Applications: React.FC = () => {
    const navigate = useNavigate();

    // Get user details from storage
    const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
    const userDetails = JSON?.parse(userDetailsString);
    const userApps = userDetails?.appRole || [];
    const { setCurrentApplication } = useContext(overallContext);

    const handleAppClick = (appAbbreviation: string) => {
        const userRoles = userDetails.appRole?.filter((role: any) => role.abbreviation === appAbbreviation)?.map((role: any) => role.role_name);
        setCurrentApplication(appAbbreviation);
        encryptStorage.setItem(encryptKeys.currentApplication,appAbbreviation);
        const route = getRouteBasedOnRoles(appAbbreviation, userRoles);
        navigate(route);
    }

    return (
        <div className="container-fluid">
            <div className="p-3">
                <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href=" ">Admin</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Applications</li>
                    </ol>
                </nav>
                <h2 className="font-18 font-bold">Applications</h2>
                <div className="row mt-3">
                    {userApps?.length === 0 && (
                        <NoRecordsFoundComponent
                            colspan={4}
                            mainContent="No Applications Found"
                            subContent="Applications will be listed once assigned by admin"
                            showTdTag = {false}
                            columnHeight='40'
                        />)
                    }
                    {userApps?.length > 1 && userApps?.map((app: any) => (
                        <div key={app.abbreviation} className="col-md-3">
                            <div
                                className="application-card cursor-pointer"
                                onClick={() => handleAppClick(app.abbreviation)}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={`img/lynxis-${app.abbreviation?.toLowerCase()}-logo.svg`} alt={`${app.abbreviation} Logo`} />
                                </div>
                                <div className="application-inner-card mt-4 text-center">
                                    <h3 className="font-16 font-bold">{app.abbreviation}</h3>
                                    <p className="color-grey-v4 font-14 font-semibold mb-0">{app.app_name}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Applications;