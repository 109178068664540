/* eslint-disable no-lone-blocks */
// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from 'react';

// /**SQ_EX_35.0 - Component to render the color and time second */

// const CountdownTimer = ({ receivedTime, normalTime, moderateTime }: any) => {
//     const [seconds, setSeconds] = useState<number>(receivedTime);
//     const [level, setLevel] = useState('low');
//     useEffect(() => {

//         const intervalId = setInterval(() => {
//             setSeconds((prevSeconds) => {
//                 const currentTime = prevSeconds + 1;
//                 if (currentTime < normalTime) {
//                     setLevel('low');
//                 } else if (currentTime >= normalTime && currentTime < moderateTime) {
//                     setLevel('medium');
//                 } else {
//                     setLevel('high');
//                 }
//                 return currentTime
//             });
//             let currentTime = seconds + 1

//             if (currentTime < normalTime) {
//                 setLevel('low');
//             } else if (currentTime >= normalTime && currentTime < moderateTime) {
//                 setLevel('medium');
//             } else {
//                 setLevel('high');
//             }

//         }, 1000);

//         return () => clearInterval(intervalId); // Cleanup interval on unmount
//     }, [receivedTime]);
//     return (
//         <>
//             <td className="align-middle">
//                 <span className={`progress-timer ${level}  d-flex align-items-center gap-1`}>
//                     <img src="/img/timer-icon.svg" alt="timer" width={10} />
//                     <span className="font-bold color-white">{seconds} sec</span>
//                 </span>
//             </td>
//         </>
//     );
// };
// const Timer = ({ receivedTime, normalTime, moderateTime }: any) => {
//     const calculateDifference = (time: string) => {
//         const pastTimeStr = time;
//         const pastTime: any = new Date(pastTimeStr);
//         // Current UTC time
//         const currentTime: any = new Date();
//         // Calculate the difference in milliseconds
//         const timeDifference = currentTime - pastTime;
//         // Convert milliseconds to seconds
//         const differenceInSeconds = Math.floor(timeDifference / 1000);
//         return differenceInSeconds;
//     };
//     return (
//         <>
//             <CountdownTimer receivedTime={calculateDifference(receivedTime)} normalTime={normalTime} moderateTime={moderateTime} />
//         </>
//     )
// }
// export default Timer;

import React, { useEffect, useState } from 'react';

/**SQ_EX_35.0 - Component to render the color and time second */

const CountdownTimer = ({ receivedTime, normalTime, moderateTime, isMock }: any) => {
    const [seconds, setSeconds] = useState<number>(receivedTime);
    const [level, setLevel] = useState('low');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds((prevSeconds) => {
                let currentTime = prevSeconds + 1;

                if (isMock) {
                    currentTime = currentTime >= 10 ? 0 : currentTime;
                }

                if (currentTime < normalTime) {
                    setLevel('low');
                } else if (currentTime >= normalTime && currentTime < moderateTime) {
                    setLevel('medium');
                } else {
                    setLevel('high');
                }
                return currentTime;
            });
        }, 1000);

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [receivedTime, normalTime, moderateTime, isMock]);

    return (
        <td className="align-middle">
            <span className={`progress-timer ${level}  d-flex align-items-center gap-1`}>
                <img src="/img/timer-icon.svg" alt="timer" width={10} />
                <span className="font-bold color-white">{seconds} sec</span>
            </span>
        </td>
    );
};

const Timer = ({ receivedTime, normalTime, moderateTime, isMock }: any) => {
    const calculateDifference = (time: string) => {
        const pastTimeStr = time;
        const pastTime: any = new Date(pastTimeStr);
        // Current UTC time
        const currentTime: any = new Date();
        // Calculate the difference in milliseconds
        const timeDifference = currentTime - pastTime;
        // Convert milliseconds to seconds
        const differenceInSeconds = Math.floor(timeDifference / 1000);
        return differenceInSeconds;
    };

    return (
        <CountdownTimer 
            receivedTime={calculateDifference(receivedTime)} 
            normalTime={normalTime} 
            moderateTime={moderateTime} 
            isMock={isMock} 
        />
    );
};

export default Timer;

// Example usage
{/* <Timer 
    receivedTime={workItem.startedOn} 
    normalTime={workItem.expectedProcessingTimeSec} 
    moderateTime={workItem.maxProcessingTimeSec} 
    isMock={true} 
/> */}