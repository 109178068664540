/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { ShiftScroll } from "../../reusable_components/shift_scroll_component";
import { ShiftOverride } from "../../Interfaces/operations_interface";
import { getBreakShiftAndTimeZonesService, getScheduleService, updateScheduleService } from "../../services/operator_service";
import ToastMessage from "../../reusable_components/toast";

export function EditScheduleComponent(props: any) {
    const userUUID: any = props?.props

    const [scheduleData, setScheduleData] = useState<any>({
        user_uuid: "",
        time_zone_uuid: "",
        time_zone: "",
        shift_template_uuid: "",
        shift_name: "",
        break_template_uuid: "",
        break_name: "",

        effective_from: "",
        effective_to: "",
        original_shift_start: "",
        original_shift_end: "",
        override_shift_start: "",
        override_shift_end: "",
        break_details: [
            {
                original_break_start: "",
                original_duration_minutes: 0,
                override_break_start: "",
                override_duration_minutes: 0
            }
        ],
        break_override_details: []
    })

    const [shiftTemplates, setShiftTemplates] = useState<any>(([
        {
            shift_template_uuid: "",
            shift_name: "",
            shift_start_time: "",
            shift_end_time: "",
            time_zone_uuid: ""
        }
    ]));

    const [breakTemplates, setBreakTemplates] = useState<any>([
        {
            "break_template_uuid": "",
            "break_name": "",
            "break_timings": [
                {
                    "break_timing_uuid": "",
                    "break_start_time": "",
                    "duration_minutes": 0,
                }
            ]
        }
    ]);

    const [timeZones, setTimeZones] = useState<any>([
        {
            time_zone_uuid: "",
            time_zone_name: ""
        }
    ]);

    const [updateScheduleData, setUpdateScheduleData] = useState<any>(scheduleData);
    const [createBreakTiming, setCreateBreakTiming] = useState<any>({
        override_break_start: "",
        original_break_start: "",
        original_duration_minutes: "",
        override_duration_minutes: 0
    });
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editBreak, setEditBreak] = useState<any>(null);

    // Add these to your existing state declarations
    const [sortField, setSortField] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>();
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    useEffect(() => {
        getSchedule()
        getShiftBreakAndTimeZonesData()
    }, [])

    const getSchedule = async () => {
        try {
            const response: any = await getScheduleService(userUUID);
            if (response?.status === 200) {
                setScheduleData(response?.data?.data)
                setUpdateScheduleData(response?.data?.data)
            }
            else { handleShowToast(true, "Error", response?.data?.message); }
        }
        catch (error: any) {
            console.error(error)
            handleShowToast(true, "Error", error.message);
        }
    }

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    useEffect(() => {
        const hasChanges = hasBreakTemplateChanges(updateScheduleData, scheduleData);
        setIsButtonDisabled(hasChanges);
    }, [updateScheduleData, scheduleData]);

    const hasBreakTemplateChanges = (
        updateScheduleData: any,
        scheduleData: any
    ): boolean => {
        let updatedBreakTimings = updateScheduleData?.break_override_details?.length > 0 
            ? updateScheduleData?.break_override_details 
            : updateScheduleData?.break_details;
        let breakTimings = scheduleData?.break_override_details?.length > 0 
            ? scheduleData?.break_override_details 
            : scheduleData?.break_details;
    
        // Check shift times and templates
        if (updateScheduleData?.shift_template_uuid !== scheduleData?.shift_template_uuid ||
            updateScheduleData?.break_template_uuid !== scheduleData?.break_template_uuid) {
            return true;
        }
    
        const compareShiftTimes = (update: string, original: string) => {
            return getBreakTimingValue(update) !== getBreakTimingValue(original);
        };
    
        if (updateScheduleData?.override_shift_start) {
            if (compareShiftTimes(updateScheduleData.override_shift_start, scheduleData?.override_shift_start || scheduleData?.original_shift_start) ||
                compareShiftTimes(updateScheduleData.override_shift_end, scheduleData?.override_shift_end || scheduleData?.original_shift_end)) {
                return true;
            }
        }
    
        // Check if break_timings arrays have different lengths
        if (breakTimings?.length !== updatedBreakTimings?.length) {
            return true;
        }
    
        // Create a function to generate a unique key for each break timing
        const getBreakKey = (breakTiming: any) => {
            const startTime = getBreakTimingValue(breakTiming.override_break_start || breakTiming.original_break_start);
            const duration = breakTiming.override_duration_minutes || breakTiming.original_duration_minutes;
            return `${startTime}-${duration}`;
        };
    
        // Create sets of break keys for comparison
        const updatedBreakSet : any = new Set(updatedBreakTimings?.map(getBreakKey));
        const originalBreakSet = new Set(breakTimings?.map(getBreakKey));
    
        // Compare the sets
        if (updatedBreakSet.size !== originalBreakSet.size) {
            return true;
        }
    
        for (let key of updatedBreakSet) {
            if (!originalBreakSet.has(key)) {
                return true;
            }
        }
    
        // If no differences found, return false
        return false;
    };

    const getShiftBreakAndTimeZonesData = async () => {
        const payload = {
            break_details: true,
            shift_details: true,
            time_zone_details: true
        }
        try {
            const response: any = await getBreakShiftAndTimeZonesService(payload)
            if (response?.status === 200) {
                setBreakTemplates(response?.data?.data?.break_templates);
                setShiftTemplates(response?.data?.data?.shift_templates);
                setTimeZones(response?.data?.data?.time_zones)
            }
            else { handleShowToast(true, "Error", response?.data?.message); }
        }
        catch (error: any) {
            console.error(error)
            handleShowToast(true, "Error", error.message);
        }
    }

    const handleShiftTemplateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const shiftTemplateUuid = event.target.value;
        const selectedShift = shiftTemplates.find((st: any) => st.shift_template_uuid === shiftTemplateUuid);
        if (selectedShift) {
            const timeZone = timeZones.find((tz: any) => tz.time_zone_uuid === selectedShift.time_zone_uuid);
            setUpdateScheduleData((prev: any) => ({
                ...prev,
                shift_template_uuid: selectedShift.shift_template_uuid,
                shift_name: selectedShift.shift_name,
                override_shift_start: selectedShift.shift_start_time,
                override_shift_end: selectedShift.shift_end_time,
                time_zone_uuid: selectedShift.time_zone_uuid,
                time_zone: timeZone ? timeZone.time_zone_name : ''
            }));
        }
    };

    const handleBreakTemplateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const breakTemplateUuid = event.target.value;
        const selectedBreak = breakTemplates.find((bt: any) => bt.break_template_uuid === breakTemplateUuid);
        if (selectedBreak) {
            const newBreakDetails = selectedBreak.break_timings.map((timing: any) => ({
                original_break_start: timing.break_start_time,
                original_duration_minutes: timing.duration_minutes,
                override_break_start: timing.break_start_time,
                override_duration_minutes: timing.duration_minutes
            }));

            if (updateScheduleData.usbo_uuid) {
                setUpdateScheduleData((prev: any) => ({
                    ...prev,
                    break_template_uuid: selectedBreak.break_template_uuid,
                    break_name: selectedBreak.break_name,
                    break_override_details: newBreakDetails
                }));
            } else {
                setUpdateScheduleData((prev: any) => ({
                    ...prev,
                    break_template_uuid: selectedBreak.break_template_uuid,
                    break_name: selectedBreak.break_name,
                    break_details: newBreakDetails
                }));
            }
        }
    };

    const handleBreakTimingChange = (index: number, editedBreak: any) => {
        setUpdateScheduleData((prevData: any) => {
            const breakKey = prevData.break_override_details.length > 0 ? 'break_override_details' : 'break_details';
            const updatedBreaks = prevData[breakKey].map((timing: any, i: number) =>
                i === index ? { ...timing, ...editedBreak } : timing
            );
            return {
                ...prevData,
                [breakKey]: updatedBreaks
            };
        });
        setEditIndex(null);
    };

    const deleteBreakTiming = (index: number) => {
        setUpdateScheduleData((prevData: any) => {
            const breakKey = prevData.break_override_details.length > 0 ? 'break_override_details' : 'break_details';
            const updatedBreaks = prevData[breakKey].filter((_: any, i: number) => i !== index);
            return {
                ...prevData,
                [breakKey]: updatedBreaks
            };
        });
    };

    const onChangeTimingRequestData = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        setCreateBreakTiming((prev: any) => ({
            ...prev,
            [name === "break_start_time" ? "override_break_start" : "override_duration_minutes"]: name === "break_start_time" ? (value.length === 5 ? `${value}:00` : value) : Number(value),
            [name === "break_start_time" ? "original_break_start" : "original_duration_minutes"]: name === "break_start_time" ? (value.length === 5 ? `${value}:00` : value) : Number(value)
        }));
    };

    const addBreakTiming = () => {
        const newBreakTiming = {
            ...createBreakTiming
        };
        setUpdateScheduleData((prevData: any) => {
            const breakKey = scheduleData?.break_override_details?.length > 0 ? 'break_override_details' : 'break_details';
            return {
                ...prevData,
                [breakKey]: [...prevData[breakKey], newBreakTiming]
            };
        });
        setCreateBreakTiming({
            override_break_start: "",
            original_break_start: "",
            original_duration_minutes: 0,
            override_duration_minutes: 0
        });
    };

    const getBreakTimingValue = (timeValue: any) => {
        if (timeValue?.includes('T')) {
            const [hours, minutes] = timeValue.split('T')[1].split('.')[0].split(':');
            return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
        }
        return timeValue;
    };

    const formatStartTime = (timeValue: any) => {
        const formattedTime = getBreakTimingValue(timeValue);
        const [hours, minutes] = formattedTime.split(':');
        return `${hours}h ${minutes}m`;
    };

    const calculateBreakTime = (shiftStartTime: string, breakStartTime: string, durationMinutes: number): string => {
        const [shiftHours, shiftMinutes] = shiftStartTime.split(':').map(Number);
        const [breakHours, breakMinutes] = breakStartTime.split(':').map(Number);
        const totalMinutes = (shiftHours * 60 + shiftMinutes) + (breakHours * 60 + breakMinutes) + durationMinutes;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const transformShiftData = (data: ShiftOverride): any => {
        const shiftStartTime = data.override_shift_start || data.original_shift_start;
        const shiftEndTime = data.override_shift_end || data.original_shift_end;
        const breaks = updateScheduleData.usbo_uuid ? data.break_override_details : data.break_details;

        return {
            shift_start_time: getBreakTimingValue(shiftStartTime),
            shift_end_time: getBreakTimingValue(shiftEndTime),
            break_ranges: breaks?.map(breakItem => ({
                break_start_time: getBreakTimingValue(breakItem.override_break_start || breakItem.original_break_start),
                duration_minutes: breakItem.override_duration_minutes || breakItem.original_duration_minutes
            })) || []
        };
    };

    const sortBreakTimings = (field: string) => {
        const breakDetails = updateScheduleData.usbo_uuid ? updateScheduleData.break_override_details : updateScheduleData.break_details;
        const shiftStartTime = getBreakTimingValue(updateScheduleData.override_shift_start || updateScheduleData.original_shift_start);

        let sortedBreaks = [...breakDetails];

        if (field === sortField) {
            // If clicking the same field, toggle the sort direction
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // If clicking a new field, set it as the sort field and default to ascending
            setSortField(field);
            setSortDirection('asc');
        }

        sortedBreaks.sort((a, b) => {
            let valueA, valueB;

            switch (field) {
                case 'timeFromShiftStart':
                    valueA = getBreakTimingValue(a.override_break_start || a.original_break_start);
                    valueB = getBreakTimingValue(b.override_break_start || b.original_break_start);
                    break;
                case 'duration':
                    valueA = a.override_duration_minutes || a.original_duration_minutes;
                    valueB = b.override_duration_minutes || b.original_duration_minutes;
                    break;
                case 'breakTime':
                    valueA = calculateBreakTime(shiftStartTime, getBreakTimingValue(a.override_break_start || a.original_break_start), a.override_duration_minutes || a.original_duration_minutes);
                    valueB = calculateBreakTime(shiftStartTime, getBreakTimingValue(b.override_break_start || b.original_break_start), b.override_duration_minutes || b.original_duration_minutes);
                    break;
                default:
                    return 0;
            }

            if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
            if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });

        setUpdateScheduleData((prev: any) => ({
            ...prev,
            [updateScheduleData.usbo_uuid ? 'break_override_details' : 'break_details']: sortedBreaks
        }));
    };

    const handleUpdateSchedule = async () => {
        var breakOverrides: any;
        var payload: any;
        if (updateScheduleData?.usbo_uuid) {
            breakOverrides = updateScheduleData?.break_override_details?.map((item: any) => {
                if ('break_override_uuid' in item) {
                    const { break_override_uuid, ...rest } = item;
                    return { ubod_uuid: break_override_uuid, ...rest };
                }
                else {
                    const { original_break_start, original_duration_minutes } = item;
                    return { override_break_start: original_break_start, original_break_start: original_break_start, override_duration_minutes: original_duration_minutes, original_duration_minutes: original_duration_minutes }
                }
            });
            payload = {
                usbo_uuid: updateScheduleData?.usbo_uuid,
                user_uuid: userUUID,
                time_zone_uuid: updateScheduleData?.time_zone_uuid,
                shift_template_uuid: updateScheduleData?.shift_template_uuid,
                break_template_uuid: updateScheduleData?.break_template_uuid,
                original_shift_start: updateScheduleData?.original_shift_start,
                original_shift_end: updateScheduleData?.original_shift_end,
                override_shift_start: updateScheduleData?.override_shift_start,
                override_shift_end: updateScheduleData?.override_shift_end,
                break_overrides: breakOverrides
            }
        } else {
            breakOverrides = updateScheduleData?.break_details?.map((item: any) => {
                if ('break_override_uuid' in item) {
                    const { break_override_uuid, ...rest } = item;
                    return { ubod_uuid: break_override_uuid, ...rest };
                }
                else {
                    const { original_break_start, original_duration_minutes } = item;
                    return { override_break_start: original_break_start, original_break_start: original_break_start, override_duration_minutes: original_duration_minutes, original_duration_minutes: original_duration_minutes }
                }
            })
            payload = {
                user_uuid: userUUID,
                time_zone_uuid: updateScheduleData?.time_zone_uuid,
                shift_template_uuid: updateScheduleData?.shift_template_uuid,
                break_template_uuid: updateScheduleData?.break_template_uuid,
                override_shift_start: updateScheduleData?.override_shift_start ? updateScheduleData?.override_shift_start : updateScheduleData?.original_shift_start,
                override_shift_end: updateScheduleData?.override_shift_end ? updateScheduleData?.override_shift_end : updateScheduleData?.original_shift_end,
                original_shift_start: updateScheduleData?.original_shift_start,
                original_shift_end: updateScheduleData?.original_shift_end,
                break_overrides: breakOverrides
            }
        }
        try {
            const response: any = await updateScheduleService(payload);
            if (response?.status === 200) {
                props.handleSuccessClose();
            }
            else { handleShowToast(true, "Error", response?.data?.message); }
        }
        catch (error: any) {
            console.error(error)
            handleShowToast(true, "Error", error.message);
        }
    }

    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };

    const renderBreakTimings = () => {
        const breakDetails = updateScheduleData.usbo_uuid ? updateScheduleData.break_override_details : updateScheduleData.break_details;
        return breakDetails.map((range: any, index: number) => (
            <tr key={index}>
                {editIndex === index ? (
                    <>
                        <td>
                            <input
                                type="time"
                                className="form-control custom-input-w-155 font-12 theme-input shadow-none"
                                value={getBreakTimingValue(editBreak?.override_break_start || editBreak?.original_break_start)}
                                onChange={(e) => setEditBreak({ ...editBreak, override_break_start: e.target.value, override_duration_minutes: range?.override_duration_minutes ? range?.override_duration_minutes : range?.original_duration_minutes })}
                            />
                        </td>
                        <td>
                            <select
                                className="form-select custom-input-w-155"
                                value={editBreak?.override_duration_minutes || editBreak?.original_duration_minutes}
                                onChange={(e) => setEditBreak({ ...editBreak, override_break_start: range?.override_break_start ? range?.override_break_start : range?.original_break_start, override_duration_minutes: Number(e.target.value) })}
                            >
                                {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(value => (
                                    <option key={value} value={value}>{value}m</option>
                                ))}
                            </select>
                        </td>
                        <td>{calculateBreakTime(getBreakTimingValue(updateScheduleData.override_shift_start || updateScheduleData.original_shift_start),
                            getBreakTimingValue(editBreak.override_break_start || editBreak.original_break_start),
                            (editBreak.override_duration_minutes || editBreak.original_duration_minutes))}
                        </td>
                        <td className="text-center">
                            <div className="d-flex align-items-center justify-content-center gap-2">
                                <button type="button" className="action-btn btn p-0" onClick={() => handleBreakTimingChange(index, editBreak)}>
                                    <img src="img/tick-icon-green.svg" alt="edit-icon-blue" />
                                </button>
                                <button type="button" className="action-btn btn p-0" onClick={() => { setEditIndex(null) }}>
                                    <img src="img/cancel-icon.svg" alt="bin-icon-red" />
                                </button>
                            </div>
                        </td>
                    </>
                ) : (
                    <>
                        <td>{formatStartTime(range.override_break_start || range.original_break_start)}</td>
                        <td>{range.override_duration_minutes || range.original_duration_minutes}m</td>
                        <td>{calculateBreakTime(getBreakTimingValue(updateScheduleData.override_shift_start || updateScheduleData.original_shift_start),
                            getBreakTimingValue(range.override_break_start || range.original_break_start),
                            range.override_duration_minutes || range.original_duration_minutes)}
                        </td>
                        <td className="text-center">
                            <div className="d-flex align-items-center justify-content-center gap-2">
                                <button type="button" className="action-btn btn p-0" onClick={() => { setEditIndex(index); setEditBreak(range) }}>
                                    <img src="img/edit-icon.svg" alt="edit-icon-blue" />
                                </button>
                                <button type="button" className="action-btn btn p-0" onClick={() => deleteBreakTiming(index)}>
                                    <img src="img/delete-icon.svg" alt="bin-icon-red" />
                                </button>
                            </div>
                        </td>
                    </>
                )}
            </tr>
        ));
    };

    return (
        <><div className="modal fade show custom-popup" id="editSchedule" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title font-16 font-bold color-black">Edit Schedule - {updateScheduleData?.user_name}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.handleClose() }}></button>
                    </div>
                    <div className="modal-body custom-popup-form">
                        <div className="row justify-content-between">
                            <div className="col-md-6">
                                <label htmlFor="shiftName" className="m-0 font-semibold font-13 color-black-v1 mb-2">Shift Name</label>
                                <select
                                    className="form-select theme-input shadow-none"
                                    id="shiftName"
                                    value={updateScheduleData.shift_template_uuid}
                                    onChange={handleShiftTemplateChange}
                                >
                                    {shiftTemplates.map((shift: any) => (
                                        <option key={shift?.shift_template_uuid} value={shift?.shift_template_uuid} title={shift?.shift_name?.length > 30 ? shift?.shift_name : ""}>
                                            {shift?.shift_name?.length > 35 ? shift?.shift_name?.substring(0, 30) + "..." : shift?.shift_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label htmlFor="breakName" className="m-0 font-semibold font-13 color-black-v1 mb-2">Break Name</label>
                                <select
                                    className="form-select theme-input shadow-none"
                                    id="breakName"
                                    value={updateScheduleData.break_template_uuid}
                                    onChange={handleBreakTemplateChange}
                                >
                                    {breakTemplates.map((breakTemplate: any) => (
                                        <option key={breakTemplate?.break_template_uuid} value={breakTemplate?.break_template_uuid} title={breakTemplate?.break_name?.length > 30 ? breakTemplate?.break_name : ""}>
                                            {breakTemplate?.break_name?.length > 35 ? breakTemplate?.break_name.substring(0, 30) + "..." : breakTemplate?.break_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label htmlFor="shiftName" className="m-0 font-semibold font-13 color-black-v1 mb-2">Shift & Break
                                    Time
                                </label>
                                <ShiftScroll shiftData={transformShiftData(updateScheduleData)} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="timeZone" className="m-0 font-semibold font-13 color-black-v1 mb-2">Time Zone</label>
                                <input
                                    type="text"
                                    className="form-control theme-input shadow-none"
                                    id="timeZone"
                                    value={updateScheduleData.time_zone}
                                    disabled
                                />
                            </div>
                            <h2 className="font-14 font-bold">{updateScheduleData?.shift_name}</h2>
                            <div className="col-md-6">
                                <label htmlFor="startTime" className="m-0 font-semibold font-13 color-black-v1 mb-2">Start Time</label>
                                <input
                                    type="time"
                                    value={getBreakTimingValue(updateScheduleData.override_shift_start || updateScheduleData.original_shift_start)}
                                    className="form-control font-12 theme-input shadow-none"
                                    id="startTime"
                                    onChange={(e) => setUpdateScheduleData((prev: any) => ({ ...prev, override_shift_end: updateScheduleData?.override_shift_end ? updateScheduleData?.override_shift_end : updateScheduleData?.original_shift_end, override_shift_start: e.target.value }))}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="endTime" className="m-0 font-semibold font-13 color-black-v1 mb-2">End Time</label>
                                <input
                                    type="time"
                                    value={getBreakTimingValue(updateScheduleData.override_shift_end || updateScheduleData.original_shift_end)}
                                    className="form-control font-12 theme-input shadow-none"
                                    id="endTime"
                                    onChange={(e) => setUpdateScheduleData((prev: any) => ({ ...prev, override_shift_start: updateScheduleData?.override_shift_start ? updateScheduleData?.override_shift_start : updateScheduleData?.original_shift_start, override_shift_end: e.target.value }))}
                                />
                            </div>
                        </div>

                        <h2 className="font-14 font-bold color-black mt-4 mb-3">Break Timings</h2>
                        <div className="table-responsive theme-table bg-white w-100 rounded-3 mt-2 custom-spacing">
                            <table className="table mb-0">
                                <thead className="sticky-top">
                                    <tr>
                                        <th className={(updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) ? "cursor-pointer" : ""} onClick={() => {
                                            if (updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) {
                                                sortBreakTimings('timeFromShiftStart')
                                            }
                                        }
                                        }>
                                            Time from Shift Start
                                            {(updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) && <span>
                                                <img
                                                    src={sortField === 'timeFromShiftStart'
                                                        ? (sortDirection === 'asc' ? "img/sort-up-arrow.svg" : "img/sort-down-arrow.svg")
                                                        : "img/sort-down-arrow.svg"}
                                                    alt="sort-arrow"
                                                    className="ms-1"
                                                />
                                            </span>}
                                        </th>
                                        <th className={(updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) ? "cursor-pointer" : ""} onClick={() => {
                                            if (updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) sortBreakTimings('duration')
                                        }}>
                                            Duration
                                            {(updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) && <span>
                                                <img
                                                    src={sortField === 'duration'
                                                        ? (sortDirection === 'asc' ? "img/sort-up-arrow.svg" : "img/sort-down-arrow.svg")
                                                        : "img/sort-down-arrow.svg"}
                                                    alt="sort-arrow"
                                                    className="ms-1"
                                                />
                                            </span>}
                                        </th>
                                        <th className={(updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) ? "cursor-pointer" : ""} onClick={() => {
                                            if (updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) sortBreakTimings('breakTime')
                                        }}>
                                            Break Time
                                            {(updateScheduleData.break_details.length > 1 || updateScheduleData.break_override_details.length > 1) && <span>
                                                <img
                                                    src={sortField === 'breakTime'
                                                        ? (sortDirection === 'asc' ? "img/sort-up-arrow.svg" : "img/sort-down-arrow.svg")
                                                        : "img/sort-down-arrow.svg"}
                                                    alt="sort-arrow"
                                                    className="ms-1"
                                                />
                                            </span>}
                                        </th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                type="time"
                                                name="break_start_time"
                                                value={createBreakTiming.override_break_start.substring(0, 5)}
                                                className="form-control custom-input-w-155 font-12 theme-input shadow-none"
                                                onChange={onChangeTimingRequestData}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                className="form-select custom-input-w-155"
                                                name="duration_minutes"
                                                value={createBreakTiming.override_duration_minutes}
                                                onChange={onChangeTimingRequestData}
                                            >
                                                <option value={0}>Select</option>
                                                {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(value => (
                                                    <option key={value} value={value}>{value}m</option>
                                                ))}
                                            </select>
                                        </td>
                                        <td></td>
                                        <td className="text-center">
                                            <button type="button" style={{ border: "0px" }} className="action-btn btn p-0" onClick={addBreakTiming} disabled={(createBreakTiming.override_break_start === "" || createBreakTiming.override_duration_minutes === 0)}>
                                                <img src="img/plus-icon-green.svg" alt="add" />
                                            </button>
                                        </td>
                                    </tr>
                                    {renderBreakTimings()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end gap-3 mt-2 mb-3">
                                <button className="secondary-btn rounded-3" type="button" onClick={() => { props.handleClose(); }}>
                                    <span className="d-inline-block my-1">Cancel</span>
                                </button>
                                <button className="primary-btn rounded-3" type="button" onClick={() => handleUpdateSchedule()} disabled={(updateScheduleData?.break_details.length === 0 && updateScheduleData?.break_override_details.length === 0) || !(isButtonDisabled)}>
                                    <span className="d-inline-block my-1">Schedule</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            {showToast && <ToastMessage props={
                {
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType
                }
            } />}
        </>
    );
}