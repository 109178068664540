const DeletionConfirmationPopup = (props: any) => {
    return (
        <>
            <div className="container-fluid position-fixed toastContainer warning-toast">
                <div className="p-4 pb-3 toast-content d-flex align-items-start gap-3">
                    <img src="img/delete-icon-lg.svg" alt="warning" />
                    <div className="d-flex flex-column gap-4 mt-2">
                        <h3 className="font-bold font-16 color-white m-0">{props.message}</h3>
                        <div className="action-btns d-flex gap-2">
                            <button
                                type="button"
                                className="action-btn-yes font-10 font-semibold px-3 py-2 rounded-2"
                                onClick={() => props.onConfirmDelete()}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className="action-btn-no font-10 font-semibold color-white px-3 py-2 rounded-2"
                                onClick={() => props.onCancelDelete()}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show">
            </div>
        </>
    );
};
export default DeletionConfirmationPopup;