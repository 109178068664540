import Client from "../client/client";
import { userManagementService, baseURL } from "../constant";
import { manageUserPayLoad, deleteUserPayLoadRequest, getUserByIdPayLoad, UserManagementPanelRequest, verifyBusinessEmailPayLoad, getAppRolesPayload } from "../Interfaces/user_management_interface";

/**PS_05 - PS_09 - Async Function to get the fetchAllUsers */
const fetchAllUsers = async () => {

    try {
        const config = {
            method: "get",
            url: `${baseURL}${userManagementService.getAllUsers}`,
        };
        const response = await Client(config);

        return response
    } catch (error) {
        console.log("User Management Service fetchAllUsers", error);       
        throw error
    }
}

/**PS_05 - PS_09 - Async Function to get the fetchAllUsers */
const fetchAllUserManagementDetails = async (payload: UserManagementPanelRequest) => {

    try {
        const config = {
            method: "post",
            url: `${baseURL}${userManagementService.getAllUserDetails}`,
            data: payload
        };
        const response = await Client(config);        
        return response
    } catch (error) {
        console.log("User Management Service fetchAllUserManagementDetails", error);        
        throw error
    }
}

/**PS_05 - PS_09 - Async Function to delete a certain user*/
const deleteUser = async (payload: deleteUserPayLoadRequest) => {

    try {
        const config = {
            method: "delete",
            url: `${baseURL}${userManagementService.deleteUser(payload.user_id)}`
        };
        const response = await Client(config);       
        return response
    } catch (error) {
        console.log("User Management Service fetchAllUserManagementDetails", error);       
        throw error
    }
}

const verifyUserBusinessMail = async (payload: verifyBusinessEmailPayLoad) => {

    try {
        const config = {
            method: "post",
            url: `${baseURL}${userManagementService.verifyBusinessMail}`,
            data: payload
        };
        const response = await Client(config);    
        return response
    } catch (error) {
        console.log("User Management Service verifyUserBusinessMail", error);        
        throw error
    }
}


const userManagement = async (payload: manageUserPayLoad) => {

    try {
        const config = {
            method: "post",
            url: `${baseURL}${userManagementService.saveuser}`,
            data: payload
        };
        const response = await Client(config);        
        return response
    } catch (error) {
        console.log("User Management Service createUser", error);       
        throw error
    }
}

const fetchUserById = async (payload: getUserByIdPayLoad) => {

    try {
        const config = {
            method: "get",
            url: `${baseURL}${userManagementService.getUserDetailsById(payload.user_id)}`
        };
        const response = await Client(config);      
        return response
    } catch (error) {
        console.log("User Management Service createUser", error);      
        throw error
    }
}

const getAppRoles = async (payload: getAppRolesPayload) => {

    try {
        const config = {
            method: "get",
            url: `${baseURL}${userManagementService.getAppRoles(payload.application_uuid)}`
        };
        const response = await Client(config);        
        return response
    } catch (error) {
        console.log("User Management Service createUser", error);       
        throw error
    }
}

export { fetchAllUsers ,fetchAllUserManagementDetails, deleteUser, verifyUserBusinessMail, userManagement, fetchUserById, getAppRoles }