import ExcelJS from 'exceljs';
const FileSaver = require('file-saver');

/**PS_LIV_UI_05 - PS_LIV_UI13 Export Excel Component to handle the exporting of the transformed Details */
interface Header {
    label: string;
    key: string;
    width?: number;
}

interface ExportParams {
    data: any[];
    headers: Header[];
    fileName: string;
    sheetName: string;
    successCallback: () => void;
    errorCallback: () => void;
}

const exportActivityData = async ({
    data,
    headers,
    fileName,
    sheetName,
    successCallback,
    errorCallback
}: ExportParams) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(sheetName);

        // Define columns
        worksheet.columns = headers.map(header => ({
            header: header.label,
            key: header.key,
            width: header.width || 20,
        }));

        worksheet.getRow(1).font = { bold: true };

          // Populate rows with pre-transformed data
          data?.forEach(item => {
            worksheet.addRow(item);
        });

        // Write to buffer and save the file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        FileSaver.saveAs(blob, fileName);

        // Emit success
        if (successCallback) {
            successCallback();
        }
    } catch (error) {
        console.error('Error exporting to Excel:', error);
        // Emit error
        if (errorCallback) {
            errorCallback();
        }
    }
};

export default exportActivityData;