/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import PaginationComponent from "../../reusable_components/pagination";
import { getWorkItemSlaByIdService, updateWorkItemSlaService } from "../../services/work_items_sla_service";
import ToastMessage from "../../reusable_components/toast";
import { FullscreenLoader } from "../loader";
import NoRecordsFoundComponent from "../../reusable_components/no_records_found_component";
import { Payload, WorkItemSLA } from "../../Interfaces/work_items_sla_interface";

export const WorkItemSLAAccordion: React.FC = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [payload, setPayload] = useState<Payload>({
        search: "",
        customer_id: state?.data?.customer_uuid,
        site_id: state?.data?.site_uuid,
        application_uuid: state?.application_id,
        page_number: 1,
        records_per_page: 10
    });

    // Memoize the payload
    const memoizedPayload = useMemo(() => payload, [
        payload.search,
        payload.customer_id,
        payload.site_id,
        payload.application_uuid,
        payload.page_number,
        payload.records_per_page
    ]);

    const [selectedAccordion, setSelectedAccordion] = useState<number | null>(null);
    const [editWorkItem, setEditWorkItem] = useState<WorkItemSLA | null>(null);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [metaData, setMetaData] = useState({
        page: 1,
        page_size: 10,
        total: 0
    });
    const [workItemSLAData, setWorkItemSLAData] = useState<WorkItemSLA[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const getWorkItemSlaDetails = useCallback(async () => {
        setLoader(true);
        try {
            const response = await getWorkItemSlaByIdService(memoizedPayload);
            if (response?.status === 200) {
                setWorkItemSLAData(response?.data?.data);
                setMetaData(response?.data?.meta);
            }
        } catch (error: any) {
            console.error("Error fetching work item SLA details:", error);
            setToastMessage(error?.message);
            setToastType("Error");
            setShowToast(true);
        } finally {
            setLoader(false);
        }
    }, [memoizedPayload]);

    useEffect(() => {
        getWorkItemSlaDetails();
    }, [getWorkItemSlaDetails]);

    useEffect(() => {
        if (workItemSLAData && workItemSLAData.length > 0) {
            setSelectedAccordion(0);
            setEditWorkItem({ ...workItemSLAData[0] });
        }
    }, [workItemSLAData]);

    useEffect(() => {
        if (editWorkItem && workItemSLAData) {
            const originalItem = workItemSLAData.find(
                (item) => item?.work_item_sla_id === editWorkItem?.work_item_sla_id
            );

            const fieldsToCheck: (keyof WorkItemSLA)[] = ['expected_processing_time_sec', 'max_processing_time_sec', 'requeue_time_sec', 'requeue_ack_time_sec'];

            const hasEmptyFields = fieldsToCheck.some(
                (field) => (editWorkItem[field] === undefined || editWorkItem[field] === 0)
            );

            const isChanged = fieldsToCheck.some(
                (field) => Number(editWorkItem[field]) !== originalItem?.[field]
            );

            setIsEdited(isChanged && !hasEmptyFields);
        }
    }, [editWorkItem, workItemSLAData]);

    const handleSearch = useCallback((searchValue: string) => {
        setPayload(prev => ({
            ...prev,
            search: searchValue,
            page_number: 1,
        }));
    }, []);

    const handleAccordionClick = useCallback((index: number) => {
        if (selectedAccordion === index) {
            setSelectedAccordion(null);
            setEditWorkItem(null);
        } else {
            setSelectedAccordion(index);
            setEditWorkItem({ ...workItemSLAData[index] });
        }
        setIsEdited(false);
        setValidationErrors({});
    }, [selectedAccordion, workItemSLAData]);

    const isValidNumber = useCallback((value: string): boolean => {
        const regex: RegExp = /^[1-9]\d*$/;
        return regex.test(value);
    }, []);

    const handleInputChange = useCallback((field: keyof WorkItemSLA, value: string) => {
        if (value !== "") {
            if (isValidNumber(value)) {
                setEditWorkItem(prev => prev ? ({
                    ...prev,
                    [field]: Number(value)
                }) : null);
            }
        } else {
            setEditWorkItem(prev => prev ? ({
                ...prev,
                [field]: 0
            }) : null);
        }
        setValidationErrors({});
    }, [isValidNumber]);

    const validateSLA = useCallback((): boolean => {
        const errors: { [key: string]: string } = {};
        const fields: (keyof WorkItemSLA)[] = ['expected_processing_time_sec', 'max_processing_time_sec', 'requeue_time_sec', 'requeue_ack_time_sec'];

        fields.forEach(field => {
            const value = editWorkItem?.[field];
            if (value === undefined) {
                errors[field] = "This field is required";
            } else if (!isValidNumber(value.toString())) {
                errors[field] = "Please enter a valid positive integer";
            }
        });

        if (Object.keys(errors).length === 0 && editWorkItem) {
            const expected = editWorkItem.expected_processing_time_sec;
            const max = editWorkItem.max_processing_time_sec;
            const requeue = editWorkItem.requeue_time_sec;

            if (max <= expected) {
                errors.max_processing_time_sec = "Maximum processing time must exceed expected processing time";
            }
            if (requeue <= max) {
                errors.requeue_time_sec = "Requeue time must exceed maximum processing time";
            }
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    }, [editWorkItem, isValidNumber]);

    const handleSave = useCallback(async () => {
        if (!validateSLA() || !editWorkItem) {
            return;
        }

        const payload = {
            work_item_sla_id: editWorkItem.work_item_sla_id,
            expected_processing_time_sec: editWorkItem.expected_processing_time_sec,
            max_processing_time_sec: editWorkItem.max_processing_time_sec,
            requeue_time_sec: editWorkItem.requeue_time_sec,
            requeue_ack_time_sec: editWorkItem.requeue_ack_time_sec,
            application_uuid: state?.application_id
        }
        try {
            const response = await updateWorkItemSlaService(payload)
            if (response?.status === 200) {
                setToastMessage("SLA details updated successfully");
                setToastType("Success")
                setShowToast(true);
                await getWorkItemSlaDetails()
            }
        }
        catch (err: any) {
            console.error(err)
            setToastMessage(err?.message);
            setToastType("Error")
            setShowToast(true);
        }
    }, [editWorkItem, state?.application_id, validateSLA, getWorkItemSlaDetails]);

    const handleCancel = useCallback(() => {
        if (selectedAccordion !== null) {
            setEditWorkItem({ ...workItemSLAData[selectedAccordion] });
        }
        setIsEdited(false);
        setValidationErrors({});
    }, [selectedAccordion, workItemSLAData]);

    const workItemsBinding = useCallback(() => {
        return workItemSLAData?.map((item, index) => (
            <div key={item.work_item_sla_id} className="accordion-item custom-shadow rounded-3 overflow-hidden">
                <h2 className="accordion-header">
                    <button
                        className={`accordion-button bg-white d-flex align-items-center gap-2 font-bold font-14 color-black shadow-none ${selectedAccordion !== index && "collapsed"}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={selectedAccordion === index}
                        aria-controls={`collapse${index}`}
                        onClick={() => handleAccordionClick(index)}
                    >
                        {item?.work_item_name === "Equipment Correction" ? <img src="img/equipment-correction-icon.svg" alt="equipment-correction" /> : <img src="img/ocr-icon.svg" alt="equipment-correction" />}
                        {item?.work_item_name}
                    </button>
                </h2>
                <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${selectedAccordion === index ? 'show' : ''}`}
                    data-bs-parent="#work_item_SLA_accordion"
                >
                    <div className="accordion-body p-0 pt-3 m-4 mt-0 border-top">
                        <div className="row">
                            <div className="col-11">
                                <div className="row">
                                    {(['expected_processing_time_sec', 'max_processing_time_sec', 'requeue_time_sec', 'requeue_ack_time_sec'] as const).map((field) => (
                                        <div key={field} className="col-3">
                                            <div className="d-flex align-items-center gap-2 mb-2 pb-1">
                                                {(field === "max_processing_time_sec" || field === "expected_processing_time_sec") && <span className={`d-inline-block process-time ${field === 'max_processing_time_sec' ? 'max' : ''}`} />}
                                                <label htmlFor={field} className="m-0 font-semibold font-13 color-black-v1">
                                                    {field === "expected_processing_time_sec" && "Expected Processing Time"}
                                                    {field === "max_processing_time_sec" && "Maximum Processing Time"}
                                                    {field === "requeue_time_sec" && "Requeue Time"}
                                                    {field === "requeue_ack_time_sec" && "Requeue Acknowledgement Time"}<span className="color-red">*</span>
                                                </label>
                                            </div>
                                            <div className="input-group border rounded-2 overflow-hidden">
                                                <input
                                                    type="text"
                                                    id={field}
                                                    className="form-control shadow-none font-regular font-12 color-black-v1"
                                                    placeholder={`Enter ${field === 'expected_processing_time_sec' ? "Expected Processing Time" : field === 'max_processing_time_sec' ? "Maximum Processing Time" : field === 'requeue_time_sec' ? "Requeue Time" : "Requeue Acknowledgement Time"}`}
                                                    value={editWorkItem?.[field] || ''}
                                                    maxLength={10}
                                                    onChange={(e) => handleInputChange(field, e.target.value)}
                                                />
                                                <span className="input-group-text border-0 font-semibold font-13 color-dark-blue rounded-0">
                                                    Sec
                                                </span>
                                            </div>
                                            {validationErrors[field] && (
                                                <div className="error text-danger font-12">{validationErrors[field]}</div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex justify-content-end gap-3 mt-4 pt-2">
                                    <button className="secondary-btn lh-base" type="button" onClick={() => handleCancel()}>
                                        Cancel
                                    </button>
                                    <button className="primary-btn lh-base" type="button" onClick={() => handleSave()} disabled={!isEdited}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }, [workItemSLAData, selectedAccordion, editWorkItem, validationErrors, handleAccordionClick, handleInputChange, handleCancel, handleSave, isEdited]);

    return (
        loader ? <FullscreenLoader></FullscreenLoader> : <>
            <div className="container-fluid">
                <div className="p-3 py-4">
                    <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a>Admin</a>
                            </li>
                            <li className="breadcrumb-item cursor-pointer" onClick={() => navigate("/workItemSla")}>Work Item SLAs</li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {state?.data?.customer_name}
                            </li>
                        </ol>
                    </nav>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h2 className="m-0 font-bold font-16 color-black d-flex align-items-center gap-2">
                            <a className="mb-1 cursor-pointer" onClick={() => navigate("/workItemSla")}>
                                <img src="img/back-arw.svg" alt="back-arw" />
                            </a>
                            {state?.data?.customer_name} - {state?.data?.site_name}
                        </h2>
                        <div className="d-flex align-items-center gap-3">
                            <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                <input
                                    type="search"
                                    className="form-control border-0 shadow-none font-14"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setSearchTerm(event.target.value)
                                    }}
                                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                        event.key === "Enter" && handleSearch(searchTerm)
                                    }}
                                />
                                <span className="input-group-text border-0 bg-transparent" onClick={() => handleSearch(searchTerm)}>
                                    <img src="img/search-icon.svg" alt="search" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="accordion theme-accordion d-flex flex-column gap-2 mb-3"
                        id="work_item_SLA_accordion"
                    >
                        {(workItemSLAData && workItemSLAData.length > 0) ? workItemsBinding() :
                            <div className="work-item-sla-no-records-found">
                                <NoRecordsFoundComponent
                                    colspan={5}
                                    mainContent={payload.search ? "No Records Found" : "No Data Found"}
                                    subContent={""}
                                    columnHeight={"60"}
                                />
                            </div>
                        }
                    </div>
                    {(workItemSLAData && workItemSLAData.length > 0) && (
                        <div className="mt-1">
                            <PaginationComponent
                                totalCount={workItemSLAData?.length}
                                initialRecordsPerPage={metaData.page_size}
                                currentPage={metaData.page}
                                setPageAndRecords={(currentPage: number, totalCount: number) => {
                                    setPayload(prev => ({
                                        ...prev,
                                        page_number: currentPage,
                                        records_per_page: totalCount < 10 ? 10 : totalCount
                                    }));
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>

            {showToast && (
                <ToastMessage
                    props={{
                        isToast: showToast,
                        setIsToast: setShowToast,
                        toastMessage: toastMessage,
                        toastType: toastType,
                    }}
                />
            )}
        </>
    );
}

export default WorkItemSLAAccordion;
