const LiveFeedWindow: React.FC<any> = () => {
    return (
        <div className="container-fluid">
            <div className="col-md-12 content-container p-0">
                <div className="p-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-between mb-2 gap-3 flex-wrap">
                                <div className="d-flex align-items-center gap-2">
                                    <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">

                                        <img src="img/gallery-icon.svg" alt="product details" />
                                    </span>
                                    <h2 className="font-bold font-14 color-black m-0">
                                        Live Feed
                                    </h2>
                                </div>
                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className="tab-pane fade active show"
                                    id="pills-liveFeed"
                                    role="tabpanel"
                                    aria-labelledby="pills-liveFeed-tab"
                                    tabIndex={0}
                                >
                                    <div className="d-flex align-items-center justify-content-between mb-2 border-top pt-2">
                                        <span className="live-indicator font-regular font-12 color-black d-flex align-items-center lh-1 gap-1">
                                            <span className="dot d-inline-block" />
                                            Live
                                        </span>
                                        <div className="d-flex align-items-center gap-2">
                                            <select
                                                name="camera-option"
                                                id="cam-view-options"
                                                aria-label="Camera View Options"
                                                className="form-select shadow-none font-regular font-12 custom-select"
                                            >
                                                <option value={1}>Cam 1</option>
                                                <option value={2}>Cam 2</option>
                                                <option value={3}>Cam 3</option>
                                                <option value={4}>Cam 4</option>
                                                <option value={5}>Cam 5</option>
                                                <option value={6}>Cam 6</option>
                                                <option value={7}>Cam 7</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <img
                                            src="img/container-sample.png"
                                            alt="container"
                                            className="container-view-img container-expanded-img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveFeedWindow