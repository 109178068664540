// Pseudocode mapping for Login.tsx
// PS_03 User clicks the Login button in login screen
// PS_04 - PS_09 Handle login process with redirect to Microsoft Login Page

import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";


const Login = () => {

  const { instance } = useMsal();
  const navigate = useNavigate();

  const SignIn = async () => {
    await instance.loginPopup().then(() => {
      navigate("/loginAuth");
    }).catch((err: any) => {
      console.log("Login", err);
    });
  };


  return (
    <>
      <div className="vh-100 d-flex align-items-center justify-content-center bg-sky-blue">
        <div className="login-container bg-white d-flex align-items-center justify-content-center flex-column gap-4">
          <img src="img/logo-lynxis-ocra.svg" alt="logo" />
          <button
            className="w-100 d-flex align-items-center justify-content-center gap-2 rounded-3 p-2 login-btn"
            type="button"
            onClick={() => SignIn()}
          >
            <img src="img/microsoft-icon.svg" alt="microsoft-icon" />
            <span className="font-bold font-16 color-blue">
              Sign in with Microsoft
            </span>
          </button>
          <p className="m-0 font-regular font-12 w-75 text-center color-black">
            By using Lynxis, you agree to the <a href="test.com">Terms of Use</a> and{" "}
            <span>Privacy Policy</span>.
          </p>
        </div>
      </div>
    </>
  )
}

export default Login