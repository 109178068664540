import React, { createContext, useState } from "react";

export const overallContext = createContext({} as any);

export const EquipmentContext = (props: any) => {

  const [isEquipmentCorrection, setIsEquipmentCorrection] = useState<boolean>(false);
  return (
    <>
      <overallContext.Provider
        value={{
          isEquipmentCorrection,
          setIsEquipmentCorrection,
        }}
      >
        {props.children}
      </overallContext.Provider>
    </>
  );
};
