import { blobURL, encryptKeys, encryptStorage } from "../constant";

export const calculateDurationInSeconds = (
    isoString1: string,
    isoString2: string
): number => {
    const date1 = new Date(isoString1);
    const date2 = new Date(isoString2);

    const differenceInSeconds = Math.abs(
        (date2.getTime() - date1.getTime()) / 1000
    );

    return Math.floor(differenceInSeconds);
}

export const addOldValueKey = (obj: any) => {
    // Check if the current object is an array
    if (Array.isArray(obj)) {
        obj.forEach(item => addOldValueKey(item));
        return;
    }

    // Check if the current object is indeed an object
    if (obj && typeof obj === 'object') {
        // Iterate through each key in the object
        for (const key in obj) {
            if (key === 'value') {
                // If 'value' key exists, create 'old_value' and copy its value
                obj['old_value'] = obj[key];
            }
            // Recursively call the function for nested objects
            addOldValueKey(obj[key]);
        }
    }
}



export const fetchUserDetailsFromStorage = (): any => {
    const storedUserDetails = encryptStorage.getItem(encryptKeys?.userDetails);
    const parsedUserDetails = storedUserDetails
        ? JSON.parse(storedUserDetails)
        : null;
    return parsedUserDetails;
}

export const updateNestedProperty = (
    obj: any,
    keys: any,
    newValue: any,
    position: any
) => {
    const updatedObj = { ...obj };
    let current = updatedObj;
    keys.forEach((key: any, index: any) => {
        if (index === keys.length - 1) {
            if (position !== -1) {
                current[position][key] = newValue;
            } else {
                current[key] = newValue;
            }
        } else {
            if (Array.isArray(current[key])) {
                current[key] = [...current[key]];
            } else {
                current[key] = { ...current[key] };
            }
            current = current[key];
        }
    });
    return updatedObj;
}
export const getNestedProperty = (obj: any, keys: any, position: any) => {
    const updatedObj = { ...obj };
    let current = updatedObj;
    let value = "";
    keys.forEach((key: any, index: any) => {
        if (index === keys?.length - 1) {
            if (position !== -1) {
                value = current?.[position]?.[key];
            } else {
                value = current?.[key];
            }
        } else {
            if (Array.isArray(current?.[key])) {
                current[key] = [...current?.[key]];
            } else {
                current[key] = { ...current?.[key] };
            }
            current = current?.[key];
        }
    });
    return value;
}

export const createBucketUrl = async (customer_uuid: string, site_uuid: string, portal_id: string, angle: string) => {
    const imageURL = `${blobURL}/${customer_uuid}/${site_uuid}/portal_passages/${portal_id}/${angle}.jpg`
    const isValid = await checkImageUrl(imageURL)
    return isValid ? imageURL : ""
}

function isImgUrl(url: string) {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
    });
}

// Async function to use isImgUrl with await
async function checkImageUrl(url: string) {
    const isValid = await isImgUrl(url);
    return isValid
}
