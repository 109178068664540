import Client from "../client/client";
import { baseURL, workItemSla } from "../constant"

export const getCustomerWorkItemAndApplicationsService = async (payload: any) => {
    try {
        const config: any = {
            method: "POST",
            url: `${baseURL}${workItemSla.getCustomerWorkItemAndApplications}`,
            data: payload
        }
        const response: any = await Client(config);
        return response;
    }
    catch (err: any) {
        throw err
    }
}

export const deleteOrValidateWorkItemSlaService = async (payload: any) => {
    try {
        const config: any = {
            method: "POST",
            url: `${baseURL}${workItemSla.deleteWorkItemSla}`,
            data: payload
        }
        const response: any = await Client(config);
        return response;
    }
    catch (err: any) {
        throw err
    }
}

export const getWorkItemSlaService = async (payload: any) => {
    try {
        const config: any = {
            method: "POST",
            url: `${baseURL}${workItemSla.getWorkItemSla}`,
            data: payload
        }
        const response: any = await Client(config);
        return response;
    }
    catch (err: any) {
        throw err
    }
}

export const getWorkItemSlaByIdService = async (payload: any) => {
    try {
        const config: any = {
            method: "POST",
            url: `${baseURL}${workItemSla.getWorkItemSlaById}`,
            data: payload
        }
        const response: any = await Client(config);
        return response;
    }
    catch (err: any) {
        throw err
    }
}

export const createWorkItemSlaService = async (payload: any) => {
    try {
        const config: any = {
            method: "POST",
            url: `${baseURL}${workItemSla.createWorkItemSla}`,
            data: payload
        }
        const response: any = await Client(config);
        return response;
    }
    catch (err: any) {
        throw err
    }
}

export const updateWorkItemSlaService = async (payload: any) => {
    try {
        const config: any = {
            method: "POST",
            url: `${baseURL}${workItemSla.updateWorkItemSla}`,
            data: payload
        }
        const response: any = await Client(config);
        return response;
    }
    catch (err: any) {
        throw err
    }
}