import React, { createContext, useState } from "react";
import { applicationAbbrievation } from "../enums/enums";
import { encryptKeys, encryptStorage } from "../constant";

export const overallContext = createContext({} as any);

export const EquipmentContext = (props: any) => {

  const [isEquipmentCorrection, setIsEquipmentCorrection] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<string>()
  const [userBreak, setUserBreak] = useState<boolean>(false)
  const sessionApplication = encryptStorage.getItem(encryptKeys?.currentApplication);
  const [currentApplication, setCurrentApplication] = useState<applicationAbbrievation>(sessionApplication ? sessionApplication : applicationAbbrievation.ORCA)
  return (
    <>
      <overallContext.Provider
        value={{
          isEquipmentCorrection,
          setIsEquipmentCorrection,
          userStatus,
          setUserStatus,
          userBreak,
          setUserBreak,
          currentApplication,
          setCurrentApplication
        }}
      >
        {props.children}
      </overallContext.Provider>
    </>
  );
};
