import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";

//PS_01 to PS_36
export const LiveFeedPanel = ({ options, value, onChange, schema, formContext, registry }: any) => {
  const { views } = options;
  const [activeTab, setActiveTab] = useState("images");
  const [focusedImageUrl, setFocusedImageUrl] = useState<string>(views[0]?.url);
  const [camera, setCamera] = useState<number>(0);
  const { match, setMatch } = formContext
  const videoURLs = [
    "https://www.youtube.com/watch?v=XafW4d9XfSE",
    "https://www.youtube.com/watch?v=p10x4nxuA-s&pp=ygURdHJ1Y2sgc3RvY2sgdmlkZW8%3D",
    "https://www.youtube.com/watch?v=whi2aHNeE10",
    "https://www.youtube.com/watch?v=xh7_uzq7UQE",
  ];
  console.log(registry)

  const carouselRef = useRef<HTMLUListElement | null>(null);
  const cameraDropdownRef = useRef<HTMLSelectElement | null>(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleImageSelect = (imageUrl: string) => {
    setFocusedImageUrl(imageUrl);
  };

  const handleCameraSelect = (event: any) => {
    setCamera(event.target.value);
  }

  useEffect(() => {
    if (registry.rootSchema.title === "OCR Visit Matchup") {
      setMatch(false)
    }
  }, [setMatch, registry.rootSchema.title])

  useEffect(() => {
    if (match === false) {
      setActiveTab("liveFeed")
    } else {
      setActiveTab("images")
    }
  }, [match])

  useEffect(() => {
    if (activeTab === "liveFeed") {
      cameraDropdownRef?.current?.focus();
    }
  }, [activeTab]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const { key } = event;
      switch (key) {
        case "Tab":
          event.preventDefault();
          if (activeTab === "images") {
            setActiveTab("liveFeed");
          } else if (match === true) {
            setActiveTab("images");
          }
          break;
      }
    }
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [activeTab, match]);

  const handleEnlarge = () => {
    window.open(videoURLs[camera], "_blank", "width=800,height=600");
  }

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    let isDragging = false;
    let startX: number, startScrollLeft: number;

    const dragStart = (e: MouseEvent) => {
      isDragging = true;
      carousel.classList.add("dragging");
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e: MouseEvent) => {
      if (!isDragging) return;
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
      updateArrowVisibility();
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    const updateArrowVisibility = () => {
      const scrollLeft = carousel.scrollLeft;
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < maxScrollLeft);
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("scroll", updateArrowVisibility);

    // Initial arrow visibility update
    updateArrowVisibility();

    return () => {
      carousel.removeEventListener("mousedown", dragStart);
      carousel.removeEventListener("mousemove", dragging);
      document.removeEventListener("mouseup", dragStop);
      carousel.removeEventListener("scroll", updateArrowVisibility);
    };
  }, [activeTab]);

  const scrollCarousel = (direction: "left" | "right") => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const scrollAmount = direction === "left" ? -200 : 200; // Adjust this value as needed
    carousel.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  return (
    <div className="content-container p-0">
      <div className="p-3">
        <div className="row">
          <div className="col-md-12 min-h-75vh" style={{ overflow: 'hidden' }}>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center gap-2">
                <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">
                  <img src="img/gallery-icon.svg" alt="product details" />
                </span>
                <h2 className="font-bold font-14 color-black m-0">
                  {`${match ? "Portal Images/" : ""}Live Feed`}
                </h2>
              </div>
              {match && <ul
                className="nav nav-pills theme-tab"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "images" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("images")}
                    type="button"
                  >
                    Images
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeTab === "liveFeed" ? "active" : ""
                      }`}
                    onClick={() => setActiveTab("liveFeed")}
                    type="button"
                  >
                    Live Feed
                  </button>
                </li>
              </ul>}
            </div>
            <div className="tab-content" id="pills-tabContent">
              {activeTab === "images" && (
                <div
                  className="tab-pane fade show active"
                  id="pills-images"
                  role="tabpanel"
                  aria-labelledby="pills-images-tab"
                  tabIndex={0}
                >
                  <div className="text-center">
                    <img
                      src={focusedImageUrl}
                      alt="container"
                      className="container-view-img"
                    />
                  </div>
                  <div className="wrapper carousel-ptd pt-4 pt-xl-5">
                    {showLeftArrow && (
                      <i
                        className="left-arrow"
                        onClick={() => scrollCarousel("left")}
                      >
                        <img src="img/chevron-blue.svg" alt="left-arrow" />
                      </i>
                    )}
                    <ul className="carousel list-unstyled" ref={carouselRef}>
                      {options.views.map((value: any, index: any) => {
                        let imageUrl =
                          value?.url === "" ? "img/img-icon.svg" : value?.url;
                        return (
                          <li key={index}>
                            <div
                              className={`cards position-relative ${value?.active === true ? "active" : ""
                                }`}
                              onClick={() => {
                                handleImageSelect(imageUrl)
                                console.log("OK")
                              }}
                            >
                              <img
                                src={imageUrl}
                                alt="container"
                                className={
                                  value.url === "" ? "" : "container-img"
                                }
                              />
                              {value.url === "" && (
                                <span className="font-semibold font-8 color-grey-v2 mt-2">
                                  Image Unavailable
                                </span>
                              )}
                              <span className="container-label position-absolute py-1 px-2 rounded-2 color-white font-bold font-8">
                                {value.label}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {showRightArrow && (
                      <i
                        className="right-arrow"
                        onClick={() => scrollCarousel("right")}
                      >
                        <img src="img/chevron-blue.svg" alt="right-arrow" />
                      </i>
                    )}
                  </div>
                </div>
              )}
              {activeTab === "liveFeed" && (
                <div
                  className="tab-pane fade show active"
                  id="pills-liveFeed"
                  role="tabpanel"
                  aria-labelledby="pills-liveFeed-tab"
                  tabIndex={0}
                >
                  <div className="d-flex align-items-center justify-content-between mb-2 border-top pt-2">
                    <span className="live-indicator font-regular font-12 color-black d-flex align-items-center lh-1 gap-1">
                      <span className="dot d-inline-block"></span>
                      Live
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <select
                        name="camera-option"
                        id="cam-view-options"
                        aria-label="Camera View Options"
                        className="form-select shadow-none font-regular font-12 custom-select"
                        value={camera}
                        ref={cameraDropdownRef}
                        onChange={handleCameraSelect}
                      >
                        {options.cameras.map((eachCamera: any, index: any) => (
                          <option key={index} value={eachCamera.value}>
                            {eachCamera.name}
                          </option>
                        ))}
                      </select>
                      <button
                        className="btn bg-white border rounded-2 p-2 d-flex hover-change"
                        type="button"
                        onClick={handleEnlarge}
                      >
                        <img src="img/enlarge-icon.svg" alt="enlarge" />
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mb-5">
                    <ReactPlayer
                      url={videoURLs[camera]}
                      volume={0}
                      muted={true}
                      width={"100%"}
                      height={"75vh"}
                      playing={true}
                      alt="container"
                      loop={true}
                      className="container-view-video mb-5"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
