import { useEffect } from "react";

export const usePostMessage = (callback: any) => {
    useEffect(() => {
        const handleReceivedMessage = (event: any) => {
            // Call the provided callback with the event data
            callback(event);
        };

        window.addEventListener('message', handleReceivedMessage);

        return () => {
            window.removeEventListener('message', handleReceivedMessage);
        };
    }, [callback]);
};


export function useKeyPress(key: any, action: any) {
    useEffect(() => {
        // Define the event listener
        const onKeyDown = (e: any) => {
            if (e.key === key) {
                action(); // Call the action if the key matches
            }
        };

        // Register the event listener
        window.addEventListener('keydown', onKeyDown);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [key, action]); // Depend on key and action to ensure they are up-to-date
}
