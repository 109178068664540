/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Popup from "../popup";
import { CountdownTimer } from "./count_down_timer_panel";

//PS_01 to PS_21
export const StatusBarPanel = ({ options, formContext, registry }: any) => {
  const [showContinueWorkingPopup, setShowContinueWorkingPopup] = useState<boolean>(false);
  const [showEscalateToLanePopup, setShowEscalateToLanePopup] = useState<boolean>(false);
  const { requeue_time_sec, requeue_ack_time_sec, expected_processing_time_sec, max_processing_time_sec, customer_name, site_name } = formContext.navigationProps;
  const { laneId, handleEscalateToLane, handleReorderWorkItem } = formContext;
  const [navigateTimer, setNavigateTimer] = useState<any>(null);
  const { title } = registry.rootSchema
  const { showPopup, showEscalateToLane } = options
  const receivedTime = new Date();

  useEffect(() => {
    if (!showPopup) {
      return
    }
    const popupTimer = setTimeout(() => {
      setShowContinueWorkingPopup(true);
      const navigateTimer = setTimeout(() => {
        handleReorderWorkItem(true);
      }, requeue_ack_time_sec * 1000);
      setNavigateTimer(navigateTimer);
    }, requeue_time_sec * 1000);
    return () => {
      clearTimeout(popupTimer);
    };
  }, [
    navigateTimer
  ]);
  const handlePopup = (button: string) => {
    setShowContinueWorkingPopup(false);
    switch (button) {
      case "No":
        handleReorderWorkItem(false);
        break;
      case "Yes":
        resetTimers();
        break;
    }
  }
  const handleEscalateToLanePopup = (button: string) => {
    setShowEscalateToLanePopup(false);
    switch (button) {
      case "Yes":
        handleEscalateToLane();
        break;
    }
  }
  const resetTimers = () => {
    if (navigateTimer) {
      clearTimeout(navigateTimer);
    }
    setShowContinueWorkingPopup(false);
    setNavigateTimer(null);
  };



  return (
    <>
      {showContinueWorkingPopup && (
        <Popup
          handleClick={handlePopup}
          popupMessage={"Are you still working on the work item?"}
        />
      )}
      {showEscalateToLanePopup && (
        <Popup
          handleClick={handleEscalateToLanePopup}
          popupMessage={"Are you sure want to escalate to lane?"}
        />
      )}
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <nav aria-label="breadcrumb" className="theme-breadcrumbs">
            <ol className="breadcrumb">
              <li className="breadcrumb-item disabled">
                <span>Work Item Queue</span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {title} {laneId && `On Lane ${laneId}`}
              </li>
            </ol>
          </nav>
          <h2 className="m-0 font-bold font-16 color-black">
            {title} {laneId && `On Lane ${laneId}`}
          </h2>
        </div>
        <div className="d-flex align-items-end gap-3">
          {!laneId && showEscalateToLane && (
            <button
              type="button"
              className="font-semibold font-12 color-white px-3 py-2 rounded-2 border-0 red-gradient-btn"
              onClick={() => setShowEscalateToLanePopup(true)}
            >
              Escalate to Lane
            </button>
          )}
          <div className="px-3 py-2 d-flex align-items-center gap-3 info-wrapper">
            <div className="d-flex flex-column gap-2 pt-1">
              <label className="color-grey-v2 font-semibold font-10 lh-1">
                Customer
              </label>
              <p className="m-0 font-semibold font-14 color-black">
                {customer_name}
              </p>
            </div>
            <div className="vr header-vr"></div>
            <div className="d-flex flex-column gap-2 pt-1">
              <label className="color-grey-v2 font-semibold font-10 lh-1">
                Site
              </label>
              <p className="m-0 font-semibold font-14 color-black">
                {site_name}
              </p>
            </div>
          </div>
          <CountdownTimer
            receivedTime={receivedTime.toISOString()}
            normalTime={expected_processing_time_sec}
            moderateTime={max_processing_time_sec}
          />
        </div>
      </div>
    </>
  );
};
