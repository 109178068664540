/* eslint-disable react-hooks/exhaustive-deps */
// /**PS_00 - Importing of the packages and declaration of state variables */

import React, { useEffect, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid'; // Import the v4 function from uuid
import moment from 'moment';
import NoRecordsFoundComponent from "../../reusable_components/noRecordsFoundComponent";
import { WorkItemQueueNotificationData, EscalateToCustomerPayLoad, Message, QueuedItemsPayLoad, TakeOverWorkItemPayLoad, WorkItems, SortConfig } from "../../Interfaces/work_items_interface";
import Timer from "./timer";
import ToastMessage from "../../reusable_components/toast";
import { escalateToCustomerAPI, getQueuedWorkItems, takeOverWorkItem } from "../../services/operator_service";
import { EscalateEquipmentCorrection } from "./equipment_correction_popup";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { baseURL, notificationSubscribe, operatorNotifications, formatDescription } from "../../constant";
import { WorkItemStatus } from "../../enums/enums";



const InProgressWorkItems = () => {
    const [inProgressWorkItems, setInProgressWorkItems] = useState<WorkItems[]>([]);
    const [recordCount, setRecordCount] = useState(10);
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [showEscalatePopup, setShowEscalatePopup] = useState(false);
    const [selectedWorkItem, setSelectedWorkItem] = useState<WorkItems | null>(null);
    const navigate = useNavigate(); // Initialize useNavigate
    let newSocket: any;
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
    const webSocketId = uuidv4();

    //   /**PS_03 invoke the getInProgressWorkItems in the empty dependency useEffect */
    /**PS_IN_LIVE_03 - PS_IN_LIVE_09 - Establishing the Connection to get the live data in In Progress to lane data */

    useEffect(() => {
        getInProgressWorkItems();
        let modified_url = baseURL ? baseURL.replace("https://", "") : "-"
        newSocket = new WebSocket(
            `wss:${modified_url}${notificationSubscribe.getLiveSubscribeNotification}${operatorNotifications.getLiveWorkItemsNotification}&userID=${webSocketId}`
        );
        newSocket.onopen = () => {
            console.log("Connection opened");
        };
        newSocket.onmessage = (event: any) => {
            let message = JSON.parse(event.data);
            try {
                const data: Message = message.data;
                handleSocketMessage(data);

            } catch (error) {
                console.error("Parsing error:", error);
            }
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            newSocket.close();
        }
    }, []);

    /**PS_IN_LIVE_11 - PS_In_LIVE_20 - Based on the oldStatus and newStatus,the Data is getting manipulated in In Progress data */
    const handleSocketMessage = (data: any) => {
        const { oldStatus, newStatus, workItemJson }: WorkItemQueueNotificationData = data;
        if (newStatus === WorkItemStatus.InProgress || oldStatus === WorkItemStatus.InProgress) {
            const sessionWorkItems: any = sessionStorage.getItem('inProgressWorkItems');            // Check if sessionWorkItems is null and initialize updatedWorkItems accordingly
            let updatedWorkItems: any[] = sessionWorkItems ? JSON.parse(sessionWorkItems) : [];
            const existingIndex = updatedWorkItems.findIndex(item => item.work_item_uuid === workItemJson.work_item_uuid);
            // Handle state transitions based on oldState and newState
            if (newStatus === WorkItemStatus.InProgress) {
                if (existingIndex === -1) {
                    updatedWorkItems.push(workItemJson);
                    setTotalRecordCount(prevCount => prevCount + 1);
                    setInProgressWorkItems(updatedWorkItems?.slice(0, recordCount));
                    sessionStorage.setItem('inProgressWorkItems', JSON.stringify(updatedWorkItems));
                }
            } else if (oldStatus === WorkItemStatus.InProgress) {
                if (existingIndex !== -1) {
                    updatedWorkItems.splice(existingIndex, 1);
                    setTotalRecordCount(prevCount => prevCount - 1);
                    setInProgressWorkItems(updatedWorkItems?.slice(0, recordCount));
                    sessionStorage.setItem('inProgressWorkItems', JSON.stringify(updatedWorkItems));
                }
            }
        }
    };

    const handleLoadMore = () => {
        setRecordCount(prevCount => prevCount + 10);
        const sessionWorkItems: any = sessionStorage.getItem('inProgressWorkItems');
        const workItems = JSON.parse(sessionWorkItems);
        setInProgressWorkItems(workItems?.slice(0, recordCount + 10));
    };

    useEffect(() => {
        const sessionWorkItems: any = sessionStorage.getItem('inProgressWorkItems');
        let sorted = JSON.parse(sessionWorkItems);
        sorted?.sort((a: any, b: any) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        // Update state with the sorted items, limiting to recordCount
        setInProgressWorkItems(sorted ? sorted?.slice(0, recordCount) : []);
    }, [sortConfig])

    //   /**PS_17 - Ps26 - Function to handle the Sorting of the queued Work Items*/
    const handleSort = (key: string) => {
        // Update sort configuration state
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    /*SQ_EX_22.3 - Helper function to set the renderSortIcon Term */
    const renderSortIcon = (key: string) => {
        const iconSrc = sortConfig.key === key
            ? `img/sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}-arrow.svg`
            : "img/sort-down-arrow.svg";
        return <img src={iconSrc} alt="sort-arrow" />;
    };


    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };

    //   /**PS_11 - PS_14 Function to fetch the queued Work Items  */
    const getInProgressWorkItems = async () => {
        try {
            const payload: QueuedItemsPayLoad = { work_item_status: "IN_PROGRESS" };
            const response: any = await getQueuedWorkItems(payload);
            if (response?.status === 200 && response.data) {
                setInProgressWorkItems(response.data?.data?.slice(0, recordCount));
                setTotalRecordCount(response.data?.data?.length || 0);
                sessionStorage.setItem('inProgressWorkItems', JSON.stringify(response.data?.data));
            }
        } catch (error: any) {
            if (error.message !== "Request failed with status code 404") {
                handleShowToast(true, "Error", error.message);
            }
        }
    };

    const escalateToCustomer = (items: WorkItems) => {
        setSelectedWorkItem(items);
        setShowEscalatePopup(true);
    };

    const handleEscalationCancel = () => {
        setShowEscalatePopup(false);
        setSelectedWorkItem(null);
    };

    /**PS_03 - PS_21 Function to fetch the queued Work Items  */
    const invokeTakeOverWorkItem = async (workItemId: string) => {
        try {
            const payload: TakeOverWorkItemPayLoad = {
                work_item_uuid: workItemId
            };
            const response: any = await takeOverWorkItem(payload);
            if (response?.status === 200) {
                if (response?.data) {
                    // Navigate to workItemScreen and pass response.data as state
                    navigate('/workItemScreen', { state: { workItemData: response.data } });
                }
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
    };

    const handleEscalationConfirm = async (reason: string) => {
        if (!selectedWorkItem) return;
        const payload: EscalateToCustomerPayLoad = {
            work_item_uuid: selectedWorkItem.work_item_uuid,
            customer_uuid: selectedWorkItem.customer_uuid,
            site_uuid: selectedWorkItem.site_uuid,
            reason: reason ? reason : "",
        };
        try {
            const response: any = await escalateToCustomerAPI(payload);
            if (response?.status === 200) {
                const sessionWorkItems: any = sessionStorage.getItem('inProgressWorkItems');
                const workItems = JSON.parse(sessionWorkItems);
                const updatedWorkItems = workItems?.filter(
                    (item: WorkItems) => item.work_item_uuid !== selectedWorkItem.work_item_uuid
                );
                setInProgressWorkItems(updatedWorkItems?.slice(0, recordCount));
                setTotalRecordCount(updatedWorkItems?.length);
                sessionStorage.setItem('inProgressWorkItems', JSON.stringify(updatedWorkItems));
                handleShowToast(true, "Success", "Work item escalated to customer successfully.");
            } else {
                handleShowToast(true, "Error", "Failed to escalate work item.");
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
        handleEscalationCancel();
    };


    //   /**PS_15 - Function to handle the rendering of the Progress Work Items */
    const bindInProgressWorkItems = (): any => {
        if (totalRecordCount === 0) {
            return (
                <NoRecordsFoundComponent
                    colspan={5}
                    columnHeight="72"
                    mainContent="No In Progress Work Items Found"
                    subContent="Work items will be listed once operators starts working on it"
                />
            );
        }
        return inProgressWorkItems?.map((items: WorkItems, index) => (
            <tr key={index}>
                <td>
                    <span className="d-flex flex-column gap-1">
                        <span
                            data-bs-placement="right"
                            title={items?.work_item_name?.length > 10  ? items.work_item_name : " "}
                            {...(items?.work_item_name && items.work_item_name.length > 10 ? { 'data-bs-toggle': '' } : {})}
                        >
                            {items.work_item_name ? formatDescription(items.work_item_name, 10) : "-"}
                        </span>
                        <span className="font-10 color-sub-grey">{moment(items.started_on).format("MM/DD/YYYY HH:mm")}</span>
                    </span>
                </td>
                <Timer
                    receivedTime={items.started_on}
                    normalTime={items.expected_processing_time}
                    moderateTime={items.max_processing_time}
                    isMock={false}
                />
                <td>
                    <span className="d-flex flex-column gap-1">
                        <span
                            data-bs-placement="right"
                            title={items?.customer_name?.length > 10  ? items.customer_name : " "}
                            {...(items?.customer_name && items.customer_name?.length > 10 ? { 'data-bs-toggle': '' } : {})}
                        >
                            {items.customer_name ? formatDescription(items.customer_name, 10) : "-"}
                        </span>
                        <span className="font-10 color-sub-grey">{items.site_name}</span>
                    </span>
                </td>
                <td>{items.group_code}</td>
                <td data-bs-toggle="tooltip" data-bs-placement="right" title={items?.assigned_to ? items?.assigned_to : "-"}>{items.assigned_to ? formatDescription(items.assigned_to, 10) : "-"}</td>
                <td className="text-center align-middle">
                    <span className="dropdown d-block">
                        <button type="button" className="border-0 dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="img/dots-icon.svg" alt="dots" />
                        </button>
                        <span className="dropdown-menu dropdown-menu-end work-item-menu px-2">
                            <span className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular cust-border-bottom" onClick={() => invokeTakeOverWorkItem(items.work_item_uuid)}>
                                <img src="img/take-over-icon.svg" alt="arrows-icon" />Take Over Work Item
                            </span>
                            <span className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular" onClick={() => escalateToCustomer(items)}>
                                <img src="img/complaint-icon.svg" alt="arrows-icon" />Escalate to Customer
                            </span>
                        </span>
                    </span>
                </td>
            </tr>
        ));
    };

    return (
        <>
            <div className="col-6">
                <h2 className="font-16 font-bold color-black">
                    In Progress Work Items
                    {totalRecordCount > 0 ? ` (${totalRecordCount})` : ''}
                </h2>
                <div className="table-responsive theme-table bg-white w-100 table-y-scroll rows-10 rounded-3" id="scrollIDGRC09">
                    <InfiniteScroll
                        dataLength={recordCount}
                        next={handleLoadMore}
                        hasMore={recordCount < totalRecordCount}
                        scrollThreshold={0.6}
                        loader={true}
                        scrollableTarget="scrollIDGRC09"
                    >
                        <table className="table mb-0">
                            <thead className="sticky-top table-header-index">
                                <tr>
                                    <th>
                                        {inProgressWorkItems && (
                                            <>
                                                {totalRecordCount === 0 ? (
                                                    <span>Work Item</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('work_item_name')}>
                                                        Work Item {" "}{renderSortIcon('work_item_name')}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </th>
                                    <th>
                                        In Progress Time
                                    </th>
                                    <th>

                                        {inProgressWorkItems && (
                                            <>
                                                {totalRecordCount === 0 ? (
                                                    <span> Customer &amp; Site</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('customer_name')}>
                                                        Customer &amp; Site {" "}{renderSortIcon('customer_name')}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </th>
                                    <th>
                                        {inProgressWorkItems && (
                                            <>
                                                {totalRecordCount === 0 ? (
                                                    <span>  Lane / CHE</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('group_code')}>
                                                        Lane / CHE {" "}{renderSortIcon('group_code')}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </th>
                                    <th>
                                        {inProgressWorkItems && (
                                            <>
                                                {totalRecordCount === 0 ? (
                                                    <span>Assigned To</span>
                                                ) : (
                                                    <span className="cursor-pointer" onClick={() => handleSort('assigned_to')}>
                                                        Assigned To{" "}{renderSortIcon('assigned_to')}
                                                    </span>
                                                )}
                                            </>
                                        )}

                                    </th>
                                    {totalRecordCount > 0 && (<th className="text-center">Action</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {bindInProgressWorkItems()}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>

            {showEscalatePopup && (
                <EscalateEquipmentCorrection
                    customerName={selectedWorkItem?.customer_name}
                    siteName={selectedWorkItem?.site_name}
                    groupCode={selectedWorkItem?.group_code}
                    onCancel={handleEscalationCancel}
                    onConfirm={handleEscalationConfirm}
                />
            )}
            {showToast && (
                <ToastMessage props={{
                    isToast: showToast,
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType,
                }} />
            )}
        </>
    );
};

export default InProgressWorkItems;