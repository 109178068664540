import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { encryptKeys, encryptStorage } from './constant';
import { applicationAbbrievation, UserRoles } from './enums/enums';


/**PS_33 - PS_55 - Checks for the user's role criteria and renders the component according to that */
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
    const location = useLocation();
    const currentApplication = encryptStorage.getItem(encryptKeys?.currentApplication);

    if (!userDetailsString) {
        return <Navigate to="/" replace />;
    }
    const userDetails = JSON?.parse(userDetailsString);
    const userApps = userDetails.appRole || [];

    const hasRole = (role: string) => {
        return userDetails.appRole?.some((appRole: { role_name: string; }) => appRole.role_name === role);
    };

    const isOperator = hasRole(UserRoles.Operator);
    const isAdmin = hasRole(UserRoles.admin);
    const isSupervisor = hasRole(UserRoles.supervisor);
    const restrictedRoutes = [ '/workitemslaaccordion',  '/usermanagementgrid', '/usermanagementform',  '/audithistory'];


    const path = location.pathname;

    if ((userApps?.length > 1 || userApps?.length === 0) && path === '/applications') {
        return <>{children}</>;
    }

    if (isAdmin && !currentApplication && restrictedRoutes.includes(path.toLowerCase())) {
        return <>{children}</>;
    }

    if (isAdmin && currentApplication === applicationAbbrievation.VEM && path !== '/applications') {
        return <>{children}</>;
    }

    if (isSupervisor && currentApplication === applicationAbbrievation.VEM && path !== '/applications') {
        if (!restrictedRoutes.includes(path.toLowerCase())) {
            return <>{children}</>;
        } else {
            return <Navigate to="/forbidden" replace />;
        }
    }

    if (isOperator && currentApplication === applicationAbbrievation.VEM && path !== '/applications') {
        if (path.toLowerCase() === '/workitemnotifications' || path.toLowerCase() === '/workitem' || path.toLowerCase() === '/livefeed') {
            return <>{children}</>;
        }
    }

    if (!userDetails.accessToken) {
        return <Navigate to="/" replace />;
    }


    return <Navigate to="/forbidden" replace />;
};

export default ProtectedRoute;