import { RJSFSchema, UiSchema } from "@rjsf/utils";
import Client from "../client/client";
import { baseURL, equipmentCorrection } from "../constant";
import {
  CompletedWorkItemRequest,
  EscalateToLaneRequest,
  FetchLayoutJsonRequest,
  UpdateUserStatusRequest,
} from "../Interfaces/work_item_interface";

const simulateDelay = (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export const requeueWorkItemService = async (workItemUUID: string) => {
  try {
    const config = {
      method: "GET",
      url: `${baseURL}${equipmentCorrection.requeueWorkItem}/${workItemUUID}`,
      data: {},
    };
    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const fetchLayoutJsonService = async (
  fetchLayoutJson: FetchLayoutJsonRequest
) => {
  try {
    // const config = {
    //     method: "GET",
    //     url: `${baseURL}${equipmentCorrection.requeueWorkItem}/${workItemUUID}`,
    //     data: {},
    // };
    // const response = await Client(config)
    // return response
    await simulateDelay(1);
    let jsonSchema: RJSFSchema = {}
    let uiSchema: UiSchema = {}
    if (fetchLayoutJson.app_work_item_uuid === "f35bb1b4-f9c0-4be0-8204-fea988197cac") {
      jsonSchema = {
        type: "object",
        title: "OCR Visit Matchup",
        properties: {
          statusBar: {
            type: "string",
          },
          equipmentClassification: {
            type: "string",
            title: "Equipment Classification",
          },
          liveFeed: {
            type: "string",
            title: "Portal Images/Live Feed",
          },
        },
      };
      uiSchema = {
        "ui:columns": [["equipmentClassification"], ["liveFeed"]],
        "ui:columnWidths": ["40%", "60%"],
        "ui:submitButtonOptions": {
          norender: true,
        },
        statusBar: {
          "ui:widget": "StatusBarPanel",
          "ui:options": {
            label: false,
            showPopup: true,
            showEscalateToLane: false
          },
        },
        equipmentClassification: {
          "ui:widget": "VisitMatchupPanel",
          "ui:options": {
            className: "custom-input-class",
            label: false,
            equipments: [
              {
                id: "bobtail",
                name: "Bobtail",
                image: "img/Bobtail.svg",
                shortcut: "-",
                defaultAttachedEquipment: [],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "bareChassis",
                name: "Bare Chassis",
                image: "img/Bare-Chassis.svg",
                shortcut: "0",
                defaultAttachedEquipment: [],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "oneContainer",
                name: "One Container",
                image: "img/Single-Container.svg",
                active: true,
                shortcut: "1",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "twoContainers",
                name: "Two Containers",
                image: "img/Twin-Containers.svg",
                shortcut: "2",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 1,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "threeContainers",
                name: "Three Containers",
                image: "img/Three-Containers.svg",
                shortcut: "3",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 1,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 2,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "flatRack",
                name: "Flat Rack",
                image: "img/Flat-Rack.svg",
                shortcut: "4",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "containerBundle",
                name: "Container Bundle",
                image: "img/Container-Bundle.svg",
                shortcut: "5",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "flatrack",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 1,
                    value: "",
                    equipment_type: "flatrack",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 2,
                    value: "",
                    equipment_type: "flatrack",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "chassisBundle",
                name: "Chassis Bundle",
                image: "img/Chassis-Bundle.svg",
                shortcut: "6",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "chassis",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 1,
                    value: "",
                    equipment_type: "chassis",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 2,
                    value: "",
                    equipment_type: "chassis",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 3,
                    value: "",
                    equipment_type: "chassis",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
            ],
            equipmentDetails: {
              bobtail: [],
              bareChassis: [
                {
                  label: "Chassis #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
              ],
              oneContainer: [
                {
                  label: "Container #",
                  id: "container",
                  sequence: 0,
                  placeholder: "Enter Container #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
                {
                  label: "Chassis #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
              ],
              twoContainers: [
                {
                  label: "Container Front #",
                  id: "container",
                  sequence: 0,
                  placeholder: "Enter Container Front #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Container Rear #",
                  id: "container",
                  sequence: 1,
                  placeholder: "Enter Container Rear #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
                {
                  label: "Chassis #",
                  id: "chassis",
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
              ],
              threeContainers: [
                {
                  label: "Container Front #",
                  id: "container",
                  sequence: 0,
                  placeholder: "Enter Container Front #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Container Middle #",
                  id: "container",
                  sequence: 1,
                  placeholder: "Enter Container Middle #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Container Rear #",
                  id: "container",
                  sequence: 2,
                  placeholder: "Enter Container Middle #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
                {
                  label: "Chassis #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
              ],
              containerBundle: [
                {
                  label: "Flat Rack 1 #",
                  id: "flatrack",
                  sequence: 0,
                  placeholder: "Enter Flat Rack 1 #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Flat Rack 2 #",
                  id: "flatrack",
                  sequence: 1,
                  placeholder: "Enter Flat Rack 2 #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Flat Rack 3 #",
                  id: "flatrack",
                  sequence: 2,
                  placeholder: "Enter Flat Rack 3 #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Chassis #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
              ],
              flatRack: [
                {
                  label: "Container #",
                  id: "container",
                  sequence: 0,
                  placeholder: "Enter Container #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
              ],
              chassisBundle: [
                {
                  label: "Chassis 1 #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis 1 #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
                {
                  label: "Chassis 2 #",
                  id: "chassis",
                  sequence: 0,
                  placeholder: "Enter Chassis 2 #",
                  jsonKeys: ["scan_data", "equipment", "equipment_attached", "value"],
                },
                {
                  label: "Chassis 3 #",
                  id: "chassis",
                  sequence: 1,
                  placeholder: "Enter Chassis 3 #",
                  jsonKeys: ["scan_data", "equipment", "equipment_attached", "value"],
                },
                {
                  label: "Chassis 4 #",
                  id: "chassis",
                  sequence: 2,
                  placeholder: "Enter Chassis 4 #",
                  jsonKeys: ["scan_data", "equipment", "equipment_attached", "value"],
                },
                {
                  label: "Chassis 5 #",
                  id: "chassis",
                  sequence: 3,
                  placeholder: "Enter Chassis 5 #",
                  jsonKeys: ["scan_data", "equipment", "equipment_attached", "value"],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
              ],
            },
            navigationShortcuts: {
              submit: "Enter",
              deselect: "Escape",
            },
          },
        },
        liveFeed: {
          "ui:widget": "LiveFeedPanel",
          "ui:options": {
            label: false,
            views: [
              { url: "img/middle-back.png", label: "Back", active: true },
              { url: "img/middle-right.png", label: "Container right" },
              { url: "img/middle-left.png", label: "Container left" },
              { url: "img/bottom-left.png", label: "Chassis left" },
              { url: "img/bottom-right.png", label: "Chassis right" },
              { url: "img/container-sample.png", label: "Rear" },
              { url: "img/top-front.png", label: "Front" },
              { url: "img/top-center.png", label: "Top" },
              { url: "img/bottom-left.png", label: "Middle" },
            ],
            cameras: [
              { name: "Cam 1", value: 0 },
              { name: "Cam 2", value: 1 },
              { name: "Cam 3", value: 2 },
              { name: "Cam 4", value: 3 },
            ],
          },
        },
      };
    } else if (fetchLayoutJson.app_work_item_uuid === "d9559ed7-ade9-4be9-95a4-4ddbd4ced064") {
      jsonSchema = {
        type: "object",
        title: "Equipment Correction",
        properties: {
          statusBar: {
            type: "string",
          },
          equipmentClassification: {
            type: "string",
            title: "Equipment Classification",
          },
          liveFeed: {
            type: "string",
            title: "Portal Images/Live Feed",
          },
        },
      };
      uiSchema = {
        "ui:columns": [["equipmentClassification"], ["liveFeed"]],
        "ui:columnWidths": ["40%", "60%"],
        "ui:submitButtonOptions": {
          norender: true,
        },
        statusBar: {
          "ui:widget": "StatusBarPanel",
          "ui:options": {
            label: false,
            showPopup: true,
            showEscalateToLane: true
          },
        },
        equipmentClassification: {
          "ui:widget": "EquipmentClassificationPanel",
          "ui:options": {
            label: false,
            equipments: [
              {
                id: "bobtail",
                name: "Bobtail",
                image: "img/Bobtail.svg",
                shortcut: "-",
                defaultAttachedEquipment: [],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "bareChassis",
                name: "Bare Chassis",
                image: "img/Bare-Chassis.svg",
                shortcut: "0",
                defaultAttachedEquipment: [],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "oneContainer",
                name: "One Container",
                image: "img/Single-Container.svg",
                active: true,
                shortcut: "1",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "twoContainers",
                name: "Two Containers",
                image: "img/Twin-Containers.svg",
                shortcut: "2",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 1,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "threeContainers",
                name: "Three Containers",
                image: "img/Three-Containers.svg",
                shortcut: "3",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 1,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 2,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "flatRack",
                name: "Flat Rack",
                image: "img/Flat-Rack.svg",
                shortcut: "4",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "container",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "containerBundle",
                name: "Container Bundle",
                image: "img/Container-Bundle.svg",
                shortcut: "5",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "flatrack",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 1,
                    value: "",
                    equipment_type: "flatrack",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 2,
                    value: "",
                    equipment_type: "flatrack",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
              {
                id: "chassisBundle",
                name: "Chassis Bundle",
                image: "img/Chassis-Bundle.svg",
                shortcut: "6",
                defaultAttachedEquipment: [
                  {
                    sequence: 0,
                    value: "",
                    equipment_type: "chassis",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 1,
                    value: "",
                    equipment_type: "chassis",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 2,
                    value: "",
                    equipment_type: "chassis",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                  {
                    sequence: 3,
                    value: "",
                    equipment_type: "chassis",
                    confidence: 0,
                    properties: [
                      {
                        type: "seal_presence",
                        value: true,
                        confidence: 0,
                      },
                      {
                        type: "isoCode",
                        value: "",
                        confidence: 0,
                      },
                    ],
                  },
                ],
                containerNameJsonKey: ["scan_data", "classification"],
                containerEquipmentAttachedJsonKey: [
                  "scan_data",
                  "equipment",
                  "equipment_attached",
                ],
              },
            ],
            equipmentDetails: {
              bobtail: [],
              bareChassis: [
                {
                  label: "Chassis #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
              ],
              oneContainer: [
                {
                  label: "Container #",
                  id: "container",
                  sequence: 0,
                  placeholder: "Enter Container #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
                {
                  label: "Chassis #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
              ],
              twoContainers: [
                {
                  label: "Container Front #",
                  id: "container",
                  sequence: 0,
                  placeholder: "Enter Container Front #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Container Rear #",
                  id: "container",
                  sequence: 1,
                  placeholder: "Enter Container Rear #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
                {
                  label: "Chassis #",
                  id: "chassis",
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
              ],
              threeContainers: [
                {
                  label: "Container Front #",
                  id: "container",
                  sequence: 0,
                  placeholder: "Enter Container Front #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Container Middle #",
                  id: "container",
                  sequence: 1,
                  placeholder: "Enter Container Middle #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Container Rear #",
                  id: "container",
                  sequence: 2,
                  placeholder: "Enter Container Middle #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
                {
                  label: "Chassis #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
              ],
              containerBundle: [
                {
                  label: "Flat Rack 1 #",
                  id: "flatrack",
                  sequence: 0,
                  placeholder: "Enter Flat Rack 1 #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Flat Rack 2 #",
                  id: "flatrack",
                  sequence: 1,
                  placeholder: "Enter Flat Rack 2 #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Flat Rack 3 #",
                  id: "flatrack",
                  sequence: 2,
                  placeholder: "Enter Flat Rack 3 #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "Chassis #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
              ],
              flatRack: [
                {
                  label: "Container #",
                  id: "container",
                  sequence: 0,
                  placeholder: "Enter Container #",
                  jsonKeys: [
                    "scan_data",
                    "equipment",
                    "equipment_attached",
                    "value",
                  ],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
              ],
              chassisBundle: [
                {
                  label: "Chassis 1 #",
                  id: "chassis",
                  sequence: -1,
                  placeholder: "Enter Chassis 1 #",
                  jsonKeys: ["scan_data", "equipment", "value"],
                },
                {
                  label: "Chassis 2 #",
                  id: "chassis",
                  sequence: 0,
                  placeholder: "Enter Chassis 2 #",
                  jsonKeys: ["scan_data", "equipment", "equipment_attached", "value"],
                },
                {
                  label: "Chassis 3 #",
                  id: "chassis",
                  sequence: 1,
                  placeholder: "Enter Chassis 3 #",
                  jsonKeys: ["scan_data", "equipment", "equipment_attached", "value"],
                },
                {
                  label: "Chassis 4 #",
                  id: "chassis",
                  sequence: 2,
                  placeholder: "Enter Chassis 4 #",
                  jsonKeys: ["scan_data", "equipment", "equipment_attached", "value"],
                },
                {
                  label: "Chassis 5 #",
                  id: "chassis",
                  sequence: 3,
                  placeholder: "Enter Chassis 5 #",
                  jsonKeys: ["scan_data", "equipment", "equipment_attached", "value"],
                },
                {
                  label: "License Plate #",
                  id: "license_plate",
                  sequence: -1,
                  placeholder: "Enter License Plate #",
                  jsonKeys: ["scan_data", "truck", "license_plate", "value"],
                },
              ],
            },
            navigationShortcuts: {
              submit: "Enter",
              deselect: "Escape",
            },
          },
        },
        liveFeed: {
          "ui:widget": "LiveFeedPanel",
          "ui:options": {
            label: false,
            views: [
              { url: "img/middle-back.png", label: "Back", active: true },
              { url: "img/middle-right.png", label: "Container right" },
              { url: "img/middle-left.png", label: "Container left" },
              { url: "img/bottom-left.png", label: "Chassis left" },
              { url: "img/bottom-right.png", label: "Chassis right" },
              { url: "img/container-sample.png", label: "Rear" },
              { url: "img/top-front.png", label: "Front" },
              { url: "img/top-center.png", label: "Top" },
              { url: "img/bottom-left.png", label: "Middle" },
            ],
            cameras: [
              { name: "Cam 1", value: 0 },
              { name: "Cam 2", value: 1 },
              { name: "Cam 3", value: 2 },
              { name: "Cam 4", value: 3 },
            ],
          },
        },
      };
    }
    const response = {
      status: 200,
      data: {
        uiSchema,
        jsonSchema,
      },
    };
    return response;
  } catch (error: any) {
    return error;
  }
}

export const escalatetoLaneService = async (payload: EscalateToLaneRequest) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.escalatetoLane}`,
      data: payload,
    };
    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const getStatusService = async () => {
  try {
    const config = {
      method: "GET",
      url: `${baseURL}${equipmentCorrection.getStatus}`,
    };
    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const updateUserActivityStatusService = async (
  payload: UpdateUserStatusRequest
) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.updateUserActivityStatus}`,
      data: payload,
    };

    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}

export const completedWorkItemService = async (
  payload: CompletedWorkItemRequest
) => {
  try {
    const config = {
      method: "POST",
      url: `${baseURL}${equipmentCorrection.completeWorkItem}`,
      data: payload,
    };

    const response = await Client(config);
    return response;
  } catch (error: any) {
    return error;
  }
}
