import { ObjectFieldTemplateProps } from "@rjsf/utils";
import React from "react";

const ObjectFieldTemplate: React.FC<ObjectFieldTemplateProps> = (props) => {
  const { uiSchema = {}, properties } = props;
  const columns: string[][] = uiSchema["ui:columns"] || [];
  const columnWidths: string[] = uiSchema["ui:columnWidths"] || [];
  const equipmentInfoProperty = properties.find(
    (prop) => prop.name === "statusBar"
  );

  return (
    <div className="body-bg font-regular">
      <div className="container-fluid">
        <div className="p-3">
          {equipmentInfoProperty && <>{equipmentInfoProperty?.content}</>}
          <div className="row mt-3 flex-lg-nowrap gap-3 px-3">
            {/* Render each column */}
            {columns.map((column, columnIndex) => (
              <div
                key={columnIndex}
                className={`col-${Math.floor(
                  12 / columns.length
                )} p-0 content-container`}
                style={{ width: columnWidths[columnIndex] }}
              >
                {/* Render properties in this column */}
                {column.map((propertyName) => {
                  const property = properties.find(
                    (prop) => prop.name === propertyName
                  );
                  return property ? (
                    <React.Fragment key={property.name}>
                      {property.content}
                    </React.Fragment>
                  ) : null;
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ObjectFieldTemplate;
