// Import the necessary modules and types.
import Client from "../client/client";
import { baseURL, breakTemplate } from "../constant";
import { CreateBreakTemplateRequest, GetRequestPayload, UpdateBreakTemplateRequest } from "../Interfaces/break_templates_interface";

export const getBreakTemplatesService = async (payload: GetRequestPayload) => {
    // PS_BT_05: Invoke clientService function with method 'POST', endpoint, and payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${breakTemplate.getBreakDetails}`,
            data: payload,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        return err;
    }
}

export const getBreakTemplateByUUIDService = async(breakTemplateUUID: string) => {
    try {
        const config = {
            method: "get",
            url: `${baseURL}${breakTemplate.getBreakDetails}/${breakTemplateUUID}`,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        return err;
    }
}

export const getAssociatedTeamsWithBreakTemplateService = async (breakTemplateUUID: string) => {
    // PS_BT_67: Invoke clientService with 'GET' method and generate endpoint string with the breakTemplateUUID.
    try {
        const config = {
            method: "get",
            url: `${baseURL}${breakTemplate.getAssociatedTeams}/${breakTemplateUUID}`,
            data: {},
        };

        // PS_BT_71: Relay the response back to the component, indicating the outcome of the fetch request.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_72: If an error occurs, the service layer catches it and relays an error response back to the component.
        return err;
    }
}

export const deleteBreakTemplateService = async (breakTemplateUUID: string) => {
    // PS_BT_80: Invoke clientService with 'DELETE' method and generate endpoint string with the breakTemplateUUID.
    try {
        const config = {
            method: "delete",
            url: `${baseURL}${breakTemplate.deleteBreak}/${breakTemplateUUID}`,
            data: {},
        };

        // PS_BT_84: Relay the response back to the component, indicating the outcome of the deletion request.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_85: If an error occurs, the service layer catches it and relays an error response back to the component.
        return err;
    }
}

export const createBreakTemplateService = async (createBreakTemplateRequest: CreateBreakTemplateRequest) => {
    // PS_BT_41: Invoke clientService with method 'POST', endpoint, and the createTemplateRequest payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${breakTemplate.createBreak}`,
            data: createBreakTemplateRequest,
        };

        // PS_BT_45: Return the response from the service layer back to the component for further action.
        const response = await Client(config);
        return response;
    }
    catch (err : any) {
        console.log("Inside Err")
        console.log(err)
        // PS_BT_46: If an error occurs during the creation process in the service layer, catch and return an error response.
        return err?.response?.data;
    }
}

export const updateBreakTemplateService = async (updateBreakTemplateRequest: UpdateBreakTemplateRequest) => {
    // PS_BT_55: Invoke clientService with method 'PUT', endpoint, and the updateBreakTemplateRequest payload.
    try {
        const config = {
            method: "put",
            url: `${baseURL}${breakTemplate.editBreak}`,
            data: updateBreakTemplateRequest,
        };

        // PS_BT_61: Return the response from the service layer back to the component for further action.
        const response = await Client(config);
        return response;
    }
    catch (err : any) {
        // PS_BT_60: If an error occurs during the update process in the service layer, catch and return an error response.
        return err?.response?.data;
    }
}