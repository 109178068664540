
// Pseudocode mapping for Index.tsx
// PS_01 User enters the application
// PS_02 Initialize the MSAL configuration with clientId, redirectUri, authority, and cache settings

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";


const configuration: any = {
    auth: {
        clientId: process.env.REACT_APP_ClientId,
        redirectUri: `${window.location.origin}/loginAuth`,
        authority:
            `https://login.microsoftonline.com/${process.env.REACT_APP_TenantId}`,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    },
};

const pca = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <MsalProvider instance={pca}>
        <App />
    </MsalProvider>
);
