import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { applicationInfo, manageUserPayLoad, getApplicationCustomerRequestPayload, roleInfo, verifyBusinessEmailPayLoad, getShiftTimeZoneDetailsPayLoad, getAppRolesPayload, getUserByIdPayLoad, userAppRoles, timeZoneInfo } from "../../Interfaces/user_management_interface";
import { getCustomerWorkItemAndApplicationsService } from "../../services/work_items_sla_service";
import ToastMessage from "../../reusable_components/toast";
import { fetchUserById, getAppRoles, userManagement, verifyUserBusinessMail } from "../../services/user_management_service";
import { getBreakShiftAndTimeZonesService } from "../../services/operator_service";
import { SortConfig } from "../../Interfaces/user_activity_interface";
import { FullscreenLoader } from "../loader";

/**PS_UMF_01 - 03 Declaration of the state variables and necessary imports */

const UserManagementForm = () => {
    const navigate = useNavigate();
    const [businessEmail, setBusinessEmail] = useState<string>('');
    const [isVerifiedEmail, setIsVerifiedEmail] = useState<boolean>(false);
    const [userDetailsPayLoad, setUserDetailsPayLoad] = useState<manageUserPayLoad>({
        business_email: '',
        time_zone_uuid: '',
        status: 1,
        first_name: '',
        middle_name: '',
        last_name: '',
        display_name: '',
        app_roles: [],
        user_id: null,
        microsoft_user_id: ''
    });
    const [initialDetailsPayload, setInitialDetailsPayload] = useState<manageUserPayLoad>({
        business_email: '',
        time_zone_uuid: '',
        status: 1,
        first_name: '',
        middle_name: '',
        last_name: '',
        display_name: '',
        app_roles: [],
        user_id: null,
        microsoft_user_id: ''
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [applicationNames, setApplicationNames] = useState<applicationInfo[]>([]);
    const [timeZoneDetails, setTimeZoneDetails] = useState<timeZoneInfo[]>([]);
    const [roleNames, setRoleNames] = useState<roleInfo[]>([]);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const location = useLocation();
    const [selectedApplication, setSelectedApplication] = useState<string>('');
    const [selectedRole, setSelectedRole] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
    const [currentlyEditing, setCurrentlyEditing] = useState<userAppRoles | null>(null);
    const [editIndex, setEditIndex] = useState<number | null>(null);


    /**PS_UMF_28 Function to navigate to the userGri */
    const navigateToUserGrid = useCallback((isCancel: string = 'false') => {
        if (isCancel === 'cancel') {
            navigate(`/userManagementGrid`);
            return
        }
        let mode = location?.state?.user_uuid ? "Updated" : "Created";
        navigate(`/userManagementGrid`, {
            state: {
                message: `${mode}`
            },
        });
    }, [location?.state?.user_uuid, navigate]);

    /**PS_UMF_20 - Callback function to be triggered to fetch all applications */
    const getAllApplications = useCallback(async () => {
        try {
            let payLoad: getApplicationCustomerRequestPayload = {
                applications: true,
                customer_site: false,
                work_item: false
            }
            setIsLoading(true)
            const response: any = await getCustomerWorkItemAndApplicationsService(payLoad);
            if (response?.status === 200) {
                setApplicationNames(response?.data?.data?.applications || []);
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setApplicationNames([]);
            }
            else {
                console.error("Error fetching all applications", error);
                handleShowToast(true, "Error", error.message);
            }
        }
        finally {
            setIsLoading(false)
        }
    }, []);

    /**PS_UMF_29 - Callback function to be triggered during initialLoad if the userId exists */
    const getUserById = useCallback(async () => {
        try {
            let payLoad: getUserByIdPayLoad = {
                user_id: location.state.user_uuid,
            }
            setIsLoading(true)
            const response: any = await fetchUserById(payLoad);
            if (response?.status === 200) {
                setUserDetailsPayLoad(response?.data?.data);
                setInitialDetailsPayload(response?.data?.data);
                setIsVerifiedEmail(true);
            }
        } catch (error: any) {
            console.error("Error fetching all applications", error);
            handleShowToast(true, "Error", error.message);
        }
        finally {
            setIsLoading(false)
        }
    }, [location.state.user_uuid]);

    /**PS_UMF_35 - Callback function to be triggered during to retrieve when onChange of the applicationName */
    const getAllUserRoles = useCallback(async (application_uuid: string) => {
        try {
            let allUserRolesPayLoad: getAppRolesPayload = {
                application_uuid: application_uuid
            }
            const response: any = await getAppRoles(allUserRolesPayLoad);
            if (response?.status === 200) {
                setRoleNames(response?.data?.data || []);
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setRoleNames([]);
            }
            else {
                console.error("Error fetching all user roles", error);
                handleShowToast(true, "Error", error.message);
            }
        }
    }, []);


    /**PS_UMF_13 - Callback function to be triggered during initialLoad for fetching the timeZoneDetails */
    const getAllTimeZoneDetails = useCallback(async () => {
        try {
            let timeZonePayload: getShiftTimeZoneDetailsPayLoad = {
                time_zone_details: true,
                shift_details: false,
                break_details: false
            }
            setIsLoading(true)
            const response: any = await getBreakShiftAndTimeZonesService(timeZonePayload);
            if (response?.status === 200) {
                setTimeZoneDetails(response?.data?.data?.time_zones || []);
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setTimeZoneDetails([]);
            }
            else {
                console.error("Error fetching all user roles", error);
                handleShowToast(true, "Error", error.message);
            }
        }
        finally {
            setIsLoading(false)
        }
    }, []);

    /**PS_UMF_25 - Callback function to be triggered during verify button click */
    const verifyBusinessMail = useCallback(async () => {
        try {
            if (!businessEmail) return
            let verifyBusinessEmail: verifyBusinessEmailPayLoad = {
                email: businessEmail
            }
            setIsLoading(true)
            const response: any = await verifyUserBusinessMail(verifyBusinessEmail);
            if (response?.status === 200) {
                let verifedEmailResponse = response?.data?.data
                setUserDetailsPayLoad({
                    business_email: verifedEmailResponse.email,
                    time_zone_uuid: '',
                    status: 1,
                    first_name: verifedEmailResponse.first_name,
                    middle_name: verifedEmailResponse.middle_name,
                    last_name: verifedEmailResponse.last_name,
                    display_name: '',
                    app_roles: [],
                    user_id: '',
                    microsoft_user_id: verifedEmailResponse.sso_id
                });
                setIsVerifiedEmail(true);
            }
        } catch (error: any) {
            if (error?.response?.status === 409) {
                handleShowToast(true, "Error", "User Already Exists");
                return;
            }
            else {
                console.error("Error fetching user details", error);
                handleShowToast(true, "Error", "Error Verifying user details");
            }
        }
        finally {
            setIsLoading(false)
        }
    }, [businessEmail]);

    /**PS_UMF_45 - Callback function to be triggered during add new user button */
    const validateUserDetails = useCallback((): boolean => {
        const hasMandatoryFields = Boolean(userDetailsPayLoad.display_name) && Boolean(userDetailsPayLoad.time_zone_uuid);
        const hasValidAppRoles = userDetailsPayLoad.app_roles.some(role => !role.is_deleted);
        const isNotEditing = currentlyEditing === null && editIndex === null;
        const hasSelectedRoleAndApplication = Boolean(selectedRole) && Boolean(selectedApplication);
        return hasMandatoryFields && hasValidAppRoles && isNotEditing && !hasSelectedRoleAndApplication;
    }, [currentlyEditing, editIndex, selectedApplication, selectedRole, userDetailsPayLoad.app_roles, userDetailsPayLoad.display_name, userDetailsPayLoad.time_zone_uuid]);


    /**PS_UMF_45 - Callback function to be triggered during add new user button */
    const manageuser = useCallback(async () => {
        try {
            if (!validateUserDetails()) return
            let saveUser: manageUserPayLoad = {
                business_email: userDetailsPayLoad.business_email,
                time_zone_uuid: userDetailsPayLoad.time_zone_uuid,
                status: userDetailsPayLoad.status,
                first_name: userDetailsPayLoad.first_name,
                middle_name: userDetailsPayLoad.middle_name,
                last_name: userDetailsPayLoad.last_name,
                display_name: userDetailsPayLoad.display_name,
                app_roles: userDetailsPayLoad.app_roles,
                microsoft_user_id: userDetailsPayLoad.microsoft_user_id,
                user_id: userDetailsPayLoad.user_id
            }
            setIsLoading(true)
            const response: any = await userManagement(saveUser);
            if (response?.status === 200 || response?.status === 201) {
                navigateToUserGrid()
            }
        } catch (error: any) {
            console.error("Error fetching user details", error);
            handleShowToast(true, "Error", error.message);
        }
        finally {
            setIsLoading(false)
        }
    }, [navigateToUserGrid, userDetailsPayLoad.app_roles, userDetailsPayLoad.business_email, userDetailsPayLoad.display_name, userDetailsPayLoad.first_name, userDetailsPayLoad.last_name, userDetailsPayLoad.microsoft_user_id, userDetailsPayLoad.middle_name, userDetailsPayLoad.status, userDetailsPayLoad.time_zone_uuid, userDetailsPayLoad.user_id,validateUserDetails]);


    /**PS_53 Function for handling toast */
    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };

    /**PS_UMF_33 -  function to be triggered during change of the application dropdown */
    const handleApplicationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const application_uuid = event.target.value;
        setSelectedApplication(application_uuid);
        getAllUserRoles(application_uuid);
    };

    /**PS_UMF_42 -  function to be triggered during change of the role dropdown */
    const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRole(event.target.value);
    };

    /** Helper function to be triggered to generate a random uniqueId */
    function generateUniqueId() {
        return 'id-' + Math.random().toString(36).substr(2, 9);
    }

    /**PS_UMF_44 -  function to be triggered during adding of new role */
    const handleAddRole = () => {
        const application = applicationNames.find(app => app.application_uuid === selectedApplication);
        const role = roleNames.find(role => role.role_uuid === selectedRole);

        if (!application || !role) return;

        setUserDetailsPayLoad(prevState => {
            if (currentlyEditing) {
                // Editing existing role
                const updatedAppRoles = prevState.app_roles?.map(appRole => {
                    // Check both unique_id and app_role_uuid to identify the role
                    const identifier = appRole.unique_id || appRole.app_role_uuid;
                    const editIdentifier = currentlyEditing.unique_id || currentlyEditing.app_role_uuid;

                    if (identifier === editIdentifier) {
                        return {
                            ...appRole,
                            application_uuid: application.application_uuid,
                            role_uuid: role.role_uuid,
                            application_name: application.abbreviation,
                            role_name: role.role_name,
                        };
                    }
                    return appRole;
                });

                return { ...prevState, app_roles: updatedAppRoles };
            } else {
                // Adding a new role
                const newRole: userAppRoles = {
                    application_uuid: application.application_uuid,
                    role_uuid: role.role_uuid,
                    unique_id: generateUniqueId(), // Generate a unique ID for a new role
                    application_name: application.abbreviation,
                    role_name: role.role_name,
                    // As this is a new role, app_role_uuid will only be available after persistence
                    app_role_uuid: undefined,
                    is_deleted: false
                };

                // Append the new role to the app_roles array
                return { ...prevState, app_roles: [...prevState.app_roles, newRole] };
            }
        });

        // Reset the currently editing role and selectors
        setCurrentlyEditing(null);
        setSelectedApplication('');
        setSelectedRole('');
        setRoleNames([]);
    };

    /**PS_UMF_32 -  function to be triggered during cancelling of the edit */
    const handleCancelEdit = () => {
        setCurrentlyEditing(null);
        setSelectedApplication('');
        setSelectedRole('');
        setEditIndex(null);
    };

    /**PS_UMF_31 -  function to be triggered during edit of the role */
    const handleEditRole = (role: userAppRoles, index: number) => {
        setCurrentlyEditing(role);
        setSelectedApplication(role.application_uuid);
        setSelectedRole(role.role_uuid); // Pre-fetch the roles for this application
        setEditIndex(index); // Set the index for enabling edit mode for the selected row

        // Call getAllUserRoles to ensure roleNames are populated if needed
        getAllUserRoles(role.application_uuid);
    };

    /**PS_UMF_30 -  function to be triggered during delete of a user role */
    const handleDeleteRole = (identifier: string | undefined) => {
        setUserDetailsPayLoad(prevState => {
            const updatedAppRoles = prevState.app_roles.reduce((acc: userAppRoles[], role) => {
                if (role.unique_id === identifier || role.app_role_uuid === identifier) {
                    if (role.app_role_uuid) {
                        // If there's an app_role_uuid, simply mark as deleted.
                        acc.push({ ...role, is_deleted: true });
                    }
                    // If app_role_uuid is not set, this role is a newly added one and should be completely removed.
                } else {
                    // Leave the role unchanged if it does not match the identifier.
                    acc.push(role);
                }
                return acc;
            }, []);
            return {
                ...prevState,
                app_roles: updatedAppRoles
            };
        });
    };

    /**PS_UMF_25 -  function to be triggered during click of the verify button */
    const handleVerifyButtonClick = () => {
        if (!businessEmail) return
        verifyBusinessMail()
    }

    /**PS_UMF_26 -  function to be triggered during the change of the status radio button */
    const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newStatus = e.target.value === '1' ? 1 : 0;
        setUserDetailsPayLoad(prevState => ({
            ...prevState,
            status: newStatus
        }));
    };


    /**PS_UMF_27 - function to be triggered during Sorting of the app roles */
    const handleRoleAssigningSort = useCallback(() => {
        if (sortConfig.key && userDetailsPayLoad.app_roles.length > 1) {
            type UserAppRoleKeys = keyof userAppRoles;
            // Create a copy of app_roles to avoid mutating state directly
            const sortedAppRoles = [...userDetailsPayLoad.app_roles].sort((a: userAppRoles, b: userAppRoles) => {
                const aValue = a[sortConfig.key as UserAppRoleKeys]?.toString().toLowerCase() || '';
                const bValue = b[sortConfig.key as UserAppRoleKeys]?.toString().toLowerCase() || '';
                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0; // Return 0 if values are equal
            });
            setUserDetailsPayLoad(prevState => ({
                ...prevState,
                app_roles: sortedAppRoles
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig.direction, sortConfig.key]);

    useEffect(() => {
        handleRoleAssigningSort()
    }, [handleRoleAssigningSort])

    /**PS_UMF_11 - Callback function to be triggered during initialLoad  to fetch the timeZoneDetails*/
    useEffect(() => {
        getAllTimeZoneDetails();
    }, [getAllTimeZoneDetails]);

    /**PS_UMF_29 - Callback function to be triggered during initialLoad to get the user details by id if location.state.user_uuid exists */
    useEffect(() => {
        if (location.state.user_uuid) getUserById()
    }, [getUserById, location.state.user_uuid])


    /**PS_AUH_22 - Function to handle the renderSortIcon */
    const renderSortIcon = (key: string) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc'
                ? <img src="img/sort-up-arrow.svg" alt="sort-arrow-up" />
                : <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
        }
        return <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
    };

    /**PS_UMF_27 - Function to handle the handleSort */
    const handleSort = (key: string) => {
        setSortConfig((prevConfig: { key: string; direction: string; }) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };


    // Function to filter application names based on userDetailsPayLoad
    const getFilteredApplicationNames = (isEdit: boolean = false) => {
        const selectedApplications = userDetailsPayLoad.app_roles?.map(role => role.application_uuid || []);
        let filteredApplications = applicationNames?.filter(app => {
            const isAppSelected = selectedApplications?.includes(app.application_uuid);
            const hasNonDeletedRole = userDetailsPayLoad.app_roles.some(role =>
                role.application_uuid === app.application_uuid && !role.is_deleted
            );
            return !isAppSelected || (isAppSelected && !hasNonDeletedRole);
        });
        if (isEdit) {
            if (editIndex !== null && editIndex < userDetailsPayLoad.app_roles.length) {
                const editIndexAppUuid = userDetailsPayLoad.app_roles[editIndex]?.application_uuid;
                const editIndexApp = applicationNames?.find(app => app.application_uuid === editIndexAppUuid);
                if (editIndexApp && !filteredApplications?.some(app => app.application_uuid === editIndexAppUuid)) {
                    filteredApplications.push(editIndexApp);
                }
            }
        }
        return filteredApplications;
    };

    /**PS_UMF_26 - Function to handle the value Change of the time_zone_uuid and the status and the display_name*/
    const handleSaveUserChange = (e: any) => {
        const regex = /^[A-Za-z\s]*$/;
        if (e.target.name === 'time_zone_uuid' || e.target.name === 'status')
            setUserDetailsPayLoad(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
        if (e.target.name === 'display_name' && regex.test(e.target.value)) {
            setUserDetailsPayLoad(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
        }
    };

    function areUserAppRolesDifferent(
        initialRoles: userAppRoles[],
        currentRoles: userAppRoles[]
    ): boolean {
        if (initialRoles.length !== currentRoles.length) {
            return true;
        }

        for (let i = 0; i < initialRoles.length; i++) {
            const initialRole = initialRoles[i];
            const currentRole = currentRoles[i];

            if (
                initialRole.application_uuid !== currentRole.application_uuid ||
                initialRole.role_uuid !== currentRole.role_uuid ||
                initialRole.application_name !== currentRole.application_name ||
                initialRole.role_name !== currentRole.role_name ||
                initialRole.is_deleted !== currentRole.is_deleted ||
                initialRole.unique_id !== currentRole.unique_id
            ) {
                return true;
            }
        }

        return false;
    }

    function areDifferent(
        initialPayload: manageUserPayLoad,
        currentPayload: manageUserPayLoad
    ): boolean {
        if (
            initialPayload.business_email !== currentPayload.business_email ||
            initialPayload.time_zone_uuid !== currentPayload.time_zone_uuid ||
            initialPayload.status !== currentPayload.status ||
            initialPayload.first_name !== currentPayload.first_name ||
            initialPayload.middle_name !== currentPayload.middle_name ||
            initialPayload.last_name !== currentPayload.last_name ||
            initialPayload.display_name !== currentPayload.display_name ||
            initialPayload.microsoft_user_id !== currentPayload.microsoft_user_id ||
            initialPayload.user_id !== currentPayload.user_id
        ) {
            return true;
        }

        if (areUserAppRolesDifferent(initialPayload.app_roles, currentPayload.app_roles)) {
            return true;
        }
        return false;
    }
    /**PS_UMF_20 - Callback function to be triggered during initialLoad */
    useEffect(() => {
        getAllApplications();
    }, [getAllApplications]);

    return (<>
        <div className={`container-fluid ${isLoading ? 'loader-blur' : ''}`}>
            {isLoading ? (
                <FullscreenLoader />
            ) : (
                <div className="p-3 py-4">
                    {/* breadcrumbs */}
                    <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item ">Admin</li>
                            <li className="breadcrumb-item cursor-pointer" onClick={() => { navigateToUserGrid('cancel') }}>User Management</li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {location.state.user_uuid ? "Edit User" : "Add New User"}
                            </li>
                        </ol>
                    </nav>
                    <h2 className="mb-3 font-bold font-16 color-black d-flex align-items-center gap-2">
                        <p className="mb-1 cursor-pointer" onClick={() => { navigateToUserGrid('cancel') }}>
                            <img src="img/back-arw.svg" alt="back-arw" />
                        </p>
                        {location.state.user_uuid ? "Edit User" : "Add New User"}
                    </h2>
                    <div className="bg-white rounded-3 shadow-sm p-4">
                        <div className="row gx-5 row-gap-4 custom-gx mb-5">
                            <div className="col-5">
                                <label
                                    htmlFor="_BusinessEmail"
                                    className="m-0 font-semibold font-14 color-black-v1 mb-2"
                                >
                                    Business Email <span className="color-red">*</span>
                                </label>
                                <div className="d-flex align-items-stretch gap-4">
                                    <input
                                        id="_BusinessEmail"
                                        type="email"
                                        className="form-control border font-13 custom-input shadow-none"
                                        placeholder="Enter Business Email"
                                        maxLength={255}
                                        value={userDetailsPayLoad?.business_email ? userDetailsPayLoad.business_email : businessEmail}
                                        disabled={isVerifiedEmail}
                                        onChange={(e) => setBusinessEmail(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && businessEmail && !isVerifiedEmail) {
                                                handleVerifyButtonClick();
                                            }
                                        }}
                                    />
                                    <button className="primary-btn text-nowrap font-14" type="button" disabled={!businessEmail || isVerifiedEmail} onClick={() => { handleVerifyButtonClick() }}>
                                        Verify
                                    </button>
                                </div>
                            </div>
                        </div>
                        {isVerifiedEmail && (<>  <div className="row row-gap-4 gx-5 custom-gx">
                            <div className="col-4">
                                <label
                                    htmlFor="BusinessEmail"
                                    className="m-0 font-semibold font-14 color-black-v1 mb-2"
                                >
                                    Business Email <span className="color-red">*</span>
                                </label>
                                <input
                                    id="BusinessEmail"
                                    type="email"
                                    className="form-control border font-13 custom-input shadow-none"
                                    placeholder="Enter Business Email"
                                    disabled={isVerifiedEmail}
                                    value={userDetailsPayLoad.business_email}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="TimeZone" className="m-0 font-semibold font-14 color-black-v1 mb-2">
                                    Time Zone <span className="color-red">*</span>
                                </label>
                                <span className="filter-drop-down">
                                    <button
                                        id="TimeZone"
                                        className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                    >
                                        <span>
                                            {userDetailsPayLoad.time_zone_uuid
                                                ? timeZoneDetails.find(zone => zone.time_zone_uuid === userDetailsPayLoad.time_zone_uuid)?.time_zone_name
                                                : 'Select'}
                                        </span>
                                        <img src="img/down-arrow.svg" alt="down-arrow" />
                                    </button>

                                    <span className="dropdown-menu font-regular font-14 p-0">
                                        <span
                                            className="px-2 py-1 list d-block font-12 time-zone-user-form" 
                                            onClick={() => handleSaveUserChange({ target: { name: "time_zone_uuid", value: "" } })}                                    >
                                            Select
                                        </span>
                                        {timeZoneDetails?.map((zone: any) => (
                                            <span
                                                key={zone.time_zone_uuid}
                                                className="px-2 py-1 list d-block font-12"
                                                onClick={() => handleSaveUserChange({ target: { name: "time_zone_uuid", value: zone.time_zone_uuid } })}  >
                                                {zone.time_zone_name}
                                            </span>
                                        ))}
                                    </span>
                                </span>
                            </div>

                            <div className="col-4">
                                <label
                                    htmlFor="_status"
                                    className="m-0 font-semibold font-14 color-black-v1 mb-2 w-100"
                                >
                                    Status
                                </label>
                                <div className="form-check form-check-inline mt-1">
                                    <input
                                        className="form-check-input shadow-none"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio1"
                                        value={1}
                                        checked={userDetailsPayLoad.status === 1}
                                        onChange={handleStatusChange}
                                    />
                                    <label
                                        className="form-check-label color-black font-regular font-13"
                                        htmlFor="inlineRadio1"
                                    >
                                        Active
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input shadow-none"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio2"
                                        value={0}
                                        checked={userDetailsPayLoad.status === 0}
                                        onChange={handleStatusChange}
                                    />
                                    <label
                                        className="form-check-label color-black font-regular font-13"
                                        htmlFor="inlineRadio2"
                                    >
                                        Inactive
                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="FirstName"
                                    className="m-0 font-semibold font-14 color-black-v1 mb-2"
                                >
                                    First Name
                                </label>
                                <input
                                    id="FirstName"
                                    type="text"
                                    className="form-control border font-13 custom-input shadow-none"
                                    placeholder="Enter First Name"
                                    value={userDetailsPayLoad.first_name}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="MiddleName"
                                    className="m-0 font-semibold font-14 color-black-v1 mb-2"
                                >
                                    Middle Name
                                </label>
                                <input
                                    id="MiddleName"
                                    type="text"
                                    className="form-control border font-13 custom-input shadow-none"
                                    placeholder="Enter Middle Name"
                                    value={userDetailsPayLoad.middle_name}
                                    disabled={true} />
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="LastName"
                                    className="m-0 font-semibold font-14 color-black-v1 mb-2"
                                >
                                    Last Name
                                </label>
                                <input
                                    id="LastName"
                                    type="text"
                                    className="form-control border font-13 custom-input shadow-none"
                                    placeholder="Enter Last Name"
                                    value={userDetailsPayLoad.last_name}
                                    disabled={true}
                                />
                            </div>
                            <div className="col-4">
                                <label
                                    htmlFor="DisplayName"
                                    className="m-0 font-semibold font-14 color-black-v1 mb-2"
                                >Display Name <span className="color-red">*</span>
                                </label>
                                <input
                                    id="DisplayName"
                                    type="text"
                                    className="form-control border font-13 custom-input shadow-none"
                                    placeholder="Enter Display Name"
                                    onChange={(e) => handleSaveUserChange({ target: { name: "display_name", value: e.target.value } })}
                                    value={userDetailsPayLoad.display_name}
                                    maxLength={200}
                                />
                            </div>
                            <div className="col-12">
                                <h4 className="font-bold font-14 color-black mb-3">Role Assigning</h4>
                                <div className="table-responsive theme-table bg-white rounded-3 custom-spacing">
                                    <table className="table mb-0">
                                        <thead>
                                            <tr className="alter">
                                            <th className="role-table-width">
                                                    {userDetailsPayLoad.app_roles?.length <= 1 ? (
                                                        <span>Applications</span>
                                                    ) : (
                                                        <span className="cursor-pointer" onClick={() => handleSort('application_name')}>
                                                            Applications{" "}{renderSortIcon('application_name')}
                                                        </span>
                                                    )}
                                                </th>
                                                <th className="role-table-width">
                                                    {userDetailsPayLoad.app_roles?.length <= 1 ? (
                                                        <span>Role</span>
                                                    ) : (
                                                        <span className="cursor-pointer" onClick={() => handleSort('role_name')}>
                                                            Role{" "}{renderSortIcon('role_name')}
                                                        </span>
                                                    )}
                                                </th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td className="role-table-width">
                                                    <select
                                                        className="form-select font-12 custom-input shadow-none border max-w-275px"
                                                        aria-label="Applications"
                                                        value={currentlyEditing ? " " : selectedApplication}
                                                        onChange={handleApplicationChange}
                                                    >
                                                        <option value="">Select</option>
                                                        {getFilteredApplicationNames()?.map((app: any) => (
                                                            <option key={app.application_uuid} value={app.application_uuid}>
                                                                {app.abbreviation}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td className="role-table-width">
                                                    <select
                                                        className="form-select font-12 custom-input shadow-none border max-w-275px"
                                                        aria-label="Roles"
                                                        value={currentlyEditing ? "" : selectedRole}
                                                        onChange={handleRoleChange}
                                                    >
                                                        <option value="">Select</option>
                                                        {roleNames?.map(role => (
                                                            <option key={role.role_uuid} value={role.role_uuid}>
                                                                {role.role_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                                <td className="text-center">
                                                    <div className="d-flex align-items-center justify-content-center gap-2">
                                                        <button
                                                            disabled={!selectedApplication || !selectedRole}
                                                            type="button"
                                                            className="btn p-0 action-btn border-0"
                                                            onClick={handleAddRole}
                                                        >
                                                            <img src="img/plus-icon-green.svg" alt="plus-icon-green" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {userDetailsPayLoad.app_roles?.filter(role => !role.is_deleted)?.map((role, index) => {
                                                const isEditing = editIndex === index;
                                                return (
                                                    <tr key={role.unique_id || role.app_role_uuid}>
                                                        <td className="role-table-width">
                                                            {isEditing ? (
                                                                <select
                                                                    className="form-select font-12 custom-input shadow-none border max-w-275px"
                                                                    aria-label="Applications"
                                                                    value={selectedApplication}
                                                                    onChange={handleApplicationChange}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {getFilteredApplicationNames(true)?.map((app: any) => (
                                                                        <option key={app.application_uuid} value={app.application_uuid}>
                                                                            {app.abbreviation}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <span>{role.application_name}</span>
                                                            )}
                                                        </td>
                                                        <td className="role-table-width">
                                                            {isEditing ? (
                                                                <select
                                                                    className="form-select font-12 custom-input shadow-none border max-w-275px"
                                                                    value={selectedRole}
                                                                    onChange={handleRoleChange}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {roleNames?.map(roleOption => (
                                                                        <option key={roleOption.role_uuid} value={roleOption.role_uuid}>
                                                                            {roleOption.role_name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <span>{role.role_name}</span>
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                                {isEditing ? (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            className="action-btn btn p-0 border-0"
                                                                            disabled={!selectedRole || !selectedApplication}
                                                                            onClick={() => { handleAddRole(); setEditIndex(null); }}
                                                                        >
                                                                            <img src="img/tick-icon-green.svg" alt="save-icon" />
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="action-btn btn p-0"
                                                                            onClick={() => { handleCancelEdit() }}
                                                                        >
                                                                            <img src="img/cancel-icon.svg" alt="cancel-icon" />
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            className="btn p-0 action-btn"
                                                                            onClick={() => {
                                                                                handleEditRole(role, index)
                                                                            }}
                                                                        >
                                                                            <img src="img/edit-icon.svg" alt="edit-icon-blue" />
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn p-0 action-btn"
                                                                            onClick={() => handleDeleteRole(role.unique_id || role.app_role_uuid)}
                                                                        >
                                                                            <img src="img/delete-icon.svg" alt="delete-icon-red" />
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                            <div className="d-flex justify-content-end gap-3 my-3 pt-5">
                                <button className="secondary-btn lh-base" type="button" onClick={() => { navigateToUserGrid('cancel') }}>
                                    Cancel
                                </button>
                                <button
                                    className="primary-btn lh-base"
                                    type="button"
                                    disabled={!validateUserDetails() ||
                                        (location?.state?.user_uuid && !areDifferent(initialDetailsPayload, userDetailsPayLoad))}
                                    onClick={manageuser}
                                >                                    {location?.state?.user_uuid ? "Update" : "Add"}
                                </button>
                            </div></>)}

                    </div>
                </div>
            )}
        </div>

        {showToast && <ToastMessage props={
            {
                setIsToast: setShowToast,
                toastMessage: toastMessage,
                toastType: toastType
            }
        } />
        }


    </>)
}

export default UserManagementForm