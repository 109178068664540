/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage = () => {
    const navigate = useNavigate();

    return (
        <div className="vh-100 d-flex align-items-center justify-content-center">
            <div className="text-center unauthorised-page px-3">
                <div className="gif mb-3">
                    <img src="img/unauthorised-img.svg" alt="hhh" />
                </div>
                <p className="font-bold font-24 color-dark-blue">Unauthorized</p>
                <p className="font-regular font-16 color-dark-blue">
                    Sorry, you are not authorized to access this page. Please check your
                    access for this page by contacting your administrator.
                </p>
                <button
                    className="primary-btn rounded-3 mt-2"
                    type="button"
                    onClick={() => { navigate(-1) }}
                >
                    <span className="d-inline-block my-1">Go Back</span>
                </button>
            </div>
        </div>

    );
};

export default UnauthorizedPage;
