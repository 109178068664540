/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

const PaginationComponent = ({ totalCount, initialRecordsPerPage, currentPage, setPageAndRecords }: any) => {
  const [recordsPerPage, setRecordsPerPage] = useState(initialRecordsPerPage);
  const recordsPerPageOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  useEffect(() => {
    setPageAndRecords(currentPage, recordsPerPage)
  }, [recordsPerPage])

  // Calculate the total number of pages
  const totalPages = Math.ceil(totalCount / recordsPerPage);

  // Filter the records per page options based on totalCount
  const validOptions = recordsPerPageOptions.filter(option =>
    option <= totalCount
  );
  // If totalCount is between two options, and is not included in the array, add the next higher option if it exists
  if (totalCount > Math.max(...validOptions) && validOptions.length < recordsPerPageOptions.length) {
    const nextOption = recordsPerPageOptions.find(option => option > totalCount);
    if (nextOption) {
      validOptions.push(nextOption);
    }
  }

  // Handle changing records per page
  const handleRecordsPerPageChange = (newRecordsPerPage: number) => {
    setRecordsPerPage(newRecordsPerPage);
    setPageAndRecords(1, newRecordsPerPage);
  };

  // Handle page change
  const handlePageChange = (newPage: number) => {
    setPageAndRecords(newPage, recordsPerPage);
  };

  return (<>
    {totalCount > 10 ? <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center gap-2 font-regular font-14 color-grey-v4">
        <span># of Records</span>
        <div className="dropdown">
          <button type="button" className="px-3 shadow-btn gap-2 d-flex align-items-center justify-content-between rounded-2 font-semibold font-14 color-black" data-bs-toggle="dropdown" aria-expanded="false">
            {recordsPerPage} <img src="img/chevron-grey.svg" alt="drp" />
          </button>
          <div className="dropdown-menu border-0 custom-shadow px-4 py-2 shadow-sm min-w-63px dropdown-menu-end">
            <div className="d-grid gap-2 max-show-wrapper">
              {validOptions.map(option => (
                <span key={option} className={`font-regular font-13 ${option === recordsPerPage ? 'primary-color-blue' : 'color-black-v2'}`} onClick={() => handleRecordsPerPageChange(option)}>
                  {option}
                </span>
              ))}
            </div>
          </div>
        </div>
        <span>of <span>{totalCount}</span></span>
      </div>
      <nav aria-label="Page navigation" className="theme-pagination cursor-pointer">
        <ul className="pagination font-semibold m-0">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a className="page-link prev" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
              <img src="img/chevron-grey-lg.svg" alt="prev" />
            </a>
          </li>
          {[...Array(totalPages)].map((_, index) => (
            <li key={index} className="page-item">
              <a className={`page-link shadow-none color-tinted-grey ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </a>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
              <img src="img/chevron-grey-lg.svg" alt="next" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
      :
      <div className="d-flex justify-content-between align-items-center my-3">
        <p className="font-14 font-regular mb-0 color-grey"># of Records: <span className="font-14 font-bold color-black">{totalCount}</span></p>
      </div>}
  </>

  );
};

export default PaginationComponent;