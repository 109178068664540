
import Client from "../client/client";
import { authentication, baseURL } from "../constant";
import { acessTokenPayload } from "../Interfaces/login_interface";


// PS_24 - PS_34 Handling token generation and storage
const generateAuthToken = async (payload: acessTokenPayload) => {
  try {

    const config = {
      method: "post",
      url: `${baseURL}${authentication.generateAuthToken}`,
      data: payload,
    };

    const response = await Client(config);
    return response
  } catch (error) {
    console.log("generateAuthToken service", error);
  }
}

export { generateAuthToken }