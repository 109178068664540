import Client from "../client/client";
import { baseURL, ShiftTemplatesEndPoints } from "../constant";

export const getShiftTemplatesService = async (requestPayload: any) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${ShiftTemplatesEndPoints.getShiftTemplates}`,
            data: requestPayload,
        };
        const response: any = await Client(config);
        return response;
    }
    catch (err) {
        throw err
    }
}

export const deleteShiftTemplateService = async (requestPayload: any) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${ShiftTemplatesEndPoints.deleteShiftTemplate}`,
            data: requestPayload,
        };
        const response: any = await Client(config);
        return response;
    }
    catch (err) {
        throw err
    }
}

export const getShiftTemplateByUUIDService = async (shiftTemplateUUID: string) => {
    try {
        const config = {
            method: "get",
            url: `${baseURL}${ShiftTemplatesEndPoints.getShiftTemplateById}${shiftTemplateUUID}`,
            data: {},
        };
        const response: any = await Client(config);
        return response;
    }
    catch (err) {
        throw err
    }
}

export const createShiftTemplateService = async (paylaod: any) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${ShiftTemplatesEndPoints.createShiftTemplate}`,
            data: paylaod,
        };
        const response: any = await Client(config);
        return response;
    }
    catch (err) {
        throw err
    }
}

export const updateShiftTemplateService = async (payload: any) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${ShiftTemplatesEndPoints.updateShiftTemplate}`,
            data: payload,
        };
        const response: any = await Client(config);
        return response;
    }
    catch (err) {
        throw err
    }
}