import React, { useState, useRef, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { Stage, Layer, Rect } from 'react-konva';

interface PTZValues {
    pitch: number;
    tilt: number;
    zoom: number;
}

interface PTZVideoPlayerProps {
    url: string;
    width: number;
    height: number;
}

const PTZVideoPlayer: React.FC<PTZVideoPlayerProps> = ({ url, width, height }) => {
    const [isDrawing, setIsDrawing] = useState(false);
    const [box, setBox] = useState({ x: 0, y: 0, width: 0, height: 0 });
    const [ptzValues, setPTZValues] = useState<PTZValues>({ pitch: 0, tilt: 0, zoom: 1 });
    const stageRef = useRef<any>(null);
    console.log(ptzValues)
    const handleMouseDown = (e: any) => {
        setIsDrawing(true);
        const pos = e.target.getStage().getPointerPosition();
        setBox({ x: pos.x, y: pos.y, width: 0, height: 0 });
    };

    const handleMouseMove = (e: any) => {
        if (!isDrawing) return;

        const stage = e.target.getStage();
        const pos = stage.getPointerPosition();
        setBox({
            ...box,
            width: pos.x - box.x,
            height: pos.y - box.y,
        });
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
        calculatePTZValues();
    };

    const calculatePTZValues = useCallback(() => {
        const stage = stageRef.current;
        if (!stage) return;

        const stageWidth = stage.width();
        const stageHeight = stage.height();

        // Calculate pitch and tilt based on box center position
        const centerX = box.x + box.width / 2;
        const centerY = box.y + box.height / 2;
        const pitch = ((centerY / stageHeight) - 0.5) * 180; // -90 to 90 degrees
        const tilt = ((centerX / stageWidth) - 0.5) * 360; // -180 to 180 degrees

        // Calculate zoom based on the larger dimension of the box
        const boxLargerDimension = Math.max(Math.abs(box.width), Math.abs(box.height));
        const stageLargerDimension = Math.max(stageWidth, stageHeight);
        const zoomFactor = boxLargerDimension / stageLargerDimension;
        const minZoom = 1;
        const maxZoom = 10;
        const zoom = minZoom + (zoomFactor * (maxZoom - minZoom));

        setPTZValues({ pitch, tilt, zoom });
    }, [box]);

    return (
        <div style={{ position: 'relative', width, height }} className="container-view-video">
            <ReactPlayer
                url={url}
                width={width}
                height={height}
                playing
                muted
                loop
            />
            <Stage
                width={width}
                height={height}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                style={{ position: 'absolute', top: 0, left: 0 }}
                ref={stageRef}
            >
                <Layer>
                    <Rect
                        x={box.x}
                        y={box.y}
                        width={box.width}
                        height={box.height}
                        stroke="white"
                        strokeWidth={1}
                    />
                </Layer>
            </Stage>
            {/* <div style={{ position: 'absolute', bottom: 10, left: 10, background: 'rgba(0,0,0,0.5)', color: 'white', padding: '5px' }}>
                Pitch: {ptzValues.pitch.toFixed(2)}°
                Tilt: {ptzValues.tilt.toFixed(2)}°
                Zoom: {ptzValues.zoom.toFixed(2)}x
            </div> */}
        </div>
    );
};

export default PTZVideoPlayer;