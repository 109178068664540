import axios from "axios";
import { authentication, baseURL, encryptKeys, encryptStorage } from "../constant";
import { jwtDecode } from "jwt-decode";

// PS_28 - PS_33 Handle API request, token validation, and token refresh
const Client = async (payload: any) => {
  try {
    const { url, method, data } = payload;
    const userDetails = JSON.parse(encryptStorage.getItem(encryptKeys.userDetails) || "{}");
    const accessToken = userDetails.accessToken;

    let config;
    // Check if the request does not involve generating a new auth token
    if (!url.includes(authentication.generateAuthToken)) {
      const isTokenExpired = await validateTokenExpiration(accessToken);

      if (isTokenExpired) {
        await refreshTokens();
      }

      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data,
        url,
        method,
      };
    } else {
      config = {
        url,
        method,
        data,
      };
    }

    const response = await axios(config);
    return response;
  } catch (error: any) {
    console.error("API request error:", error.message);
    throw error;
  }
};

async function validateTokenExpiration(token: string | undefined) {
  if (!token) return false;

  try {
    const tokenParts = token.split(".");
    const payload = tokenParts[1];
    const decodedPayload = JSON.parse(atob(payload));

    if (decodedPayload.exp) {
      const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
      return decodedPayload.exp < currentTimestamp; // Return true if expired
    }
  } catch (error: any) {
    console.error("Error validating token expiration:", error);
  }

  return false; // Default to not expired
}

async function refreshTokens() {
  const userDetails = JSON.parse(encryptStorage.getItem(encryptKeys.userDetails) || "{}");
  const refresh_token = userDetails.refreshToken;

  if (!refresh_token) return;

  try {
    const isRefreshTokenExpired = await validateTokenExpiration(refresh_token);

    if (isRefreshTokenExpired) {
      window.location.href = "/"
    }
    else {
      const payload = {
        data: { refresh_token },
        url: `${baseURL}${authentication.generateAuthTokenByRefreshToken}`,
        method: "post",
      };

      const response = await axios(payload);



      // Store new tokens in session storage
      if (response.data && response.data.access_token && response.data.refresh_token) {
        const decodedToken: any = jwtDecode(response.data.access_token);
        const userData: any = {
          accessToken: response.data.access_token,
          userName: decodedToken.userName,
          emailId: decodedToken.emailId,
          userId: decodedToken.userUUID,
          refreshToken: response.data.refresh_token,
          appRole: decodedToken.appRole || [],
        };

        // Save the complete session data object
        encryptStorage.setItem(encryptKeys.userDetails, JSON.stringify(userData));

      }

      return response;

    }
  } catch (error: any) {
    console.error("Error refreshing tokens:", error);
  }
}

export default Client;