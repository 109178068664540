import Client from "../client/client";
import { operatorConsoleService, baseURL,workItems } from "../constant";
import { DeleteWorkItemPayLoad, EscalateToCustomerPayLoad, QueuedItemsPayLoad, TakeOverWorkItemPayLoad, TakeWorkItemPayLoad, AssignWorkItemPayLoad, GetActiveOperatorsPayLoad, ReArrangeWorkItemPayLoad } from "../Interfaces/work_items_interface";

/**PS_05 - PS_09 - Async Function to get the QueuedWorkitems based on the current workItem Progress  */
const getQueuedWorkItems = async (payload: QueuedItemsPayLoad) => {

  try {
    const config = {
      method: "post",
      url: `${baseURL}${operatorConsoleService.getQueuedWorkItems}`,
      data: payload,
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service getQueuedWorkItems", error);
    throw error
  }
}

/**PS_05 - PS_09 - Async Function to get the QueuedWorkitems based on the current workItem Progress  */
const escalateToCustomerAPI = async (payload: EscalateToCustomerPayLoad) => {

  try {
    const config = {
      method: "post",
      url: `${baseURL}${operatorConsoleService.escalateToCustomer}`,
      data: payload,
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service EsclateToCustomer", error);
    throw error
  }
}

/**PS_05 - PS_09 - Async Function to get the QueuedWorkitems based on the current workItem Progress  */
const deleteWorkItem = async (payload: DeleteWorkItemPayLoad) => {
  try {
    const config = {
      method: "Delete",
      url: `${baseURL}${operatorConsoleService.deleteWorkItem}/${payload.work_item_uuid}`,
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service deleteWorkItem", error);
    throw error
  }
}

const takeWorkItem = async (payload: TakeWorkItemPayLoad) => {
  try {
    const config = {
      method: "post",
      url: `${baseURL}${operatorConsoleService.takeWorkItem}`,
      data: payload
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service takeWorkItem", error);
    throw error
  }
}
const getStatusService = async () => {
  try{
    const config = {
      method : "Get",
      url: `${baseURL}${operatorConsoleService.getStatus}`,
    };
    const response = await Client(config);
    return response
  }
  catch(error) {
    throw error
  }
}


const takeOverWorkItem = async (payload: TakeOverWorkItemPayLoad) => {
  try {
    const config = {
      method: "post",
      url: `${baseURL}${operatorConsoleService.takeOverWorkItem}`,
      data: payload
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service takeOverWorkItem", error);
    throw error
  }
}
const getUserStatusService = async(userUUID : string) => {
  try{
    const config = {
      method : "Get",
      url: `${baseURL}${operatorConsoleService.getUserRecentStatus}/${userUUID}`,
    };
    const response = await Client(config);
    return response
  }
  catch(error) {
    throw error
  }
}

const assignWorkItem = async (payload: AssignWorkItemPayLoad) => {
  try {
    const config = {
      method: "post",
      url: `${baseURL}${operatorConsoleService.assignQueuedWorkItem}`,
      data: payload
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service assignWorkItem", error);
    throw error
  }
}

const getAvailableOperators = async (payload:GetActiveOperatorsPayLoad) => {
  try {
    const config = {
      method: "post",
      url: `${baseURL}${operatorConsoleService.getAvailableOperators}`,
      data: payload
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service getAvailableOperators", error);
    throw error
  }
}


const updateItemPriority = async (payload: ReArrangeWorkItemPayLoad) => {
  try {
    const config = {
      method: "post",
      url: `${baseURL}${operatorConsoleService.reArrangeWorkItem}`,
      data: payload
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service updateItemPriority", error);
    throw error
  }
}

const getScheduleDetailsService = async(user_uuid: string) => {
  try {
      const config = {
          method: "get",
          url: `${baseURL}${workItems.getScheduleDetails}/${user_uuid}`,
      };

      // PS_BT_09: Return the response from the backend which includes status and break templates data.
      // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
      const response = await Client(config);
      return response;
  }
  catch (err) {
      // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
      return err;
  }
}

const updateUserActivityStatus = async(payload: any) => {
  try {
      const config = {
          method: "post",
          url: `${baseURL}${workItems.updateActivityStatus}`,
          data: payload,
      };

      // PS_BT_09: Return the response from the backend which includes status and break templates data.
      // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
      const response = await Client(config);
      return response;
  }
  catch (err) {
      // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
      return err;
  }
}

const publishNotification = async(payload :any) => {
  try{
    const config = {
      method : "post",
      url : `${baseURL}${workItems.publishNotification}`,
      data : payload
    }
    const response = await Client(config);
    return response;
  }
  catch(err){
    return err;
  }
}

const assignWorkItemService = async(payload :any) => {
  try{
    const config = {
      method : "post",
      url : `${baseURL}${operatorConsoleService.assignWorkItem}`,
      data : payload
    }
    const response = await Client(config);
    return response;
  }
  catch(err){
    return err;
  }
}

export const getScheduleService = async(userUUID : string) => {
  try {
      const config = {
          method: 'get',
          url: `${baseURL}${operatorConsoleService.getSchedule}/${userUUID}`,
      }
      const response = await Client(config);
      return response;
  }
  catch(err){
      return err;
  }
}

export const updateScheduleService = async(payload : any) => {
  try {
      const config = {
          method: 'post',
          url: `${baseURL}${operatorConsoleService.updateSchedule}`,
          data: payload
      }
      const response = await Client(config);
      return response;
  }
  catch(err){
      return err;
  }
}

export const getBreakShiftAndTimeZonesService = async(payload: any) => {
  try{
      const config = {
          method : 'post',
          url: `${baseURL}${operatorConsoleService.getShiftBreakAndTimeZoneDetails}`,
          data: payload
      }
      const response = await Client(config)
      return response
  }
  catch(err){
      return err
  }
}

export { getQueuedWorkItems, escalateToCustomerAPI, deleteWorkItem, takeWorkItem, takeOverWorkItem, assignWorkItem, getAvailableOperators, updateItemPriority,getStatusService, getUserStatusService, updateUserActivityStatus, getScheduleDetailsService, publishNotification, assignWorkItemService }
