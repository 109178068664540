/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { createShiftTemplateService, getShiftTemplateByUUIDService, updateShiftTemplateService } from "../../services/shift_templates_service";
import { getBreakShiftAndTimeZonesService } from "../../services/operator_service";

export function ShiftTemplatePopup({ mode, shiftTemplateUuid, onClose, onAddOrUpdate }: any) {
    const [shiftName, setShiftName] = useState<string>("");
    const [timeZones, setTimeZones] = useState<any>([]);
    const [selectedTimeZone, setSelectedTimeZone] = useState<string>("");
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    const [initialShiftName, setInitialShiftName] = useState<string>("");
    const [initialStartTime, setInitialStartTime] = useState<string>("");
    const [initialEndTime, setInitialEndTime] = useState<string>("");
    const [initialSelectedTimeZone, setInitialSelectedTimeZone] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        fetchTimeZoneDetails();
    }, []);

    useEffect(() => {
        if (mode === 'edit' && shiftTemplateUuid) {
            fetchShiftTemplateDetails();
        }
    }, [mode, shiftTemplateUuid]);

    useEffect(() => {
        checkButtonDisabled();
    }, [shiftName, selectedTimeZone, startTime, endTime]);

    const formatTime = (time: any) => {
        const date = new Date(time);
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const toTimestamp = (time: string) => {
        const [hours, minutes] = time.split(":").map(Number);
        const date = new Date();
        date.setUTCHours(hours, minutes, 0, 0);
        return date.toISOString();
    };

    const fetchTimeZoneDetails = async () => {
        const payload: any = {
            break_details: false,
            shift_details: false,
            time_zone_details: true
        };
        try {
            const response: any = await getBreakShiftAndTimeZonesService(payload);
            if (response?.status === 200) {
                setTimeZones(response?.data?.data?.time_zones);
            }
        } catch (error: any) {
            console.error(error);
        }
    };

    const fetchShiftTemplateDetails = async () => {
        try {
            const response = await getShiftTemplateByUUIDService(shiftTemplateUuid);
            const data = response?.data?.data;
            const formattedStartTime = formatTime(data?.start_time);
            const formattedEndTime = formatTime(data?.end_time);

            setShiftName(data?.shift_name);
            setStartTime(formattedStartTime);
            setEndTime(formattedEndTime);
            setSelectedTimeZone(data?.time_zone?.time_zone_uuid);

            setInitialShiftName(data?.shift_name);
            setInitialStartTime(formattedStartTime);
            setInitialEndTime(formattedEndTime);
            setInitialSelectedTimeZone(data?.time_zone?.time_zone_uuid);
        } catch (error) {
            console.error('Error fetching shift template details:', error);
        }
    };

    const checkButtonDisabled = () => {
        if (!shiftName || !startTime || !endTime || !selectedTimeZone || startTime === endTime) {
            setIsButtonDisabled(true);
            return;
        }
        if (mode === "edit") {
            let start = startTime.includes("T") ? formatTime(startTime) : startTime;
            let end = endTime.includes("T") ? formatTime(endTime) : endTime;
            if (
                shiftName === initialShiftName &&
                start === initialStartTime &&
                end === initialEndTime &&
                selectedTimeZone === initialSelectedTimeZone
            ) {
                setIsButtonDisabled(true);
                return;
            }
        }
        setIsButtonDisabled(false);
    };

    const handleAddOrUpdateClick = async () => {
        if (isButtonDisabled) return;

        const payload: any = {
            time_zone_uuid: selectedTimeZone,
            shift_name: shiftName,
            start_time: toTimestamp(startTime),
            end_time: toTimestamp(endTime)
        };

        if (mode === 'edit') {
            payload["shift_template_uuid"] = shiftTemplateUuid;
        }

        try {
            if (mode === 'edit') {
                const response: any = await updateShiftTemplateService(payload);
                if (response?.status === 200) {
                    onClose();
                    onAddOrUpdate();
                }
            } else {
                const response: any = await createShiftTemplateService(payload);
                if (response?.status === 201) {
                    onClose();
                    onAddOrUpdate();
                }
            }
        } catch (err: any) {
            if (err?.status === 409) {
                setErrorMessage(err?.response?.data?.message);
            }
            console.error('Error handling shift template:', err);
        }
    };

    return (
        <div
            className="modal show fade custom-popup"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            id="staticBackdrop-1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title font-16 font-bold color-black" id="staticBackdropLabel">
                            {mode === 'add' ? 'Add Shift Template' : 'Edit Shift Template'}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
                    </div>
                    <div className="modal-body custom-popup-form">
                        <div className="row justify-content-between">
                            <div className="col-md-6 field-size">
                                <label htmlFor="shift-name" className="m-0 font-semibold font-13 color-black-v1 mb-2">
                                    Shift Name<span className="color-red">*</span>
                                </label>
                                <div className="input-group rounded-2 overflow-hidden input-grp-border">
                                    <input
                                        id="shift-name"
                                        type="text"
                                        className="form-control font-12 shadow-none border-0"
                                        placeholder="Enter Shift Name"
                                        value={shiftName}
                                        onChange={(e) => {setShiftName(e.target.value); setErrorMessage("")}}
                                        maxLength={255}
                                    />
                                </div>
                                {errorMessage && (
                                    <div className="error text-danger font-12">{errorMessage}</div>
                                )}
                            </div>
                            <div className="col-md-6 field-size">
                                <label htmlFor="time-zone" className="m-0 font-semibold font-13 color-black-v1 mb-2">
                                    Time Zone<span className="color-red">*</span>
                                </label>
                                <select
                                    className="form-select font-13 custom-shift-select"
                                    id="time-zone"
                                    aria-label="Default select example"
                                    value={selectedTimeZone}
                                    onChange={(e) => setSelectedTimeZone(e.target.value)}
                                >
                                <option value={""} className="font-12">Select</option>
                                    {timeZones?.map((timeZone: any) => (
                                        <option key={timeZone?.time_zone_uuid} value={timeZone?.time_zone_uuid} className="font-12">
                                            {timeZone?.time_zone_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <h2 className="font-14 font-bold color-black mt-4 mb-3">Shift Timings</h2>
                        <div className="row justify-content-between">
                            <div className="col-md-6 field-size">
                                <label htmlFor="start-time" className="m-0 font-semibold font-13 color-black-v1 mb-2">
                                    Start time<span className="color-red">*</span>
                                </label>
                                <input
                                    type="time"
                                    id="start-time"
                                    className="form-control font-12 theme-input shadow-none"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6 field-size">
                                <label htmlFor="end-time" className="m-0 font-semibold font-13 color-black-v1 mb-2">
                                    End time<span className="color-red">*</span>
                                </label>
                                <input
                                    type="time"
                                    id="end-time"
                                    className="form-control font-12 theme-input shadow-none"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end gap-3 mt-2 mb-3">
                                <button className="secondary-btn rounded-3" type="button" onClick={onClose}>
                                    <span className="d-inline-block my-1">Cancel</span>
                                </button>
                                <button
                                    className="primary-btn rounded-3"
                                    type="button"
                                    onClick={handleAddOrUpdateClick}
                                    disabled={isButtonDisabled}
                                >
                                    <span className="d-inline-block my-1">{mode === 'add' ? 'Add' : 'Update'}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}