import React from 'react';

/*PS_54 - Component to handle the noRecordFound */

// TypeScript type for the component props
type NoRecordsFoundProps = {
    colspan: number;
    mainContent: string;
    subContent: string;
    columnHeight:string
};

// NoRecordsFoundComponent with props type defined
const NoRecordsFoundComponent: React.FC<NoRecordsFoundProps> = ({ colspan, mainContent, subContent ,columnHeight }) => {
    return (
            <tr>
                <td colSpan={colspan}>
                    <div className="e-content-wrapper d-flex align-items-center justify-content-center flex-column gap-2" style={{ height: `${columnHeight}vh` }}
                    >
                        <img src="img/items-icon.svg" alt="list" />
                        <h4 className="font-semibold font-18 color-black m-0">{mainContent}</h4>
                        <p className="font-semibold font-13 color-grey-v1 m-0">{subContent}</p>
                    </div>
                </td>
            </tr>
    );
};

export default NoRecordsFoundComponent


/** How to use
 *   {noContent && (
                <NoRecordsFoundComponent 
                    colspan={8} 
                    mainContent="No EscalateTo Lane Work Items Found" 
                    subContent="Work Items will be listed once escalated"
                    columnHeight ="78" in vh
                />
            )}
 */
