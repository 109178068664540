import Client from "../client/client";
import { insightService, baseURL } from "../constant";
import { CompletedGridPayLoad } from "../Interfaces/insight_interface";

/**PS_05 - PS_09 - Async Function to get the getCompletedWorkItems */
const fetchCompletedWorkItems = async (payload: CompletedGridPayLoad) => {

    try {
        const config = {
            method: "post",
            url: `${baseURL}${insightService.getCompletedWorkItems}`,
            data: payload,
        };
        const response = await Client(config);      
        return response
    } catch (error) {
        console.log("insightService getCompletedWorkItems", error);    
        return error
    }
}

export { fetchCompletedWorkItems }