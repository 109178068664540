import Client from "../client/client";
import { baseURL, notificationEndpoints, notifications } from "../constant";
import { CreateNotificationRequest, GetNotificationRequest, UpdateNotificationRequest } from "../Interfaces/notification_interface";


export const createNotificationService = async (payload: CreateNotificationRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${notificationEndpoints.createNotification}`,
            data: payload,
        };
        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const getUserNotifications = async (notificationRequest: GetNotificationRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${notifications.getNotifications}`,
            data: notificationRequest,
        };
        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error;
    }
}

export const updateUserNotifications = async (notificationRequest: UpdateNotificationRequest) => {
    try {
        const config = {
            method: "POST",
            url: `${baseURL}${notifications.updateNotifications}`,
            data: notificationRequest,
        };
        const response = await Client(config);
        return response;
    } catch (error: any) {
        return error
    }
}