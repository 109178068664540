/* eslint-disable react-hooks/exhaustive-deps */
import Form from "@rjsf/core";
import "../../App.css";
import { RegistryWidgetsType, RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useCallback, useEffect, useRef, useState } from "react";
import { LiveFeedPanel } from "../../reusable_components/panels/live_feed_panel";
import { EquipmentClassificationPanel } from "../../reusable_components/panels/equipment_classification_panel";
import { StatusBarPanel } from "../../reusable_components/panels/status_bar_panel";
import ObjectFieldTemplate from "../../reusable_components/templates/column_object_field_template";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CompletedWorkItemRequest,
  // CompletedWorkItemRequest,
  EscalateToLaneRequest,
  FetchLayoutJsonRequest,
  RequeueWorkItemRequest,
  UpdateUserStatusRequest,
} from "../../Interfaces/work_item_interface";
import {
  completedWorkItemService,
  escalatetoLaneService,
  fetchLayoutJsonService,
  getStatusService,
  requeueWorkItemService,
  updateUserActivityStatusService,
} from "../../services/work_item_service";
import { FullscreenLoader } from "../loader";
import { VisitMatchupPanel } from "../../reusable_components/panels/visit_matchup_panel";
import { applicationAbbrievation, NotificationCategories, NotificationTypes, UserRoles, UserStatus, WebsocketCodes } from "../../enums/enums";
import WebsocketClient from "../../client/websocket";
import { overallContext } from "../../context/Context";
import { useContext } from "react";
import { fetchUserDetailsFromStorage } from "../../utils/utils";
import { addOldValueKey, calculateDurationInSeconds } from "../../utils/utils";
import moment from "moment";
import { CreateNotificationRequest } from "../../Interfaces/notification_interface";
import { createNotificationService } from "../../services/notification_service";

// PS_01 to PS_13
const WorkItem = () => {
  const { userId, appRole } = fetchUserDetailsFromStorage()
  const applicationUUID = appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-";
  const { setIsEquipmentCorrection } = useContext(overallContext);
  const navigate = useNavigate();
  let navigationProps = JSON.parse(JSON.stringify(useLocation().state))
  navigationProps.portal_data = JSON.parse(navigationProps.portal_data)
  const workItemJSON = useRef<any>(navigationProps.portal_data)
  const { completed } = navigationProps
  workItemJSON.current.scan_data.old_classification = workItemJSON.current.scan_data.classification
  addOldValueKey(workItemJSON.current)
  const websocket = useRef<WebSocket>()
  const startTime = useRef<string>(new Date().toISOString());
  const [loader, setLoader] = useState<boolean>(false);
  const [match, setMatch] = useState<boolean>(true)
  const [isFocusedToInputField, setIsFocusedToInputField] = useState<boolean>(false)
  const jsonSchema = useRef<RJSFSchema>({})
  const uiSchema = useRef<UiSchema>({
    "ui:submitButtonOptions": {
      norender: true,
    },
  });
  const widgets: RegistryWidgetsType = {
    EquipmentClassificationPanel,
    StatusBarPanel,
    LiveFeedPanel,
    VisitMatchupPanel,
  };
  const { lane_id, lane_name } = navigationProps;
  const [laneDetails, setLaneDetails] = useState<any>({
    lane_id: lane_id,
    lane_name: lane_name
  });

  const fetchLayoutJson = useCallback(async () => {
    try {
      const { app_work_item_uuid } = navigationProps;
      const payload: FetchLayoutJsonRequest = {
        app_work_item_uuid: app_work_item_uuid
      };
      const response = await fetchLayoutJsonService(payload);
      if (response?.status === 200) {
        const { json_schema, ui_schema } = response?.data?.data?.json_schema
        jsonSchema.current = json_schema
        uiSchema.current = ui_schema
      } else {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      console.log("an error occured: ", error);
    }
  }, [navigationProps]);

  //PS_48 to PS_53
  const handleOnMessage = useCallback(
    async (event: any) => {
      const { work_item_uuid } = navigationProps
      try {
        const receivedMessage = JSON.parse(event.data);
        if (receivedMessage?.data?.type === WebsocketCodes.TAKEOVER && receivedMessage?.data?.data?.work_item_uuid === work_item_uuid) {
          setIsEquipmentCorrection(false)
          await UpdateUserStatus(UserStatus.IDLE, "Work item has been take over by the supervisor")
          navigate("/workItemNotifications", { replace: true, state: { "takeover": true } });
          const payload: CreateNotificationRequest = {
            type: NotificationTypes.DIRECT,
            destination: [userId],
            application_uuid: applicationUUID,
            notification_details: {
              notification_category: NotificationCategories.WORKITEM,
              notification_title: "Work Item",
              notification_message: "The \"work item\" has been \"taken over\" by the \"supervisor\".",
              customer_name: navigationProps.customer_name,
              site_name: navigationProps.site_name,
              app_work_item_uuid: navigationProps.app_work_item_uuid,
            }
          }
          const response = await createNotificationService(payload)
          if (response.status !== 201) {
            throw new Error(response?.error);
          }
        } else if (receivedMessage?.data?.type === WebsocketCodes.LANEUPDATE) {
          setLaneDetails(receivedMessage?.data);
        }
      } catch (error: any) {
        console.log("an error occurred: ", error);
      }
    },
    [navigate]
  );
  const handleOnError = useCallback((error: any) => {
    console.log("an error occured in websocket:", error);
  }, []);

  const loadInitialPageData = async () => {
    setLoader(true);
    await fetchLayoutJson();
    setLoader(false);
  }

  useEffect(() => {
    const { work_item_uuid } = navigationProps
    if (!navigationProps?.completed) {
      const socket = WebsocketClient(work_item_uuid + WebsocketCodes.WORKITEMCORRECTION, []);
      websocket.current = socket;
      socket.onmessage = handleOnMessage;
      socket.onerror = handleOnError;
    }
    loadInitialPageData();
    return () => {
      if (websocket.current) {
        websocket.current.close()
      }
    };
  }, [handleOnError, handleOnMessage]);

  const UpdateUserStatus = async (category: UserStatus, reason: string) => {
    try {
      const statusResponse = await getStatusService();
      if (statusResponse?.status !== 200) {
        throw new Error(statusResponse?.error);
      }
      const { user_status_uuid } = statusResponse?.data?.data?.find(
        (eachStatus: any) => eachStatus.status_name === category
      );
      if (!user_status_uuid) {
        throw new Error(`${category} uuid not found`);
      }
      const payload: UpdateUserStatusRequest = {
        user_uuid: userId,
        user_status_uuid: user_status_uuid,
        reason: reason,
        category: category,
      };
      const response = await updateUserActivityStatusService(payload);
      if (response?.status !== 200) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      console.log("An error occured: ", error)
    }
  }

  useEffect(() => {
    if (!completed) {
      setIsEquipmentCorrection(true);
    }
  }, [])
  //PS_40 to PS_47
  const handleSubmit = async (newPortalJson: any) => {
    try {
      setLoader(true)
      await UpdateUserStatus(UserStatus.IDLE, "User has completed the work item")
      const startedFormattedTime = moment.utc().format("YYYY-MM-DD HH:mm:ss")
      const completionTime = moment.utc().format("YYYY-MM-DD HH:mm:ss")
      const completedOn = new Date().toISOString();
      const completionDurationSec: number = calculateDurationInSeconds(
        startTime.current,
        completedOn
      );
      const { work_item_uuid, customer_uuid, site_uuid, work_item_type, customer_name, site_name, expected_processing_time_sec, max_processing_time_sec } = navigationProps
      newPortalJson.utc_date_time_completed = completionTime
      const payload: CompletedWorkItemRequest = {
        customer_id: customer_uuid,
        site_id: site_uuid,
        area_id: "AREA_01",
        customer_name: customer_name,
        site_name: site_name,
        started_on: startedFormattedTime,
        expected_processing_time_sec: expected_processing_time_sec,
        max_processing_time_sec: max_processing_time_sec,
        group_code: laneDetails.lane_id,
        work_item_type: work_item_type,
        work_item_id: work_item_uuid,
        completion_duration_sec: completionDurationSec,
        completed_on: completionTime,
        completed_work_item_data: newPortalJson,
      };
      console.log("notificationpayload", payload)
      const response = await completedWorkItemService(payload);
      if (response?.status !== 200) {
        throw new Error(response?.error);
      }
      setIsEquipmentCorrection(false)
      await UpdateUserStatus(UserStatus.IDLE, "The Operator has completed the work item")
      navigate("/workItemNotifications", { replace: true });
    } catch (error: any) {
      console.log("an error occured: ", error);
    } finally {
      setLoader(false)
    }
  }
  //PS_14 to PS_23
  const handleEscalateToLane = async () => {
    try {
      setLoader(true)
      const { work_item_uuid } = navigationProps;
      const payload: EscalateToLaneRequest = {
        work_item_uuid,
      };
      const response = await escalatetoLaneService(payload);
      if (response?.status !== 200) {
        throw new Error(response?.error);
      }
      setIsEquipmentCorrection(false)
      await UpdateUserStatus(UserStatus.IDLE, "The user has escalated the work item to the lane")
      navigate("/workItemNotifications", { replace: true });
    } catch (error: any) {
      console.log("an error occured: ", error);
    } finally {
      setLoader(false)
    }
  }

  const handleReorderWorkItem = async (setOperatorToDnd: boolean) => {
    try {
      setLoader(true)
      const { work_item_uuid } = navigationProps
      if (setOperatorToDnd) {
        await UpdateUserStatus(UserStatus.DND, "Operator did not work in work item for too long")
      } else {
        await UpdateUserStatus(UserStatus.IDLE, "The operator no longer works on the work item")
      }
      const payload: RequeueWorkItemRequest = {
        workitem_id: work_item_uuid
      }
      const response = await requeueWorkItemService(payload)
      if (response?.status !== 200) {
        throw new Error(response?.error);
      }
      setIsEquipmentCorrection(false)
      navigate("/workItemNotifications", { replace: true });
      if (setOperatorToDnd) {
        const notificationPayload: CreateNotificationRequest = {
          type: NotificationTypes.BROADCAST,
          destination: [UserRoles.supervisor],
          application_uuid: applicationUUID,
          notification_details: {
            notification_category: NotificationCategories.WORKITEM,
            notification_title: "Work Item",
            notification_message: "The \"work item\" has been \"requeued\" due to \"operator inactivity\".",
            customer_name: navigationProps.customer_name,
            site_name: navigationProps.site_name,
            app_work_item_uuid: navigationProps.app_work_item_uuid,
          }
        }
        const response = await createNotificationService(notificationPayload)
        if (response.status !== 201) {
          throw new Error(response?.error);
        }
      }
    }
    catch (error: any) {
      console.log("an error occured: ", error);
    } finally {
      setLoader(false)
    }
  }



  const handleFocusChange = (isFocused: boolean) => {
    setIsFocusedToInputField(isFocused)
  }


  return (
    <>
      {loader ? (
        <FullscreenLoader />
      ) : (
        <Form
          schema={jsonSchema.current}
          uiSchema={uiSchema.current}
          templates={{ ObjectFieldTemplate }}
          validator={validator}
          onSubmit={handleSubmit}
          formContext={{
            workItemJSON: workItemJSON.current,
            handleSubmit,
            handleEscalateToLane,
            handleReorderWorkItem,
            laneDetails,
            navigationProps,
            match,
            setMatch,
            isFocusedToInputField,
            handleFocusChange
          }}
          widgets={widgets}
        />
      )}
    </>
  );
};

export default WorkItem;