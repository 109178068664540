
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Applications from './components/application/applications';
import { encryptKeys, encryptStorage } from './constant';
import Login from './components/login/login';
import Header from './components/login/header';
import LoginAuth from './components/login/login_auth';
import { BreakTemplates } from './components/break_template/break_templates';
import NotFoundComponent from './components/404NotFound';
import ErrorPage from './components/ErrorPage';
import { OperatorNotifications } from './components/operations/work_item_notifications'
import WorkItem from './components/work_item/work_item';
import { EquipmentContext } from './context/Context';
import UserActivity from './components/user_activity/user_activity';
const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
// Protected Route component
const ProtectedRoute = ({ children }: any) => {
    if (!userDetailsString) {
        return <Navigate to="/" replace />;

    }

    const userDetails = JSON.parse(userDetailsString);

    if (!userDetails.accessToken) {
        return <Navigate to="/" replace />;
    }

    return children;
};

function App() {
    return (
        <BrowserRouter>
            <EquipmentContext>
                <React.Fragment>
                    {(window.location.pathname !== "/") && <Header />}
                    <Routes>

                        <Route path='/' element={<Login />} />
                        <Route path='/loginAuth' element={<LoginAuth />} />
                        <Route path='applications' element={<ProtectedRoute><Applications /></ProtectedRoute>} />
                        <Route path='operations' element={<ProtectedRoute><UserActivity /></ProtectedRoute>} />
                        <Route path="/breakTemplate" element={<BreakTemplates />} />
                        <Route path="/notFound" element={<NotFoundComponent />} />
                        <Route path="/errorPage" element={<ErrorPage />} />
                        <Route path="/workItemNotifications" element={<OperatorNotifications />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                        <Route path="/workItem" element={<WorkItem />} />

                    </Routes>
                </React.Fragment>
            </EquipmentContext>
        </BrowserRouter>
    );
}

export default App;