/* eslint-disable jsx-a11y/anchor-is-valid */

const ErrorPage = () => {
    return (
        <div className="col-md-12 float-start mb-5">
            <div className="col-md-12 text-center">
            <a><img className="no-search" src="/img/error-page.png" alt="Noserachresults" /> </a>
            </div>
            <div className="col-md-12 text-center my-3">
                <h5 className="footer11">Error</h5>
            </div>
            <div className="col-md-12 text-center mb-4">
                <h5 className="search-name">We can't find the page what you're looking for...</h5>
            </div>
        </div>
    );
};

export default ErrorPage;
