/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/**PS_01 - Importing of the packages and declaration of state variables */

import React, { useState, useCallback, useRef, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Timer from "./timer";
import { AssignWorkItemPayLoad, GetActiveOperatorsPayLoad, Message, QueuedItemsPayLoad, ReArrangeWorkItemPayLoad, SelectedOperator, SortConfig, TakeWorkItemPayLoad, WorkItemQueueNotificationData, WorkItems } from "../../Interfaces/work_items_interface";
import NoRecordsFoundComponent from "../../reusable_components/noRecordsFoundComponent";
import ToastMessage from "../../reusable_components/toast";
import { assignWorkItem, getAvailableOperators, getQueuedWorkItems, takeWorkItem, updateItemPriority } from "../../services/operator_service";
import { DndProvider, useDrag, useDrop, DropTargetMonitor, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import moment from "moment";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { baseURL, encryptStorage, encryptKeys, notificationSubscribe, operatorNotifications, formatDescription } from "../../constant";
import { WorkItemStatus } from '../../enums/enums';
import { v4 as uuidv4 } from 'uuid'; // Import the v4 function from uuid


const QueuedWorkItems = () => {

  const [queuedWorkItems, setQueuedWorkItems] = useState<WorkItems[]>([]); // using mock data for state
  const [recordCount, setRecordCount] = useState(10);
  const [totalRecordCount, setTotalRecordCount] = useState<number>(0)
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate;
  const [availableOperators, setAvailableOperators] = useState<any>({});
  const [assignedOperators, setAssignedOperators] = useState<any>({});
  const [visibleDropdown, setVisibleDropdown] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [hoveredOperatorId, setHoveredOperatorId] = useState<any>()
  const ItemType = 'sortable-item';
  const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
  const userDetails = JSON.parse(userDetailsString);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
  const webSocketId = uuidv4();


  /**PS_02 - PS_03 invoke the fetchQueueWorkItems in the empty dependency useEffect */
  /**PS_QU_LIVE_03 - PS_QU_LIVE_09 - Establishing the Connection to get the live data in In Queued data */

  useEffect(() => {
    fetchQueuedWorkItems();
    let modified_url = baseURL ? baseURL.replace("https://", "") : "-"
    const newSocket = new WebSocket(
      `wss:${modified_url}${notificationSubscribe.getLiveSubscribeNotification}${operatorNotifications.getLiveWorkItemsNotification}&userID=${userDetails.userId}`
    );

    newSocket.onopen = () => {
      console.log("connection opened")
    };

    newSocket.onmessage = (event: any) => {
      let message = JSON.parse(event.data);
      try {
        const data: Message = message.data;
        handleSocketMessage(data);

      } catch (error) {
        console.error("Parsing error:", error);
      }
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      newSocket.close();
    }
  }, []);

  /**PS_QU_LIVE_11 - PS_QU_LIVE_20 - Based on the oldStatus and newStatus,the Data is getting manipulated in In Queued data */


  const handleSocketMessage = (data: any) => {
    const { oldStatus, newStatus, workItemJson }: WorkItemQueueNotificationData = data;
    if (newStatus === WorkItemStatus.Queued || oldStatus === WorkItemStatus.Queued) {
      // Check if sessionWorkItems is null and initialize updatedWorkItems accordingly
      const sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems');
      let updatedWorkItems: any[] = sessionWorkItems ? JSON.parse(sessionWorkItems) : [];
      const existingIndex = updatedWorkItems.findIndex(item => item.work_item_uuid === workItemJson.work_item_uuid);
      // Handle state transitions based on oldState and newState
      if (oldStatus === WorkItemStatus.Queued && newStatus === WorkItemStatus.Requeued) {
        if (existingIndex !== -1) {
          updatedWorkItems[existingIndex] = workItemJson;
          setTotalRecordCount(prevCount => prevCount + 1);
          setQueuedWorkItems(updatedWorkItems?.slice(0, recordCount));
          sessionStorage.setItem('queuedWorkItems', JSON.stringify(updatedWorkItems));
        }
      }
      else if (newStatus === WorkItemStatus.Queued) {
        if (existingIndex === -1) {
          updatedWorkItems.push(workItemJson);
          setTotalRecordCount(prevCount => prevCount + 1);
          setQueuedWorkItems(updatedWorkItems?.slice(0, recordCount));
          sessionStorage.setItem('queuedWorkItems', JSON.stringify(updatedWorkItems));
        }
      } else if (oldStatus === WorkItemStatus.Queued) {
        if (existingIndex !== -1) {
          updatedWorkItems.splice(existingIndex, 1);
          setTotalRecordCount(prevCount => prevCount - 1);
          setQueuedWorkItems(updatedWorkItems?.slice(0, recordCount));
          sessionStorage.setItem('queuedWorkItems', JSON.stringify(updatedWorkItems));
        }
      }

    }
  };

  useEffect(() => {
    const sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems');
    let sorted = JSON.parse(sessionWorkItems);
    sorted?.sort((a: any, b: any) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    // Update state with the sorted items, limiting to recordCount
    setQueuedWorkItems(sorted ? sorted?.slice(0, recordCount) : []);
  }, [sortConfig])


  /**PS_42 - Function to handle the Sorting of the queued Work Items */
  const handleSort = (key: string) => {
    // Update sort configuration state
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };


  /**PS_03 - PS_21 Function to fetch the queued Work Items  */
  const fetchQueuedWorkItems = async () => {
    try {
      const payload: QueuedItemsPayLoad = {
        work_item_status: WorkItemStatus.Queued
      };
      const response: any = await getQueuedWorkItems(payload);
      if (response?.status === 200) {
        if (response?.data) {
          sessionStorage.setItem('queuedWorkItems', JSON.stringify(response.data?.data));
          setQueuedWorkItems(response.data?.data?.slice(0, recordCount));
          setTotalRecordCount(response.data?.data?.length || 0);
        }
      }
    } catch (error: any) {
      if (error.message !== "Request failed with status code 404") {
        handleShowToast(true, "Error", error.message);
      }
    }
  };

  const handleAssignOperator = async (workItemUUID: string, operatorUUID: string, operatorName: string) => {
    try {
      const payload: AssignWorkItemPayLoad = {
        work_item_uuid: workItemUUID,
        operator_uuid: operatorUUID
      };
      const response: any = await assignWorkItem(payload);
      if (response?.status === 200) {
        handleShowToast(true, "Success", "Work Item Assigned Successfully");
        let sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems')
        let queuedWorkitems = JSON.parse(sessionWorkItems)
        const newQueuedWorkItems = queuedWorkitems?.map((item: any) => {
          if (item.work_item_uuid === workItemUUID) {
            return { ...item, is_assigned: true, assigned_to: operatorUUID, assigned_operator_name: operatorName };
          }
          return item;
        });
        sessionStorage.setItem('queuedWorkItems', JSON.stringify(newQueuedWorkItems));
        setQueuedWorkItems(newQueuedWorkItems?.slice(0, recordCount));
      }
      else {
        handleShowToast(true, "Error", response?.data?.message);
      }
    }
    catch (error: any) {
      handleShowToast(true, "Error", error?.response?.data?.message);
    }
  };

  const showOperatorsDropdown = async (workItem: WorkItems) => {
    if (visibleDropdown === workItem.work_item_uuid) {
      // Close the currently open dropdown if it's clicked again
      setVisibleDropdown(null);
      setSearchTerm("")
    } else {
      // Set the visible dropdown and fetch the available operators
      setVisibleDropdown(workItem.work_item_uuid);
      setSearchTerm("")
      await fetchActiveOperators(workItem.work_item_uuid, workItem.site_uuid, workItem.customer_uuid);
    }
  };

  /**PS_03 - PS_21 Function to fetch the queued Work Items  */
  const fetchActiveOperators = async (workItemId: string, siteId: string, customerId: string) => {
    try {
      const payload: GetActiveOperatorsPayLoad = {
        work_item_uuid: workItemId,
        site_id: siteId,
        customer_id: customerId
      };
      const dummyOperators = [
        {
          operator_uuid: "192e9839-0150-4000-8516-8090ab69a501",
          operator_name: "John Doe",
          operator_status: "IDLE"
        },
        {
          operator_uuid: "192e9839-0150-4000-876a-954e2d602201",
          operator_name: "Jane Smith",
          operator_status: "WORKING"
        },
        {
          operator_uuid: "192e9839-0150-4000-8d17-a2ded76c7f01",
          operator_name: "Alice Johnson",
          operator_status: "WORKING"
        },
        {
          operator_uuid: "192e9839-0150-4000-8295-a391d1227901",
          operator_name: "Bob Brown",
          operator_status: "ACTIVE"
        }
      ];
      setAvailableOperators(() => ({
        [workItemId]: dummyOperators || [] // Map the operator data to the workItem_uuid
      }));
      const response: any = await getAvailableOperators(payload);
      if (response?.status === 200) {
        if (response?.data) {
          setAvailableOperators(() => ({
            [workItemId]: response.data?.data || [] // Map the operator data to the workItem_uuid
          }));
        }
      }
    } catch (error: any) {
      handleShowToast(true, "Error", error?.response?.data?.message);
    }
  };

  /*SQ_EX_22.3 - Helper function to set the renderSortIcon Term */
  const renderSortIcon = (key: string) => {
    const iconSrc = sortConfig.key === key
      ? `img/sort-${sortConfig.direction === 'asc' ? 'up' : 'down'}-arrow.svg`
      : "img/sort-down-arrow.svg";
    return <img src={iconSrc} alt="sort-arrow" />;
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  interface DragItem {
    index: number;
    type: string;
  }

  interface SortableItemProps {
    item: WorkItems;
    index: number;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
  }
  interface SortableListProps {
    items: WorkItems[];
    moveItem: (dragIndex: number, hoverIndex: number) => void;
  }
  const SortableList: React.FC<SortableListProps> = ({ items, moveItem }) => {

    if (items?.length === 0) {
      return <NoRecordsFoundComponent
        colspan={5}
        mainContent="No Queued Work Items Found"
        subContent="Work items will be listed once created"
        columnHeight='40'
      />
    }
    return (
      <tbody>
        {items?.map((item: any, index: number) => (
          <SortableItem key={item.work_item_uuid} item={item} index={index} moveItem={moveItem} />
        ))}
      </tbody>
    );
  };

  const SortableItem: React.FC<SortableItemProps> = ({ item, index, moveItem }: any) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const [, drop] = useDrop({
      accept: ItemType,
      hover(item: DragItem, monitor: any) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Only continue if clientOffset is available
        if (clientOffset) {
          const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

          // Only move when the cursor is above 50% (moving upwards) or below 50% (moving downwards)
          if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
            || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) {
            return;
          }

          // Perform the move
          moveItem(dragIndex + 1, hoverIndex + 1);
          item.index = hoverIndex;
        }
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: ItemType,
      item: () => {
        return { type: ItemType, index };
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <tr ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }} className={item?.is_escalated ? "bg-warning" : ''}>
        <td className="align-middle"><img src="img/draggable-icon.svg" alt="draggable-icon" className="cursor-grab" /></td>
        <td>
          <span className="d-flex flex-column gap-1">
            <span
              data-bs-placement="right"
              title={item?.work_item_name?.length > 10 ? item.work_item_name : " "}
              {...(item?.work_item_name && item.work_item_name.length > 10 ? { 'data-bs-toggle': '' } : {})}
            >
              {item.work_item_name ? formatDescription(item.work_item_name, 10) : "-"}
            </span>
            <span className="font-10 color-sub-grey d-flex gap-1">
              {item.queued_on ? moment(item.queued_on).format("MM/DD/YYYY HH:mm") : "-"}
              {(item.is_escalated && !item.is_assigned) && (<span className="cursor-pointer position-relative tooltip-icon">
                <><img
                  src="img/warning-icon.svg"
                  alt="warning-icon" /><span className="custom-tooltip-upward">
                    <span className="color-white font-10">
                      Escalated Item Back in Queue
                    </span>
                  </span></>
              </span>)}
              {item.is_assigned && (<span className="cursor-pointer position-relative tooltip-icon">
                <><img
                  src="img/assigned-icon.svg"
                  alt="assigned-icon" /><span className="custom-tooltip-upward">
                    <span className="color-white font-10">
                      Assigned</span>
                  </span></>
              </span>)}
            </span>
          </span>
        </td>
        <Timer receivedTime={item.queued_on} normalTime={item.expected_processing_time} moderateTime={item.max_processing_time} isMock={false}
        />

        <td >
          <span className="d-flex flex-column gap-1">
            <span
              data-bs-placement="right"
              title={item?.customer_name?.length > 10  ? item.customer_name : " "}
              {...(item?.customer_name && item.customer_name?.length > 10 ? { 'data-bs-toggle': '' } : {})}
            >
              {item.customer_name ? formatDescription(item.customer_name, 10) : "-"}
            </span>
            <span className="font-10 color-sub-grey">{item.site_name}</span>
          </span>
        </td>
        <td>{item.group_code}</td>
        <td className="text-center align-middle">
          <span className="dropdown d-block">
            <button type="button" className="border-0 dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="img/dots-icon.svg" alt="dots" />
            </button>
            <span className="dropdown-menu dropdown-menu-end work-item-menu px-2">
              <span className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular cust-border-bottom" onClick={() => showOperatorsDropdown(item)}>
                <img src="img/user-icon-blue.svg" alt="user-icon" />Assign
              </span>
              <span className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular" onClick={() => invokeTakeWorkItem(item.work_item_uuid)}>
                <img src="img/take-over-icon.svg" alt="arrows-icon" />Take Work Item</span>
            </span>
            {visibleDropdown === item.work_item_uuid && (
              <ul data-popper-placement="bottom-start" className="dropdown-menu work-item-menu w-215 border-0 px-3 show " style={{
                position: 'absolute',
                inset: '0px auto auto 0px',
                margin: '0px',
                transform: 'translate3d(-166px, 7.6px, 0px)',

              }}>
                <li className="d-flex justify-content-between align-items-center gap-2 py-2 font-13 font-regular list">
                  <span className="d-flex align-items-center gap-1">
                    <img src="img/user-icon-blue.svg" alt="user-icon" />
                    <span className="font-14 font-regular">Assign</span>
                  </span>
                  <img
                    src="img/close-icon-dd.svg"
                    alt="close-icon"
                    className="cursor-pointer"
                    onClick={() => setVisibleDropdown(null)} // Hide the dropdown menu
                  />
                </li>
                <li className="py-1 list">
                  <div className="input-group border bg-white w-100 rounded-2 mb-1">
                    <input
                      type="text"
                      className="form-control font-12 border-0 shadow-none bg-white"
                      placeholder="Search"
                      aria-label="Search"
                      value={item.is_assigned ? item.assigned_operator_name : searchTerm}
                      onChange={(e) => handleSearch(e.target.value)} // Implement setSearchTerm
                    />
                    <span className="input-group-text border-0 bg-transparent">
                      <img src="img/search-icon.svg" alt="Search" />
                    </span>
                  </div>
                </li>
                <li>
                  <ul className="dropdown-y-scroll ps-0 d-flex flex-column gap-1">
                    {/* Render operator list */}
                    {availableOperators[item.work_item_uuid]?.filter((operator: any) =>
                      operator.operator_name.toLowerCase().includes(
                        item.is_assigned ? item.assigned_operator_name?.toLowerCase() : searchTerm.toLowerCase()
                      )
                    )?.map((operator: any) => (
                      <li
                        key={operator.operator_uuid}
                        className={`font-14 font-regular color-black-v2 d-flex align-items-center gap-1 p-1 list ${assignedOperators === operator.operator_uuid ? 'active' : ''}`}
                        onMouseEnter={() => setHoveredOperatorId(operator.operator_uuid)} // Implement setHoveredOperatorId
                        onMouseLeave={() => setHoveredOperatorId(null)} // Implement setHoveredOperatorId
                      >
                        <span className="green-dot" hidden={operator.operator_status !== 'IDLE'}></span>
                        <span className="red-dot" hidden={operator.operator_status === 'IDLE'}></span>
                        {operator.operator_name}
                        {(hoveredOperatorId === operator.operator_uuid && !item.is_assigned) && (
                          <button className="primary-btn sm" onClick={() => handleAssignOperator(item.work_item_uuid, operator.operator_uuid, operator.operator_name)}>Assign</button> // Implement handleAssignOperator
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            )}
          </span>
        </td>
      </tr>
    );

  };

  function recalculatePriorities(items: WorkItems[]): WorkItems[] {
    // Assuming higher priority items should have a lower index
    // We map over the items while assigning the index as the new priority score.
    return items?.map((item, index) => ({
      ...item,
      priority: index,
    }));
  }


  /**PS_03 - PS_21 Function to fetch the queued Work Items  */
  const invokeTakeWorkItem = async (workItemId: string) => {
    try {
      const payload: TakeWorkItemPayLoad = {
        work_item_uuid: workItemId
      };
      const response: any = await takeWorkItem(payload);
      if (response?.status === 200) {
        if (response?.data) {
          // Navigate to workItemScreen and pass response.data as state
          // navigate('/workItemScreen', { state: { workItemData: response.data.data } });
        }
      }
    } catch (error: any) {
      handleShowToast(true, "Error", error?.response?.data?.message);
    }
  };

  /**PS_53 Function for handling toast */
  const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
    setShowToast(showToast);
    setToastType(toastType);
    setToastMessage(toastMessage);
  };

  /**PS_34 Function to handle the loading of more queued Work Items */
  const handleLoadMore = () => {
    setRecordCount(prevCount => prevCount + 10);
    let sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems')
    let workItems = JSON.parse(sessionWorkItems);
    setQueuedWorkItems(workItems?.slice(0, recordCount + 10))
  };



  const moveItem = useCallback(async (dragIndex: number, hoverIndex: number) => {
    // Ensure that we are working with indexes relevant to `displayedItems`
    const dragItem = queuedWorkItems[dragIndex];
    if (!dragItem) return;
    let sessionWorkItems: any = sessionStorage.getItem('queuedWorkItems')
    let workItems = JSON.parse(sessionWorkItems)
    // Update the `items` array based on the movement from `displayedItems`
    let updates = [...workItems];
    // Find the indexes relevant to full `items` list
    const actualDragIndex = workItems.findIndex((item: any) => item.work_item_uuid === dragItem.work_item_uuid);
    const actualHoverIndex = dragIndex < hoverIndex ? workItems.findIndex((_: any, idx: number) => idx === hoverIndex) : hoverIndex;
    if (actualDragIndex < 0 || actualHoverIndex < 0) return;

    // Perform the move operation
    updates.splice(actualDragIndex, 1);
    updates.splice(actualHoverIndex, 0, dragItem);

    // Optional: Recalculate priorities
    updates = recalculatePriorities(updates);
    sessionStorage.setItem('queuedWorkItems', JSON.stringify(updates));
    // Update `displayedItems` state
    setQueuedWorkItems(updates?.slice(0, recordCount));

    // Create the payload for the API call
    const payload: ReArrangeWorkItemPayLoad = {
      work_item_uuid: dragItem.work_item_uuid,
      current_position: dragIndex,
      target_position: hoverIndex
    };

    // Call the backend API with the necessary payload
    try {
      console.log("reordered");
      // const response = await updateItemPriority(payload);
      // if (response.status === 200) {
      //   // //If the update is successful, confirm the changes and update local storage
      //   // // Update `items` state and `localStorage`
      //   // sessionStorage.setItem('queuedWorkItems', JSON.stringify(updates));
      //   // // Update `displayedItems` state
      //   // setQueuedWorkItems(updates?.slice(0, recordCount));
      //   // handleShowToast(true, "Success", "Work Items ReOrdered Successfully");

      // }
    } catch (error: any) {
      // Handle the error case
      console.error('Could not update item priorities:', error);
      handleShowToast(true, "Error", error?.response?.data?.message);
    }
  }, [queuedWorkItems, recordCount]);

  return <>
    {/* Queued Work Items */}
    <h2 className="font-16 font-bold color-black">Queued Work Items  {totalRecordCount > 0 ? (
      <span> ({totalRecordCount}) </span>
    ) : ''}</h2>
    <div className="table-responsive theme-table bg-white w-100 table-y-scroll rows-6 rounded-3 mb-3" id="scrollIDGRC07">
      <InfiniteScroll
        dataLength={recordCount}
        next={handleLoadMore}
        hasMore={recordCount < totalRecordCount}
        scrollThreshold={0.6}
        loader={true}
        scrollableTarget="scrollIDGRC07"
      >
        <table className="table mb-0">
          <colgroup>
            <col span={1} width="1%" />
          </colgroup>
          <thead className='sticky-top table-header-index'>
            <tr className="alter">
              <th />
              <th>
                {queuedWorkItems && (
                  <>
                    {totalRecordCount === 0 ? (
                      <span>Work Item</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('work_item_name')}>
                        Work Item {" "}{renderSortIcon('work_item_name')}
                      </span>
                    )}
                  </>
                )}
              </th>
              <th>
                Queued Time
              </th>
              <th>
                {queuedWorkItems && (
                  <>
                    {totalRecordCount === 0 ? (
                      <span> Customer &amp; Site</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('customer_name')}>
                        Customer &amp; Site {" "}{renderSortIcon('customer_name')}
                      </span>
                    )}
                  </>
                )}
              </th>
              <th>
                {queuedWorkItems && (
                  <>
                    {totalRecordCount === 0 ? (
                      <span>  Lane / CHE</span>
                    ) : (
                      <span className="cursor-pointer" onClick={() => handleSort('group_code')}>
                        Lane / CHE {" "}{renderSortIcon('group_code')}
                      </span>
                    )}
                  </>
                )}
              </th>
              {totalRecordCount > 0 && (<th className="text-center">Action</th>)}
            </tr>
          </thead>
          <DndProvider backend={HTML5Backend}>
            <SortableList items={queuedWorkItems} moveItem={moveItem} />
          </DndProvider>
        </table>
      </InfiniteScroll>
    </div>
    {showToast && <ToastMessage props={
      {
        setIsToast: setShowToast,
        toastMessage: toastMessage,
        toastType: toastType
      }
    } />}
  </>
}


export default QueuedWorkItems