/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react";
import { addOldValueKey, getEquipmentIdByShortcut, getFirstInputFieldValue, getNestedProperty, updateNestedProperty } from "../../utils/utils";
import { ClassificationTypes, EquipmentTypes } from "../../enums/enums";
import { CheckVisitMatchupRequest } from "../../Interfaces/work_item_interface";
import { checkWorkItemService } from "../../services/work_item_service";
import { chassisRegex, containerRegex } from "../../constant";

//PS_01 to PS_43
export const VisitMatchupPanel = ({
  options,
  onChange,
  schema,
  formContext,
}: any) => {
  const { match, setMatch, portalData, defaultPortalData, handleSubmit, navigationProps, handleFocusChange, showContinueWorkingPopup } = formContext;
  const { equipments, equipmentDetails, navigationShortcuts } = options;
  const { submit, deselect } = navigationShortcuts;
  const { completed } = navigationProps
  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [newWorkItemJSON, setNewWorkItemJSON] = useState<any>(portalData.current);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const debounce = useRef<any>()

  useEffect(() => {
    try {
      let disable = false;
      if (!newWorkItemJSON) return
      const { scan_data } = newWorkItemJSON
      switch (scan_data?.classification) {
        case ClassificationTypes.FLATRACK:
        case ClassificationTypes.BARECHASSIS:
          if (scan_data?.equipment?.value?.trim() === "" || !chassisRegex.test(scan_data?.equipment?.value)) {
            disable = true
          }
          break
        case ClassificationTypes.BOBTAIL:
          setDisableSubmitButton(false)
          return
        case ClassificationTypes.CHASSISBUNDLE:
          let nonEmptyChassisCount = scan_data?.equipment
            ?.equipment_attached?.filter((item: any) =>
              item.equipment_type === EquipmentTypes.CHASSIS && item.value && item.value.trim() !== ''
            ).length;
          if (scan_data?.equipment.value.trim() !== "") {
            nonEmptyChassisCount += 1
          }
          disable = nonEmptyChassisCount < 2;
          break
        default:
          if (newWorkItemJSON?.scan_data?.equipment
            ?.equipment_attached) {
            for (let eachEquipment of newWorkItemJSON?.scan_data?.equipment
              ?.equipment_attached) {
              if (eachEquipment?.value.trim() === "") {
                disable = true;
                break;
              }
            }
          }
          break
      }
      if (scan_data?.equipment && scan_data.equipment?.equipment_attached) {
        for (let eachEquipment of newWorkItemJSON?.scan_data?.equipment
          ?.equipment_attached) {
          if (eachEquipment.value.trim() !== "") {
            if (eachEquipment.equipment_type === EquipmentTypes.CONTAINER && !containerRegex.test(eachEquipment.value)) {
              disable = true;
            } else if (eachEquipment.equipment_type === EquipmentTypes.CHASSIS && !chassisRegex.test(eachEquipment.value)) {
              disable = true;
            }
          }
        }
      }
      setDisableSubmitButton(disable);
    } catch (error: any) {
      console.log("an error occured:", error.message)
    }
  }, [newWorkItemJSON, selectedEquipment]);
  useEffect(() => {
    try {
      if (match && completed) {
        let matchedEquipment = equipments?.find(
          (eachEquipment: any) =>
            eachEquipment?.name === portalData.current?.scan_data?.classification
        );
        if (matchedEquipment) {
          setSelectedEquipment(matchedEquipment)
        }
        setNewWorkItemJSON(portalData.current)
      } else {
        setMatch(false)
      }
    } catch (error: any) {
      console.log("an error occured:", error.message)
    }
  }, []);
  useEffect(() => {
    try {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (completed || showContinueWorkingPopup) return;
        const { key } = event;
        const normalizedKey = typeof key === 'string' ? key.toLowerCase() : '';

        // Check if the key press is for equipment selection
        const isEquipmentSelectionKey = equipments.some(
          (equipment: any) => equipment.shortcut.toLowerCase() === normalizedKey
        );

        if (selectedEquipment) {
          switch (normalizedKey) {
            case submit.toLowerCase():
              if (disableSubmitButton === false) {
                event.preventDefault(); // Prevent default behavior
                handleSubmit(newWorkItemJSON, new Date().toISOString());
              }
              break;
            case deselect.toLowerCase():
              event.preventDefault(); // Prevent default behavior
              setSelectedEquipment(null);
              break;
            case "y":
            case "n":
              if (newWorkItemJSON?.scan_data?.classification === ClassificationTypes.BOBTAIL) {
                event.preventDefault(); // Prevent default behavior
                if (normalizedKey === "y") {
                  handleSubmit(newWorkItemJSON, new Date().toISOString());
                } else {
                  setSelectedEquipment(null);
                }
                return;
              }
              break;
          }
        } else if (isEquipmentSelectionKey) {
          event.preventDefault(); // Prevent default behavior
          const selectedId = getEquipmentIdByShortcut(equipments, key);
          if (selectedId) {
            handleItemSelect(selectedId);
          }
        }
      }

      window.addEventListener("keydown", handleKeyPress);
      return () => window.removeEventListener("keydown", handleKeyPress);
    } catch (error: any) {
      console.log("an error occurred:", error.message)
    }
  }, [
    selectedEquipment,
    deselect,
    submit,
    newWorkItemJSON,
    disableSubmitButton,
    equipments,
    completed,
    showContinueWorkingPopup,
  ]);
  useEffect(() => {
    if (selectedEquipment && firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [selectedEquipment]);
  const handleItemSelect = (itemId: string) => {
    try {
      if (completed || showContinueWorkingPopup) return;
      let newSelectedEquipment = equipments?.find(
        (eachEquipment: any) => eachEquipment?.id === itemId
      );
      setSelectedEquipment(newSelectedEquipment);
      const {
        containerNameJsonKey,
        containerEquipmentAttachedJsonKey,
        defaultAttachedEquipment,
        defaultScanData,
        name,
      } = newSelectedEquipment;
      setNewWorkItemJSON((prevWorkItemJson: any) => {
        let originalCopy: any = JSON.parse(JSON.stringify(prevWorkItemJson))
        originalCopy.scan_data = JSON.parse(JSON.stringify(defaultScanData))
        addOldValueKey(originalCopy.scan_data)
        addOldValueKey(defaultAttachedEquipment)
        originalCopy = updateNestedProperty(
          originalCopy,
          containerNameJsonKey,
          name,
          -1
        );
        originalCopy.scan_data.truck.license_plate.value = prevWorkItemJson?.scan_data?.truck?.license_plate?.value || "";
        originalCopy.scan_data.equipment.value = prevWorkItemJson?.scan_data?.equipment?.value || "";
        if (!defaultScanData?.equipment) return originalCopy
        originalCopy = updateNestedProperty(
          originalCopy,
          containerEquipmentAttachedJsonKey,
          defaultAttachedEquipment,
          -1
        );
        if (prevWorkItemJson?.scan_data?.equipment) {
          const equipmentAttachedFromPrev = prevWorkItemJson?.scan_data?.equipment?.equipment_attached || [];

          originalCopy.scan_data.equipment.equipment_attached.forEach((eachEquipmentAttached: any) => {
            const foundEquipment = equipmentAttachedFromPrev.find((eachPreviousAttachedEquipment: any) => {
              return (
                eachEquipmentAttached.sequence === eachPreviousAttachedEquipment.sequence &&
                eachEquipmentAttached.equipment_type === eachPreviousAttachedEquipment.equipment_type
              );
            });
            eachEquipmentAttached.old_value = ""
            // If found, update the current equipment with the previous one
            if (foundEquipment) {
              eachEquipmentAttached.value = foundEquipment.value; // Update only the value property
              eachEquipmentAttached.confidence = foundEquipment.confidence;
            }
          });
        }
        checkVisitMatchup(getFirstInputFieldValue(equipmentDetails[newSelectedEquipment?.id][0], originalCopy), newSelectedEquipment.name)
        return originalCopy;
      });
      portalData.current = JSON.parse(JSON.stringify(defaultPortalData.current))
      setMatch(false)
    } catch (error: any) {
      console.log("an error occured:", error.message)
    }
  }
  const handleChange = (event: any, container: any, index: any) => {
    try {
      if (showContinueWorkingPopup) return
      let { jsonKeys, sequence } = container
      if (sequence === undefined) {
        sequence = -1
      }
      let updatedNewWorkItemJson = updateNestedProperty(
        newWorkItemJSON,
        jsonKeys,
        event.target.value,
        sequence
      );
      if (debounce.current) {
        clearTimeout(debounce.current)
      }
      debounce.current = setTimeout(() => {
        if (index === 0) {
          checkVisitMatchup(event.target.value, selectedEquipment.name)
        }
      }, 1000)
      setNewWorkItemJSON(updatedNewWorkItemJson);
    } catch (error: any) {
      console.log("an error occured: ", error.message)
    }
  }
  const checkVisitMatchup = async (value: string, selectedEquipmentName: string) => {
    try {
      if (value === "") return
      const payload: CheckVisitMatchupRequest = {
        selected_equipment: selectedEquipmentName,
        value: value
      }
      const response = await checkWorkItemService(payload)
      const { status, data: responseData } = response
      if (status !== 200) {
        throw new Error(responseData.message)
      }
      if (responseData?.data.is_portal_passage_found) {
        const parsedPortalDataJSON = JSON.parse(responseData?.data?.portal_data)
        const newWorkItemJSONCopy = JSON.parse(JSON.stringify(newWorkItemJSON))
        addOldValueKey(parsedPortalDataJSON)
        newWorkItemJSONCopy.scan_data = parsedPortalDataJSON.scan_data
        newWorkItemJSONCopy.event_id = parsedPortalDataJSON.event_id
        portalData.current.scan_data = parsedPortalDataJSON.scan_data
        portalData.current.event_id = parsedPortalDataJSON.event_id
        setNewWorkItemJSON(newWorkItemJSONCopy)
        setMatch(true)
        return
      }
      portalData.current = JSON.parse(JSON.stringify(defaultPortalData.current))
      setMatch(false)
    } catch (error: any) {
      console.log("An error occured:", error.Message)
    }
  }
  const bindEquipmentDetails = () => {
    try {
      return equipmentDetails[selectedEquipment.id]?.length > 0 ? (
        <>
          {equipmentDetails[selectedEquipment?.id]?.map(
            (container: any, index: number) => {
              let value: string = "";
              let valid: boolean = true
              let inputClassName: string = "";
              let indicatorClassName: string = ""
              let portalPassageText: string = "";
              let confidenceKey: string[] = [...container?.jsonKeys];
              confidenceKey[confidenceKey.length - 1] = "confidence";
              if (container?.sequence !== undefined) {
                value = getNestedProperty(
                  newWorkItemJSON,
                  container?.jsonKeys,
                  container?.sequence
                );
              } else {
                value = getNestedProperty(
                  newWorkItemJSON,
                  container?.jsonKeys,
                  -1
                );
              }
              indicatorClassName = "";
              inputClassName = "input-grp-border"
              portalPassageText = ""
              if (value !== "") {
                if (match) {
                  inputClassName = "input-success";
                  portalPassageText = "Portal Passage Found!";
                  indicatorClassName = "color-green-v1";
                } else {
                  if (index === 0) {
                    portalPassageText = "Portal Passage No Match!";
                    indicatorClassName = "color-red";
                  }
                }
              }
              if (value !== "") {
                if (container.id === EquipmentTypes.CONTAINER && !containerRegex.test(value)) {
                  valid = false;
                  indicatorClassName = "color-red";
                  portalPassageText = "Enter a valid container number";
                  inputClassName = "input-correction"
                } else if (container.id === EquipmentTypes.CHASSIS && !chassisRegex.test(value)) {
                  valid = false;
                  indicatorClassName = "color-red";
                  portalPassageText = "Enter a valid chassis number";
                  inputClassName = "input-correction"
                }
              }
              if (completed) {
                inputClassName = ""
              }
              return (
                <div className="mb-3" key={index}>
                  <label
                    htmlFor={`container_${index}`}
                    className="m-0 font-semibold font-12 color-black-v1 mb-2"
                  >
                    {container?.label}
                  </label>
                  <div
                    className={`input-group rounded-2 overflow-hidden ${inputClassName}`}
                  >
                    <input
                      id={`container_${index}`}
                      type="text"
                      ref={index === 0 ? firstInputRef : null}
                      className={`form-control font-12 shadow-none ${inputClassName !== "inputCorrection" ? "" : "border-0"}`}
                      placeholder={container.placeholder}
                      aria-label={`Container #${index + 1}`}
                      onFocus={() => handleFocusChange(true)}
                      onBlur={() => handleFocusChange(false)}
                      name={container?.name}
                      value={value}
                      maxLength={255}
                      autoComplete="off"
                      onChange={(event: any) =>
                        handleChange(
                          event,
                          container,
                          index,
                        )
                      }
                      disabled={completed || (index !== 0 && match)}
                    />
                    {index !== 0 && match && !completed && (
                      <span className="input-group-text border-0 bg-transparent">
                        <img src="img/tick-icon-green.svg" alt="cancel" />
                      </span>
                    )}
                  </div>
                  {
                    !completed && indicatorClassName && portalPassageText && (index === 0 || !valid) && (
                      <span className={`font-semibold font-10 ${indicatorClassName}`}>
                        {portalPassageText}
                      </span>
                    )
                  }
                </div >
              );
            }
          )}
          {!completed && (
            <button
              className="mt-4 primary-btn d-flex align-items-center ms-auto justify-content-center gap-2 align-self-end"
              type="button"
              disabled={disableSubmitButton}
              onClick={() => {
                handleSubmit(newWorkItemJSON, new Date().toISOString());
              }}
            >
              <span>Done</span>
              <span className="inner-btn">Enter</span>
            </button>)}
        </>
      ) : (
        <>
          <p className="font-semibold font-15 color-black text-center py-0">
            {completed ? "This equipment is selected as a Bobtail." : "Are you sure this is a Bobtail?"}
          </p>
          {!completed && <div className="mt-4 d-flex align-items-center justify-content-end gap-2 align-self-end">
            <button
              className="mt-auto secondary-btn no-btn d-flex align-items-center justify-content-center gap-2 align-self-end"
              type="button"
              onClick={() => setSelectedEquipment(null)}
            >
              <span>No</span>
            </button>
            <button
              className="mt-auto primary-btn d-flex align-items-center justify-content-center gap-2 align-self-end"
              type="button"
              onClick={() => handleSubmit(newWorkItemJSON, new Date().toISOString())}
            >
              <span>Yes</span>
              <span className="inner-btn">Enter</span>
            </button>
          </div>}
        </>
      );
    } catch (error: any) {
      console.log("an error ocured:", error.message)
    }
  }
  return (
    <div className="p-3 h-100">
      <div className="row min-h-75vh h-100">
        <div className="col-md-6 border-end h-100">
          <div className="d-flex align-items-center gap-2 pb-2">
            <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">
              <img src="img/truck-icon.svg" alt="truck" />
            </span>
            <h2 className="font-bold font-14 color-black m-0">
              {schema.title}
            </h2>
            <span className="cursor-pointer position-relative tooltip-icon">
              <img
                src="img/tooltip-icon.svg"
                alt="sort-arrow-down"
                style={{ marginBottom: "2px" }}
              />
              <span className="custom-tooltip">
                <span className="color-white text-center font-regular">
                  Examine the images below to identify the truck's load type,
                  then select the appropriate option from the list below.
                </span>
              </span>
            </span>
          </div>
          <div className="items-wrapper">
            {options?.equipments?.map((item: any, index: any) => (
              <div
                key={index}
                className={`d-flex align-items-center py-3 px-1 border-bottom cursor-pointer item-select ${item.id === selectedEquipment?.id ? "active" : ""
                  }`}
                onClick={() => handleItemSelect(item.id)}
              >
                <div className="w-25">
                  <img src={item.image} alt={item.name} />
                </div>
                <span className="font-semibold font-12 color-black">
                  {item.name}
                </span>
                <span className="ms-auto keyboard-press font-regular font-10 d-flex align-items-center justify-content-center">
                  {item.shortcut}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6 h-100">
          <div className="field-container min-h-75vh h-100">
            <div className="d-flex align-items-center gap-2 mb-3">
              <span className="inset-icon-wrapper d-flex align-items-center justify-content-center">
                <img src="img/product-details-icon.svg" alt="product details" />
              </span>
              <h2 className="font-bold font-14 color-black m-0">Details</h2>
              <span className="cursor-pointer position-relative tooltip-icon">
                <img
                  src="img/tooltip-icon.svg"
                  alt="sort-arrow-down"
                  style={{ marginBottom: "2px" }}
                />
                <span className="custom-tooltip">
                  <span className="color-white text-center font-regular">
                    Please provide details about the chassis and containers involved in this visit.
                    If details match portal passage, it will be auto-found.
                    If no match, manually input the information.
                  </span>
                </span>
              </span>
            </div>
            {selectedEquipment?.id ? (
              bindEquipmentDetails()
            ) : (
              <div className="vh-50 d-flex align-items-center justify-content-center gap-3 flex-column ">
                <img src="img/list-icon.svg" alt="list" />
                <p className="font-semibold font-12 color-black text-center">
                  Select Equipment Classification to Continue.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
