import Client from "../client/client";
import { auditHistoryService, baseURL } from "../constant";
import { AuditHistoryRequestPayLoad } from "../Interfaces/audit_history_interface";

/**PS_05 - PS_09 - Async Function to get the fetchAuditHistoryDetails */
const fetchAuditHistoryDetails = async (payload: AuditHistoryRequestPayLoad) => {

    try {
        const config = {
            method: "post",
            url: `${baseURL}${auditHistoryService.getAuditHistoryDetails}`,
            data: payload,
        };
        const response = await Client(config);
      
        return response
    } catch (error) {
        console.log("audit History Service fetchAuditHistoryDetails", error);
        
        throw error

    }
}

export { fetchAuditHistoryDetails }