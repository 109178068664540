import Client from "../client/client";
import { baseURL, userActivityService } from "../constant";
import { getAvailableTeamsPayLoad, GetIndividualBreakDetailsPayLoad, UpdateUserActivityStatusPayLoad, UserActivityPayLoad, UserShiftOverridePayLoad, IndividualUserActivityRequest, IndividualUserActivityKPIRequest } from "../Interfaces/user_activity_interface";


const fetchTeamsCreators = async (payload: getAvailableTeamsPayLoad) => {
  try {
    const config = {
      method: "get",
      url: `${baseURL}${userActivityService.getTeamCreatorsInfo}/${payload.application_uuid}`,
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service fetchTeamsCreators", error);
    return error
  }
}

const fetchAvailableTeams = async (payload: getAvailableTeamsPayLoad) => {
  try {
    const config = {
      method: "get",
      url: `${baseURL}${userActivityService.getAvailableTeams}/${payload.application_uuid}`,
    };
    const response = await Client(config);
    return response
  } catch (error) {
    console.log("workItemQueue service fetchAvailableTeams", error);
    return error
  }
}


const fetchUserActivity = async (payload: UserActivityPayLoad) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${userActivityService.getUserActivity}`,
            data: payload,
        };
        const response = await Client(config);
        return response
    } catch (error) {
        console.log("workItemQueue service fetchUserActivity", error);
        return error
    }
}

const updateUserActivityStatus = async (payload: UpdateUserActivityStatusPayLoad) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${userActivityService.setOperatorStatus}`,
            data: payload,
        };
        const response = await Client(config);
        return response
    } catch (error) {
        console.log("workItemQueue service updateUserActivityStatus", error);
        return error
    }
}

const getUserBreakScheule = async (payload: GetIndividualBreakDetailsPayLoad) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${userActivityService.getOperatorBreakSchedule}/${payload.user_uuid}`,

        };
        const response = await Client(config);
        return response
    } catch (error) {
        console.log("workItemQueue service getUserBreakScheule", error);
        return error
    }
}

const overrideUserBreakScheulde = async (payload: UserShiftOverridePayLoad) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${userActivityService.overrideOperatorBreakSchedule}`,
            body: payload
        };
        const response = await Client(config);
        return response
    } catch (error) {
        console.log("workItemQueue service overrideUserBreakScheulde", error);
        return error
    }
}


export const userActivityKPI = async(payload:getAvailableTeamsPayLoad)=>{
    try {
      const config = {
        method: "get",
        url: `${baseURL}${userActivityService.getUserActivityKPI}/${payload.application_uuid}`,
      };
      const response = await Client(config);
      return response
  
    } catch (error) {
      console.log("userActivity data", error);
      throw error
    }
  }

const IndividualUserActivityApi = async (payLoad: IndividualUserActivityRequest) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${userActivityService.getIndividualUserActivity}`,
            data: payLoad
        };
        const response = await Client(config);
        console.log(response, "response")
        return response.data
    } catch (error) {
        console.log("User Activity service IndividualUserActivityApi", error);
        return error
    }
}

const GetIndividualUserActivityKPI = async (payLoad: IndividualUserActivityKPIRequest) => {
    try {
        const config = {
            method: "post",
            url: `${baseURL}${userActivityService.getIndividualUserActivityKPI}`,
            data: payLoad
        };
        const response = await Client(config);
        return response
    } catch (error) {
        console.log("User Activity service GetIndividualUserActivityKPI", error);
        return error
    }
}




export { fetchAvailableTeams, fetchUserActivity, fetchTeamsCreators, updateUserActivityStatus, getUserBreakScheule, overrideUserBreakScheulde, IndividualUserActivityApi, GetIndividualUserActivityKPI }
