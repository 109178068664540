import { useCallback, useEffect, useState } from "react";
import { AuditHistoryFilter, AuditHistoryGridInterface, AuditHistoryRequestPayLoad } from "../../Interfaces/audit_history_interface";
import { SortConfig } from "../../Interfaces/work_items_interface";
import { fetchAuditHistoryDetails } from "../../services/audit_history.service";
import ToastMessage from "../../reusable_components/toast";
import NoRecordsFoundComponent from "../../reusable_components/no_records_found_component";
import PaginationComponent from "../../reusable_components/pagination";
import { formatDescription, MinimumFilterDate } from "../../constant";
import moment from "moment";
import { fetchAllUsers } from "../../services/user_management_service";
import { allUsers } from "../../Interfaces/user_management_interface";
import { FullscreenLoader } from "../loader";


/**PS_01 -PS_02 Importing the necessary functions and services required and declaration of the state variable*/
const AuditHistoryGrid = () => {
    const [auditHistoryInfo, setAuditHistoryInfo] = useState<AuditHistoryGridInterface[]>([]);
    const [actionDropdownUsers, setActionDropdownUsers] = useState<allUsers[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<AuditHistoryFilter>({
        filter_from_date: '',
        filter_to_date: '',
        action_by_uuid: '',
        action_for_uuid: ''
    });
    const [inputSearchTerm, setInputSearchTerm] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'audit_date', direction: 'desc' })
    const [totalCount, setTotalCount] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [auditHistoryFilter, setAuditHistoryFilter] = useState<AuditHistoryFilter>({
        filter_from_date: '',
        filter_to_date: '',
        action_by_uuid: '',
        action_for_uuid: ''
    });

    /**PS_AUH_FE_30-PS_AUH_FE_35  - Callback function to be triggered whenever dependencies change */

    const getAllAuditHistory = useCallback(async () => {
        let adjustedToDate = auditHistoryFilter.filter_to_date;
        if (auditHistoryFilter.filter_from_date && auditHistoryFilter.filter_to_date) {
            const toDate = new Date(auditHistoryFilter.filter_to_date);
            toDate.setDate(toDate.getDate() + 1);
            adjustedToDate = toDate.toISOString().split('T')[0]; 
        }
        const details: AuditHistoryRequestPayLoad = {
            sort_column: sortConfig.key,
            sort_order: sortConfig.direction,
            search: searchTerm.trim(),
            filter_from_date: auditHistoryFilter.filter_from_date,
            filter_to_date: adjustedToDate,
            number_of_records_per_page: limit,
            page_number: page,
            action_by_uuid: auditHistoryFilter.action_by_uuid,
            action_for_uuid: auditHistoryFilter.action_for_uuid
        };
        try {
            setIsLoading(true)
            const response: any = await fetchAuditHistoryDetails(details);
            if (response?.status === 200) {
                setAuditHistoryInfo(response.data.data || []);
                setTotalCount(response.data.meta.total || 0);
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setTotalCount(0);
                setAuditHistoryInfo([]);
                return
            }
            else {
                console.error("Error fetching audit history details", error);
                handleShowToast(true, "Error", error.message);
            }

        }
        finally {
            setIsLoading(false)
        }
    }, [sortConfig, auditHistoryFilter, limit, page, searchTerm]);

    /**PS_AUH_FE_53- 56 - Callback function to be triggered whenever dependencies change */
    const getAllUsers = useCallback(async () => {
        try {
            setIsLoading(true)
            const response: any = await fetchAllUsers();
            if (response?.status === 200) {
                setActionDropdownUsers(response.data.data || []);
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setActionDropdownUsers([]);
            }
            else {
                console.error("Error fetching all users", error);
                handleShowToast(true, "Error", error.message);
            }

        }
        finally {
            setIsLoading(false)
        }
    }, []);

    // New effect for fetching users only on initial load
    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    useEffect(() => {
        getAllAuditHistory();
    }, [getAllAuditHistory]);


    /**PS_AUH_FE_31 - Function to handle the search Term Change */
    const applySearch = (isCancel: boolean = false) => {
        setPage(1);
        setLimit(10);
        setSearchTerm(isCancel ? '' : inputSearchTerm); // Set searchTerm to passiveSearchTerm
    };

    /**PS_AUH_FE_60 - Function to handle the apply button */
    const handleApplyButton = () => {
        setAuditHistoryFilter({ filter_from_date: selectedFilter.filter_from_date, filter_to_date: selectedFilter.filter_to_date, action_by_uuid: selectedFilter.action_by_uuid, action_for_uuid: selectedFilter.action_for_uuid });
        setPage(1);
        setLimit(10);
        setShowFilter(false);
    };

    /**PS_AUH_FE_32 to reset date filters and fetch data */
    const cancelFilter = () => {
        setPage(1);
        setLimit(10);
        setAuditHistoryFilter({ filter_from_date: '', filter_to_date: '', action_by_uuid: '', action_for_uuid: '' });
        setSelectedFilter({ filter_from_date: '', filter_to_date: '', action_by_uuid: '', action_for_uuid: '' });
    };

    /**PS_AUH_61 - Function to handle the handleSort */
    const handleSort = (key: string) => {
        setSortConfig((prevConfig: { key: string; direction: string; }) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    /** PS_AUH_FE_40 - Callback function to handle page and records per page changes **/
    const handlePageAndRecordsChange = (currentPage: number, recordsPerPage: number) => {
        setPage(currentPage);
        setLimit(recordsPerPage);
    };

    /**PS_AUH_22 - Function to handle the renderSortIcon */
    const renderSortIcon = (key: string) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc'
                ? <img src="img/sort-up-arrow.svg" alt="sort-arrow-up" />
                : <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
        }
        return <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
    };

    /**PS_AUH_61 - Function to handle the filter Change */
    const handleFilterChange = (e: any) => {
        setSelectedFilter(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    /**PS_53 Function for handling toast */
    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };

    const getUserName = (user_uuid: string) => {
        const user = actionDropdownUsers?.find(u => u.user_uuid === user_uuid);
        return user ? user.user_name : "Select";
    };


    /**PS_AUH_EX_01 - Function to render the audit history grid*/
    const renderAuditHistory = () => {
        if (totalCount === 0) {
            return <NoRecordsFoundComponent
                colspan={4}
                mainContent="No Data Found"
                subContent="History will be shown if any actions made"
                columnHeight='62'
            />
        }

        return (auditHistoryInfo?.map?.((item: AuditHistoryGridInterface) => {
            return (<> <tr>
                <td>
                    <span
                        className="text-truncate d-inline-block max-w-650px"
                        title={item.action?.length > 20 ? item.action : " "}
                        {...(item.action && item.action.length > 20 ? { 'data-bs-toggle': 'tooltip' } : {})}
                    >
                        {item.action ? formatDescription(item.action, 50) : "-"}
                    </span>
                </td>
                <td>
                    <span
                        data-bs-placement="right"
                        title={item.action_for?.length > 20 ? item.action_for : " "}
                        {...(item.action_for && item.action_for.length > 10 ? { 'data-bs-toggle': 'tooltip' } : {})}
                    >
                        {item.action_for ? formatDescription(item.action_for, 20) : "-"}
                    </span>
                </td>
                <td>
                    <span
                        data-bs-placement="right"
                        title={item.action_by?.length > 20 ? item.action_by : " "}
                        {...(item.action_by && item.action_by.length > 20 ? { 'data-bs-toggle': 'tooltip' } : {})}
                    >
                        {item.action_by ? formatDescription(item.action_by, 20) : "-"}
                    </span>
                </td>
                <td>
                    {item.audit_date ? moment.utc(item.audit_date).format("MM/DD/YYYY HH:mm") : " -"}
                </td>
            </tr>
            </>)
        }))
    }

    return (
        <>
            <div className={`container-fluid ${isLoading ? 'loader-blur' : ''}`}>
                {isLoading ? (
                    <FullscreenLoader />
                ) : (
                    <div className="p-3 py-4">
                        {/* Breadcrumbs */}
                        <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <p>Admin</p>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Audit History
                                </li>
                            </ol>
                        </nav>
                        {/* Breadcrumbs */}
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <h2 className="m-0 font-bold font-18 color-black">Audit History</h2>
                            <div className="d-flex align-items-stretch gap-3">
                                <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                    <input
                                        type="search"
                                        className="form-control border-0 shadow-none font-14"
                                        placeholder="Search"
                                        aria-label="Search"
                                        value={inputSearchTerm}
                                        onChange={(e) => setInputSearchTerm(e.target.value)}
                                        onKeyUp={(event) => event.key === "Enter" ? applySearch() : null}

                                    />
                                    <span className="input-group-text border-0 bg-transparent" onClick={() => {
                                        applySearch()
                                    }}>
                                        <img src="img/search-icon.svg" alt="search" />
                                    </span>
                                </div>
                                <div className="dropdown filter-dpd">
                                    <button
                                        className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3"
                                        type="button"
                                        aria-expanded="true"
                                        data-bs-auto-close="true"
                                        onClick={() => setShowFilter(!showFilter)}
                                    >
                                        <img
                                            src="img/filter-icon-blue.svg"
                                            alt="filter"
                                            className="filter-img"
                                        />
                                        <span>Filter</span>
                                    </button>
                                    {showFilter && (<> <div className="dropdown-menu border-0 custom-shadow dropdown-menu-end filter-dpd p-3 mt-2 show" style={{ position: 'absolute', right: '-5px' }}>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <h3 className="m-0 font-bold font-16 color-black-v1">
                                                Advanced Filter
                                            </h3>
                                            <button className="bg-transparent border-0" type="button" onClick={() => { setShowFilter(false) }}>
                                                <img src="img/close-icon.svg" alt="close" />
                                            </button>
                                        </div>
                                        <div className="row g-3">
                                            <div className="col-6">
                                                <label htmlFor="action_for" className="font-semibold font-12 color-black-v1 form-label">
                                                    Action For
                                                </label>
                                                <span className="filter-drop-down">
                                                    <button
                                                        id="action_for"
                                                        className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="true"
                                                    >
                                                        <span>{selectedFilter.action_for_uuid ? getUserName(selectedFilter.action_for_uuid) : 'Select'}</span>
                                                        <img src="img/down-arrow.svg" alt="down-arrow" />
                                                    </button>

                                                    <span className="dropdown-menu font-regular font-14 p-0">
                                                        <span
                                                            className="px-2 py-1 list d-block font-12"
                                                            data-value=""
                                                            onClick={() => handleFilterChange({ target: { name: "action_for_uuid", value: "" } })}
                                                        >
                                                            Select
                                                        </span>
                                                        {actionDropdownUsers?.map((user, index) => (
                                                            <span
                                                                key={index}
                                                                className="px-2 py-1 list d-block font-12"
                                                                onClick={() => handleFilterChange({ target: { name: "action_for_uuid", value: user.user_uuid } })}
                                                            >
                                                                {user.user_name}
                                                            </span>
                                                        ))}
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="action_for" className="font-semibold font-12 color-black-v1 form-label">
                                                    Action By
                                                </label>
                                                <span className="filter-drop-down">
                                                    <button
                                                        id="action_by"
                                                        className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="true"
                                                    >
                                                        <span>{selectedFilter.action_by_uuid ? getUserName(selectedFilter.action_by_uuid) : 'Select'}</span>
                                                        <img src="img/down-arrow.svg" alt="down-arrow" />
                                                    </button>

                                                    <span className="dropdown-menu font-regular font-14 p-0">
                                                        <span
                                                            className="px-2 py-1 list d-block font-12"
                                                            data-value=""
                                                            onClick={() => handleFilterChange({ target: { name: "action_by_uuid", value: "" } })}
                                                        >
                                                            Select
                                                        </span>
                                                        {actionDropdownUsers?.map((user, index) => (
                                                            <span
                                                                key={index}
                                                                className="px-2 py-1 list d-block font-12"
                                                                onClick={() => handleFilterChange({ target: { name: "action_by_uuid", value: user.user_uuid } })}
                                                            >
                                                                {user.user_name}
                                                            </span>
                                                        ))}
                                                    </span>
                                                </span>
                                            </div>
                                            <h4 className="mb-2 font-semibold font-14 color-black-v1">
                                                Date Range
                                            </h4>
                                            <div className="col-6 mt-0">
                                                <label
                                                    htmlFor="from_date"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    From
                                                </label>
                                                <input
                                                    type="date"
                                                    id="from_Date"
                                                    value={selectedFilter.filter_from_date}
                                                    min={MinimumFilterDate}
                                                    max={selectedFilter.filter_to_date ? selectedFilter.filter_to_date : new Date().toISOString().split('T')[0]}
                                                    className="form-control font-12 theme-input shadow-none"
                                                    onChange={(e) => setSelectedFilter({ ...selectedFilter, filter_from_date: e.target.value })} />
                                            </div>
                                            <div className="col-6 mt-0">
                                                <label
                                                    htmlFor="to_date"
                                                    className="font-semibold font-12 color-black-v1 form-label"
                                                >
                                                    To
                                                </label>
                                                <input
                                                    type="date"
                                                    id="to_Date"
                                                    value={selectedFilter.filter_to_date}
                                                    min={selectedFilter.filter_from_date ? selectedFilter.filter_from_date : MinimumFilterDate}
                                                    max={new Date().toISOString().split('T')[0]}
                                                    className="form-control font-12 theme-input shadow-none"
                                                    onChange={(e) => setSelectedFilter({ ...selectedFilter, filter_to_date: e.target.value })}
                                                />
                                            </div>
                                            <div className="co1-12">
                                                <div className="d-flex justify-content-end gap-3 mt-5">
                                                    <button className="secondary-btn rounded-3" type="button" onClick={() => { cancelFilter() }}>
                                                        <span className="d-inline-block my-1">Clear</span>
                                                    </button>
                                                    <button disabled={!(
                                                        (selectedFilter.filter_from_date && selectedFilter.filter_to_date) || // Both dates present
                                                        (!selectedFilter.filter_from_date && !selectedFilter.filter_to_date && // Both dates absent
                                                            (selectedFilter.action_by_uuid || selectedFilter.action_for_uuid)) // At least one action UUID present
                                                    )} onClick={() => { handleApplyButton() }}
                                                        className="primary-btn rounded-3"
                                                        type="button"
                                                    >
                                                        <span className="d-inline-block my-1">Apply</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div></>)}
                                </div>
                            </div>
                        </div>
                        {/* grid */}
                        <div className="table-responsive bg-white theme-table rounded-3 mb-3 custom-spacing">
                            <table className="table m-0 align-middle">
                                <colgroup>
                                    <col span={1} width="40%" />
                                    <col span={1} width="20%" />
                                    <col span={1} width="20%" />
                                    <col span={1} width="20%" />
                                </colgroup>
                                <thead>
                                    <tr className="alter">
                                        <th>
                                            Action
                                        </th>
                                        <th>
                                            {auditHistoryInfo?.length === 0 ? (
                                                <span>Action For</span>
                                            ) : (
                                                <span className="cursor-pointer" onClick={() => handleSort('action_for')}>
                                                    Action For{" "}{renderSortIcon('action_for')}
                                                </span>
                                            )}
                                        </th>
                                        <th>
                                            {auditHistoryInfo?.length === 0 ? (
                                                <span>Action By</span>
                                            ) : (
                                                <span className="cursor-pointer" onClick={() => handleSort('action_by')}>
                                                    Action By{" "}{renderSortIcon('action_by')}
                                                </span>
                                            )}
                                        </th>
                                        <th>
                                            {auditHistoryInfo?.length === 0 ? (
                                                <span>Date &amp; Time</span>
                                            ) : (
                                                <span className="cursor-pointer" onClick={() => handleSort('audit_date')}>
                                                    Date &amp; Time{" "}{renderSortIcon('audit_date')}
                                                </span>
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderAuditHistory()}
                                </tbody>
                            </table>
                        </div>
                        {(auditHistoryInfo && auditHistoryInfo?.length > 0) && <div className="mt-1"><PaginationComponent
                            totalCount={totalCount}
                            initialRecordsPerPage={limit}
                            setPageAndRecords={(currentPage: number, limit: number) => {
                                handlePageAndRecordsChange(currentPage, limit)
                            }}
                            currentPage={page}
                        /></div>}

                    </div>
                )}
            </div>

            {showToast && <ToastMessage props={
                {
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType
                }
            } />
            }
        </>
    )
};
export default AuditHistoryGrid