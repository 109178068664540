/* eslint-disable jsx-a11y/anchor-is-valid */
const NotFoundComponent = () => {
    return (
        <div className="vh-100 d-flex align-items-center justify-content-center">
            <div className="text-center page-not-found px-3">
                <div className="gif">
                    <img src="img/404 error.gif" alt="404" />
                </div>
                <p className="font-bold font-24 color-dark-blue">Page Not Found</p>
                <p className="font-regular font-16 color-dark-blue">
                    Sorry, the page you’re looking for does not exist or has been moved please
                    go back to the Home page
                </p>               
            </div>
        </div>

    );
};

export default NotFoundComponent;
