/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**PS_UIA_01 - Importing of the necessary services and the functions  */
/**PS_UA_LIVE_24-PS_UA_LIVE_25  Importing of the Packages for the Excel Downloading*/

import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExcelJS from 'exceljs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAvailableTeamsPayLoad, SortConfig, TeamCreatorName, UserActivityKPI, TeamsName, UpdateUserActivityStatusPayLoad, UserActivityPayLoad, UserActivityFilter } from '../../Interfaces/user_activity_interface';
import { encryptKeys, encryptStorage } from '../../constant';
import NoRecordsFoundComponent from '../../reusable_components/noRecordsFoundComponent';
import { fetchUserActivity, fetchAvailableTeams, fetchTeamsCreators, updateUserActivityStatus, getUserBreakScheule, userActivityKPI } from "../../services/user_activity_service";
import { ShiftScroll } from '../../reusable_components/shift_scroll_component';
import ToastMessage from '../../reusable_components/toast';
import { applicationAbbrievation, UserStatus, UserStatusType } from '../../enums/enums';
import EscalatedToLane from '../operations/escalated_to_lane';
import InProgressWorkItems from "../operations/in_progress_work_item"
import QueuedWorkItems from "../operations/queued_work_item"
import { EditScheduleComponent } from './break_shift_override';
import { getStatusService } from '../../services/operator_service';
const FileSaver = require('file-saver');

const UserActivity = () => {

    /**PS_UIA_01 - Declaring of the required state variables */
    const [userActivityData, setUserActivityData] = useState<any[]>([]);
    const [userActivityKPIData, setUserActivityKPIInfo] = useState<UserActivityKPI>({
        logged_in_count: 0,
        idle_count: 0,
        working_count: 0,
        dnd_count: 0,
        offline_count: 0,
        target_percentage: 0
    })
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [teamCreators, setTeamCreators] = useState<TeamCreatorName[]>([]);
    const [teamsName, setTeamsName] = useState<TeamsName[]>([]);
    const [userActivityFilter, setUserActivityFilter] = useState<UserActivityFilter>({
        team_name: '',
        status: '',
        created_by: ''
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ key: '', direction: 'asc' });
    const [isLoading, setIsLoading] = useState(false)
    const [recordCount, setRecordCount] = useState(10)
    const [showFilter, setShowFilter] = useState(false);
    const [showEditSchedule, setShowEditSchedule] = useState(false);
    const [userStatus, setUserStatus] = useState([]);
    const [currentTab, setCurrentTab] = useState("Work Item Queue");
    const navigate = useNavigate();
    // Retrieve stored user details from encrypted storage
    const userDetailsString = encryptStorage.getItem(encryptKeys?.userDetails);
    const userDetails = JSON.parse(userDetailsString);
    const vemAppId = userDetails.appRole?.find((role: any) => role.abbreviation === applicationAbbrievation.VEM)?.app_uuid || "-";
    const [editScheduleUserUUID, setEditScheduleUserUUID] = useState<string>("")
    /**PS_UIA_01 - Need to invoke the user Activity Fetching for intial page load */
    useEffect(() => {
        userActivityInitialLoad();
    }, []);

    useEffect(() => {
        applySearchSort();
    }, [sortConfig])

    /**PS_UA_23 - Function to handle the filter Change */
    const handleFilterChange = (e: any) => {
        setUserActivityFilter(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    /**PS_PS_UA_02 - Getting the details in the initial load */
    const userActivityInitialLoad = async () => {
        setIsLoading(true)
        await getUserActivityData(true);
        await fetchFilterTeamsName();
        await fetchFilterTeamCreatorsName();
        await fetchUserActivityKPI();
        await fetchStatus();
        setIsLoading(false)
    }

    // /**PS_UA_23 - Function to handle the filteredAndSortedData */
    const filteredAndSortedData = useMemo(() => {
        const sessionUserActivity: any = sessionStorage.getItem('userActivityData');
        let SessionData: any = JSON.parse(sessionUserActivity);

        let filteredItems = SessionData?.filter((item: any) =>
            item.display_name?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase()) ||
            (item.current_work_item_name && item.current_work_item_name?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase()))
        );
        if (sortConfig.key !== '') {
            filteredItems?.sort((a: any, b: any) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return filteredItems;
    }, [userActivityData, sortConfig, searchTerm]);

    /**PS_UA_20 Function to load More Data */
    const loadMoreData = () => {
        setRecordCount(prevCount => prevCount + 10);
        setUserActivityData(filteredAndSortedData?.slice(0, recordCount + 10));
    };


    const applySearchSort = () => {
        setUserActivityData(filteredAndSortedData?.slice(0, recordCount));
    };


    // PS_UA_20 - Load Function to get the UserActivityData when filter is set
    const getUserActivityData = async (isCancel = false) => {
        try {
            let isCancelFilter = {
                team_name: '',
                status: '',
                created_by: ''
            }
            const payload: UserActivityPayLoad = { filter: isCancel ? isCancelFilter : userActivityFilter, "application_uuid": vemAppId };
            const invokeUserApi: any = await fetchUserActivity(payload);
            if (invokeUserApi?.status === 200) {
                if (invokeUserApi?.data) {
                    setTotalRecordCount(invokeUserApi.data?.meta?.total || 0);
                    sessionStorage.setItem('userActivityData', JSON.stringify(invokeUserApi.data?.data));
                    setUserActivityData(invokeUserApi.data.data ? invokeUserApi.data?.data?.slice(0, recordCount) : []);
                }
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
    };

    /**PS_UA_21 - Function to invoke the userActivity Status */
    const invokeUpdateUserActivityStatus = async (user_uuid: string) => {
        try {
            const user_status_uuid: any = userStatus?.find(
                (eachStatus: any) => eachStatus.status_name === UserStatus.DND
            );
            const payload: UpdateUserActivityStatusPayLoad = {
                user_uuid: user_uuid,
                user_status_uuid: user_status_uuid?.user_status_uuid,
                category: UserStatus.DND,
                reason: "Set By Supervisor"
            };
            const reponse: any = await updateUserActivityStatus(payload);
            if (reponse?.status === 200) {
                if (reponse?.data) {
                    const sessionUserActivity: any = sessionStorage.getItem('userActivityData');
                    const userActivities = JSON.parse(sessionUserActivity);
                    let updatedData = userActivities?.map((activity: any) => {
                        if (activity.user_id === user_uuid) {
                            return {
                                ...activity,
                                current_status: UserStatusType.DND, // Update status to DND
                            };
                        }
                        return activity;
                    })
                    sessionStorage.setItem('userActivityData', JSON.stringify(updatedData));
                    setUserActivityData(updatedData?.slice(0, recordCount));
                }
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
    };

    /**PS_UA_22 - Function to invoke the handleOpenEditSchedule  */
    const handleOpenEditSchedule = async () => {
        setShowEditSchedule(true);
    };

    /*SQ_EX_22.3 - Helper function to set the formatShiftDetails Term */
    const formatShiftDetails = (input: any): any => {
        return {
            shift_start_time: input.shift_start_time,
            shift_end_time: input.shift_end_time,
            break_ranges: input.break_details?.map((breakDetail: any) => ({
                break_start_time: breakDetail.break_start_time,
                duration_minutes: breakDetail.break_duration?.toString()
            }))
        };
    };

    /**PS_03 - PS_21 Function to fetch the fetchFilterTeamsName  */
    const fetchFilterTeamsName = async () => {
        try {
            const payload: getAvailableTeamsPayLoad = {
                application_uuid: vemAppId
            };
            const response: any = await fetchAvailableTeams(payload);
            if (response?.status === 200) {
                if (response?.data) {
                    setTeamsName(response.data?.data || [])
                }
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
    };

    /**PS_03 - PS_21 Function to fetch the fetchFilterTeamCreatorsName  */
    const fetchFilterTeamCreatorsName = async () => {
        try {
            const payload: getAvailableTeamsPayLoad = {
                application_uuid: vemAppId
            };
            const response: any = await fetchTeamsCreators(payload);
            if (response?.status === 200) {
                if (response?.data) {
                    setTeamCreators(response.data?.data || []);
                }
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
    };

    /**PS_03 - PS_21 Function to fetch the fetchFilterTeamCreatorsName  */
    const fetchStatus = async () => {
        try {

            const response: any = await getStatusService();
            if (response?.status === 200) {
                if (response?.data) {
                    setUserStatus(response.data?.data || []);
                }
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
    };


    /**PS_03 - PS_21 Function to fetch the fetchFilterTeamCreatorsName  */
    const fetchUserActivityKPI = async () => {
        try {
            const payload: getAvailableTeamsPayLoad = {
                application_uuid: vemAppId
            };
            const response: any = await userActivityKPI(payload);
            if (response?.status === 200) {
                if (response?.data) {
                    setUserActivityKPIInfo(response.data?.data || []);
                }
            }
        } catch (error: any) {
            handleShowToast(true, "Error", error.message);
        }
    };

    /**PS_UA_22 - Helper Function to get the working during break tooltip */
    function getWorkingDuringBreakTooltip(activity: any): string | null {
        if (activity.working_during_break) {
            const currentTime = new Date();
            const currentUTCHours = currentTime.getUTCHours();
            const currentUTCMinutes = currentTime.getUTCMinutes();

            const currentBreak = activity.break_details?.find((breakDetail: any) => {
                const [breakHours, breakMinutes] = breakDetail.break_start_time?.split(':').map(Number);
                const breakEndHours = (breakHours + Math?.floor((breakMinutes + breakDetail.break_duration) / 60)) % 24;
                const breakEndMinutes = (breakMinutes + breakDetail.break_duration) % 60;

                const isAfterStart = (currentUTCHours > breakHours) || (currentUTCHours === breakHours && currentUTCMinutes >= breakMinutes);
                const isBeforeEnd = (currentUTCHours < breakEndHours) || (currentUTCHours === breakEndHours && currentUTCMinutes < breakEndMinutes);

                return isAfterStart && isBeforeEnd;
            });

            if (currentBreak) {
                const [startHours, startMinutes] = currentBreak.break_start_time.split(':').map(Number);
                const endHours = (startHours + Math?.floor((startMinutes + currentBreak.break_duration) / 60)) % 24;
                const endMinutes = (startMinutes + currentBreak.break_duration) % 60;

                const formatTime = (hours: number, minutes: number) =>
                    `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                const breakStartFormatted = formatTime(startHours, startMinutes);
                const breakEndFormatted = formatTime(endHours, endMinutes);

                return `${activity.display_name} is working during the ${breakStartFormatted} - ${breakEndFormatted} break period.`;
            }
        }
        return null;
    }

    /**PS_53 Function for handling toast */
    const handleShowToast = (showToast: boolean, toastType: string, toastMessage: string) => {
        setShowToast(showToast);
        setToastType(toastType);
        setToastMessage(toastMessage);
    };


    /**PS_UA_22 - Function to handle the handleSearch */
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    /**PS_UA_22 - Function to handle the renderSortIcon */
    const renderSortIcon = (key: string) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc'
                ? <img src="img/sort-up-arrow.svg" alt="sort-arrow-up" />
                : <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
        }
        return <img src="img/sort-down-arrow.svg" alt="sort-arrow-down" />;
    };

    /**PS_UA_22 - Function to invoke the handleCloseEditSchedule  */
    const handleCloseEditSchedule = () => {
        // Here you may also want to perform any additional clean-up if necessary
        setShowEditSchedule(false);

    };

    const handleSuccessClose = () => {
        setShowEditSchedule(false);
        handleShowToast(true, "Success", "Schedule Updated Successfully");
        getUserActivityData(Object.values(userActivityFilter).some((data) => data !== "") ? false : true)
    }

    /**PS_UA_22 - Function to handle the handleSort */
    const handleSort = (key: string) => {
        setSortConfig((prevConfig: { key: string; direction: string; }) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };


    // Helper function to get user name from user_uuid
    const getUserName = (user_uuid: string) => {
        const creator = teamCreators?.find(c => c.user_uuid === user_uuid);
        return creator ? creator.user_name : "Select";
    };

    // Helper function to get team name from team_uuid
    const getTeamName = (team_uuid: string) => {
        const team = teamsName?.find(t => t.team_uuid === team_uuid);
        return team ? team.team_name : "Select";
    };

    // Helper function to format status name
    const formatStatusName = (statusName: string) => {
        if (statusName === UserStatus.DND) {
            return UserStatus.DND;
        }
        return statusName.charAt(0).toUpperCase() + statusName?.slice(1).toLowerCase();
    };

    /**Binding Function  */
    const userActivityBinding = () => {
        if (userActivityData?.length === 0) {
            return <NoRecordsFoundComponent
                colspan={8}
                mainContent="No Users Found"
                subContent="Users will be listed once onboarded"
                columnHeight='40'
            />
        }
        return (userActivityData?.map?.((activity: any, index: number) => {
            const workingDuringBreakTooltip = getWorkingDuringBreakTooltip(activity);
            return (<>
                <tr className={activity?.working_during_break ? "bg-blue" : ''} key={activity.user_id} >
                    <td className="align-middle">
                        <a href="#" >{activity?.display_name}{" "}</a>
                        {workingDuringBreakTooltip && (
                            <span className="cursor-pointer position-relative tooltip-icon">
                                <img src="img/clock-icon-orange.svg" alt="clock-icon" />
                                <span className="custom-tooltip">
                                    <span className="color-white font-10">
                                        {workingDuringBreakTooltip}
                                    </span>
                                </span>
                            </span>
                        )}
                    </td>
                    <td className="align-middle text-center">
                        {(activity?.current_status === UserStatusType.IDLE || activity?.current_status === UserStatusType.ACTIVE) && <span className="operator-status idle">Idle</span>}
                        {activity?.current_status === UserStatusType.OFFLINE && <span className="operator-status offline">Offline</span>}
                        {activity?.current_status === UserStatusType.WORKING && <span className="operator-status working">Working</span>}
                        {activity?.current_status === UserStatusType.DND && <span className="operator-status dnd">DND</span>}
                        {activity?.current_status === UserStatusType.BREAK && <span className="operator-status break-time">Break</span>}
                    </td>
                    <td className="align-middle">{activity?.current_work_item_name !== "" ? activity?.current_work_item_name : "-"}</td>
                    <td className="align-middle text-end">{activity?.completed_work_items_count ? activity?.completed_work_items_count : 0}</td>
                    <td className="align-middle text-end">{activity?.target_completion_percentage ? activity?.target_completion_percentage : 0}%</td>
                    <td className="align-middle">
                        <div>
                            <h3 className="font-8 font-semibold mb-1">
                                {activity?.shift_name}
                            </h3>
                            <div className="d-flex align-items-start">
                                <ShiftScroll shiftData={formatShiftDetails(activity)} />
                            </div>
                        </div>
                    </td>
                    <td className="align-middle text-end">{activity?.total_dnd_time_minutes || 0} {activity?.total_dnd_time_minutes === 1 || activity?.total_dnd_time_minutes === 0 ? 'min' : 'mins'}</td>
                    {userActivityData.length > 0 && (
                        <><td className="text-center align-middle">
                            <span className="dropdown d-block">
                                <button
                                    type="button"
                                    className="border-0 dropdown-btn"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img src="img/dots-icon.svg" alt="dots" />
                                </button>
                                <span className="dropdown-menu work-item-menu px-2">
                                    <button disabled={activity.current_status === UserStatus.DND || activity.current_status === UserStatus.WORKING || activity.current_status === UserStatus.OFFLINE} className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular cust-border-bottom" onClick={() => { invokeUpdateUserActivityStatus(activity.user_id) }}>
                                        <img src="img/dnd-icon-red.svg" alt="minus-icon" />
                                        DND
                                    </button>
                                    <button className="dropdown-item d-flex align-items-center gap-2 py-2 px-3 font-13 font-regular" onClick={() => {
                                        setEditScheduleUserUUID(activity.user_id);
                                        handleOpenEditSchedule()
                                    }}>
                                        <img
                                            src="img/clock-icon-blue.svg"
                                            alt="clock-icon"
                                        />
                                        Edit Schedule
                                    </button>
                                </span>
                            </span>
                        </td></>
                    )}

                </tr>
            </>)
        })
        )
    }
    /**PS_UA_LIVE_26 - Function to export Individual user activity data */
    const invokeExportUserData = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('User Activity Report');
            // Define headers and make them bold
            worksheet.columns = [
                { header: 'User Name', key: 'displayName', width: 20 },
                { header: 'Status', key: 'currentStatus', width: 20 },
                { header: 'Current Work Item', key: 'currentWorkItemName', width: 30 },
                { header: '# Work Items Completed', key: 'completedWorkItemsCount', width: 20 },
                { header: 'Target Percentage', key: 'targetCompletionPercentage', width: 20 },
                { header: 'Shift Start Time', key: 'shiftStartTime', width: 20 },
                { header: 'Shift End Time', key: 'shiftEndTime', width: 20 },
                { header: 'DND Time (min)', key: 'totalDndTimeMinutes', width: 20 },
            ];

            // Make headers bold
            worksheet.getRow(1).font = { bold: true };
            // Iterate over each user data record
            for (const activity of userActivityData) {
                // Format shift details
                const shiftDetails = formatShiftDetails(
                    activity);
                worksheet.addRow({
                    displayName: activity.display_name || "-",
                    currentStatus: activity.current_status
                        ? (activity.current_status === UserStatus.DND
                            ? UserStatus.DND
                            : activity.current_status?.charAt(0)?.toUpperCase() + activity.current_status?.slice(1)?.toLowerCase())
                        : "-", currentWorkItemName: activity.current_work_item_name || "-",
                    completedWorkItemsCount: activity.completed_work_items_count || 0,
                    targetCompletionPercentage: activity.target_completion_percentage || 0,
                    shiftStartTime: shiftDetails.shift_start_time || "-",
                    shiftEndTime: shiftDetails.shift_end_time || "-",
                    totalDndTimeMinutes: activity.total_dnd_time_minutes || 0,
                });
            }

            
            // Generate Excel file
            const excelBuffer = await workbook.xlsx.writeBuffer();
            const data = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            });

            // Save Excel file
            FileSaver.saveAs(data, 'User Activity Details.xlsx');
            handleShowToast(true, "Success", "User Details exported successfully");

        } catch (error) {
            console.error('Error exporting to Excel:', error);
            handleShowToast(false, "Error", "Failed to export User Details");
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="p-3">
                    {/* Breadcrumbs */}
                    <nav aria-label="breadcrumb" className="theme-breadcrumbs">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="">Operations</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {currentTab}
                            </li>
                        </ol>
                    </nav>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <ul className="nav nav-pills theme-tab" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="pills-workItemFeed-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-workItemFeed"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-workItemFeed"
                                    aria-selected="true"
                                    onClick={() => { setCurrentTab("Work Item Queue") }}
                                >
                                    Work Item Queue
                                </button>
                            </li>
                            <li className="nav-item active" role="presentation">
                                <button
                                    className="nav-link"
                                    id="pills-userActivity-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-userActivity"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-userActivity"
                                    aria-selected="true"
                                    onClick={() => { setCurrentTab("User Activity") }}
                                >
                                    User Activity
                                </button>
                            </li>
                        </ul>

                        {currentTab === "User Activity" && <div className="d-flex align-items-center gap-3">
                            <div className="input-group custom-input search-grp rounded-2 overflow-hidden">
                                <input
                                    type="search"
                                    className="form-control border-0 shadow-none font-14"
                                    placeholder="Search"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    onKeyUp={(event) => event.key === "Enter" ? applySearchSort() : null}
                                />

                                <span className="input-group-text border-0 bg-transparent"
                                    onClick={() => applySearchSort()}>
                                    <img src="img/search-icon.svg" alt="search" />
                                </span>
                            </div>
                            <div className="dropdown filter-dpd">
                                <button
                                    className="shadow-btn rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-tinted-grey lh-1 px-3"
                                    type="button"
                                    data-bs-auto-close="false"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => setShowFilter(!showFilter)}
                                >
                                    <img
                                        src="img/filter-icon-blue.svg"
                                        alt="filter"
                                        className="filter-img"
                                    />
                                    <span>Filter</span>
                                </button>
                                <div className={`dropdown-menu border-0 custom-shadow dropdown-menu-end filter-dpd p-3 mt-2 ${showFilter ? 'show' : ''}`}>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h3 className="m-0 font-bold font-16 color-black-v1">
                                            Advanced Filter
                                        </h3>
                                        <button className="bg-transparent border-0" type="button" onClick={() => { setShowFilter(false) }}>
                                            <img src="img/close-icon.svg" alt="close" />
                                        </button>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <label
                                                htmlFor="status"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                Status
                                            </label>                                                                                <span className="filter-drop-down">
                                                <button
                                                    className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <span>{userActivityFilter.status ? formatStatusName(userActivityFilter.status) : "Select"}</span>
                                                    <img src="img/down-arrow.svg" alt="down-arrow" />
                                                </button>

                                                <span className="dropdown-menu font-regular font-14 p-0">
                                                    <span
                                                        className="px-2 py-1 list d-block font-12"
                                                        onClick={() => handleFilterChange({ target: { name: "status", value: "" } })}
                                                    >
                                                        Select
                                                    </span>
                                                    {userStatus?.sort((a: any, b: any) => a.status_name.localeCompare(b.status_name))
                                                        .map((status: any, index) => (
                                                            <span
                                                                key={index}
                                                                className="px-2 py-1 list d-block font-12"
                                                                onClick={() => handleFilterChange({ target: { name: "status", value: status.status_name } })}
                                                            >
                                                                {formatStatusName(status.status_name)}
                                                            </span>
                                                        ))
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <label
                                                htmlFor="created_by"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                Team Created By
                                            </label>
                                            <span className="filter-drop-down">
                                                <button
                                                    className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <span>{userActivityFilter.created_by ? getUserName(userActivityFilter.created_by) : "Select"}</span>
                                                    <img src="img/down-arrow.svg" alt="down-arrow" />
                                                </button>

                                                <span className="dropdown-menu font-regular font-14 p-0">
                                                    <span
                                                        className="px-2 py-1 list d-block font-12"
                                                        onClick={() => handleFilterChange({ target: { name: "created_by", value: "" } })}
                                                    >
                                                        Select
                                                    </span>
                                                    {teamCreators?.map((creator, index) => (
                                                        <span
                                                            key={index}
                                                            className="px-2 py-1 list d-block font-12"
                                                            onClick={() => handleFilterChange({ target: { name: "created_by", value: creator.user_uuid } })}
                                                        >
                                                            {creator.user_name}
                                                        </span>
                                                    ))}
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <label
                                                htmlFor="team_name"
                                                className="font-semibold font-12 color-black-v1 form-label"
                                            >
                                                Team Name
                                            </label>
                                            <span className="filter-drop-down">
                                                <button
                                                    className="d-flex justify-content-between align-items-center cust-dropdown-btn font-12"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <span>{userActivityFilter.team_name ? getTeamName(userActivityFilter.team_name) : "Select"}</span>
                                                    <img src="img/down-arrow.svg" alt="down-arrow" />
                                                </button>

                                                <span className="dropdown-menu font-regular font-14 p-0">
                                                    <span
                                                        className="px-2 py-1 list d-block font-12"
                                                        onClick={() => handleFilterChange({ target: { name: "team_name", value: "" } })}
                                                    >
                                                        Select
                                                    </span>
                                                    {teamsName?.map((team, index) => (
                                                        <span
                                                            key={index}
                                                            className="px-2 py-1 list d-block font-12"
                                                            onClick={() => handleFilterChange({ target: { name: "team_name", value: team.team_uuid } })}
                                                        >
                                                            {team.team_name}
                                                        </span>
                                                    ))}
                                                </span>
                                            </span>
                                        </div>
                                        <div className="co1-12">
                                            <div className="d-flex justify-content-end gap-3 mt-5">
                                                <button className="secondary-btn rounded-3" type="button" onClick={() => {
                                                    getUserActivityData(true); setUserActivityFilter({
                                                        team_name: '',
                                                        status: '',
                                                        created_by: ''
                                                    })
                                                }}>
                                                    <span className="d-inline-block my-1">Cancel</span>
                                                </button>
                                                <button disabled={!Object.values(userActivityFilter).some((data) => data !== "")}
                                                    onClick={() => {
                                                        getUserActivityData(false)
                                                        setShowFilter(false)
                                                    }}
                                                    className="primary-btn rounded-3"
                                                    type="button"
                                                > <span className="d-inline-block my-1">Apply</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="shadow-btn export rounded-2 d-flex align-items-center justify-content-center gap-2 font-semibold font-14 color-dark-green lh-1 px-3"
                                type="button" onClick={() => { invokeExportUserData() }}
                            >
                                <img src="img/excel-icon.svg" alt="export" />
                                <span>Export</span>
                            </button>
                        </div>}

                    </div>



                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-workItemFeed" role="tabpanel" aria-labelledby="pills-workItemFeed-tab" tabIndex={0}>
                            <div className="row mt-3">

                                <InProgressWorkItems />
                                <div className="col-6">
                                    <QueuedWorkItems />
                                    <EscalatedToLane />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-content" id="pills-tabContent-2">
                        <div
                            className="tab-pane"
                            id="pills-userActivity"
                            role="tabpanel"
                            aria-labelledby="pills-userActivity-tab"
                            tabIndex={0}
                        >
                            <div className="row mb-3">
                                <div className="col-2">
                                    <div className="op-status-card">
                                        <span className="d-flex align-items-center gap-2">
                                            <img src="img/login-icon.svg" alt="login-icon" />
                                            <span className="font-12 font-semibold color-grey-v2">
                                                Logged In
                                            </span>
                                        </span>
                                        <span className="font-18 font-bold color-black-v1">{userActivityKPIData?.logged_in_count || 0}</span>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="op-status-card">
                                        <span className="d-flex align-items-center gap-2" >
                                            <img src="img/idle-icon.svg" alt="tick-icon" />
                                            <span className="font-12 font-semibold color-grey-v2">Idle</span>
                                        </span>
                                        <span className="font-18 font-bold color-black-v1">{userActivityKPIData?.idle_count || 0}</span>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="op-status-card">
                                        <span className="d-flex align-items-center gap-2">
                                            <img src="img/working-icon.svg" alt="man-working-icon" />
                                            <span className="font-12 font-semibold color-grey-v2">Working</span>
                                        </span>
                                        <span className="font-18 font-bold color-black-v1">{userActivityKPIData?.working_count || 0}</span>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="op-status-card">
                                        <span className="d-flex align-items-center gap-2">
                                            <img src="img/dnd-icon.svg" alt="minus-icon" />
                                            <span className="font-12 font-semibold color-grey-v2">DND</span>
                                        </span>
                                        <span className="font-18 font-bold color-black-v1">{userActivityKPIData?.dnd_count || 0}</span>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="op-status-card">
                                        <span className="d-flex align-items-center gap-2">
                                            <img src="img/offline-icon.svg" alt="close-icon" />
                                            <span className="font-12 font-semibold color-grey-v2">Offline</span>
                                        </span>
                                        <span className="font-18 font-bold color-black-v1">{userActivityKPIData?.offline_count || 0}</span>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="op-status-card position-relative">
                                        <span className="font-8 font-semibold time-report">Last 12 hrs</span>
                                        <span className="d-flex align-items-center gap-2">
                                            <img src="img/target-icon.svg" alt="target-icon" />
                                            <span className="font-12 font-semibold color-grey-v2">
                                                Target Percentage
                                            </span>
                                        </span>
                                        <span className="font-18 font-bold color-black-v1">
                                            {(userActivityKPIData?.target_percentage || 0).toFixed(2)}%
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-12">
                                <div className="table-responsive theme-table bg-white w-100 table-y-scroll custom rounded-3" id="userActivityTableContainer">
                                    <InfiniteScroll
                                        dataLength={userActivityData?.length}
                                        next={loadMoreData}
                                        hasMore={userActivityData?.length < totalRecordCount}
                                        scrollableTarget="userActivityTableContainer">
                                        <table className="table mb-0">
                                            <thead className="sticky-top">
                                                <tr className="alter cursor-pointer">
                                                    <>
                                                        <th>
                                                            {userActivityData?.length === 0 ? (
                                                                <span>User Name</span>
                                                            ) : (
                                                                <span className="cursor-pointer" onClick={() => handleSort('display_name')}>
                                                                    User Name {" "}{renderSortIcon('display_name')}
                                                                </span>
                                                            )}
                                                        </th>
                                                    </>
                                                    <>
                                                        <th className="text-center">
                                                            {userActivityData?.length === 0 ? (
                                                                <span>Status</span>
                                                            ) : (
                                                                <span className="cursor-pointer" onClick={() => handleSort('current_status')}>
                                                                    Status {" "}{renderSortIcon('current_status')}
                                                                </span>
                                                            )}
                                                        </th>
                                                    </>

                                                    <>
                                                        <th>
                                                            {userActivityData?.length === 0 ? (
                                                                <span>Current Work Item</span>
                                                            ) : (
                                                                <span className="cursor-pointer" onClick={() => handleSort('current_work_item_name')}>
                                                                    Current Work Item {" "}{renderSortIcon('current_work_item_name')}
                                                                </span>
                                                            )}
                                                        </th>
                                                    </>
                                                    <>
                                                        <th className="text-end">
                                                            {userActivityData?.length === 0 ? (
                                                                <span># Work Items Completed</span>
                                                            ) : (
                                                                <span className="cursor-pointer" onClick={() => handleSort('completed_work_items_count')}>
                                                                    # Work Items Completed{" "}{renderSortIcon('completed_work_items_count')}
                                                                </span>
                                                            )}
                                                        </th>
                                                    </>
                                                    <>
                                                        <th className="text-end">
                                                            {userActivityData?.length === 0 ? (
                                                                <span>Target Percentage</span>
                                                            ) : (
                                                                <span className="cursor-pointer" onClick={() => handleSort('target_completion_percentage')}>
                                                                    Target Percentage{" "}{renderSortIcon('target_completion_percentage')}
                                                                </span>
                                                            )}
                                                        </th>
                                                    </>
                                                    <th>
                                                        Shift &amp; Break Time{" "}
                                                        <span className="cursor-pointer position-relative tooltip-icon">
                                                            <img src="img/tooltip-icon.svg" alt="tooltip" />
                                                            <span className="custom-tooltip">
                                                                <span className="font-8 font-semibold color-white d-flex align-items-center gap-1">
                                                                    <span className="legend-blue" />
                                                                    Shift Time{" "}
                                                                </span>
                                                                <span className="font-8 font-semibold color-white d-flex align-items-center gap-1">
                                                                    <span className="legend-orange" />
                                                                    Break Time
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </th>
                                                    <>
                                                        <th className="text-end">
                                                            {userActivityData?.length === 0 ? (
                                                                <span>DND Time</span>
                                                            ) : (
                                                                <span className="cursor-pointer" onClick={() => handleSort('total_dnd_time_minutes')}>
                                                                    DND Time{" "}{renderSortIcon('total_dnd_time_minutes')}
                                                                </span>
                                                            )}
                                                        </th>
                                                    </>
                                                    {userActivityData?.length > 0 && (
                                                        <>
                                                            <th className="text-center">Action</th>

                                                        </>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userActivityBinding()}
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                </div>
                                <div className="mt-3">
                                    <span className="font-14 font-regular color-grey-v4">
                                        # of Records: <span className="font-semibold color-black ">{totalRecordCount}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            {/* Conditional rendering of EditScheduleComponent */}
            {showEditSchedule && (
                <EditScheduleComponent props={editScheduleUserUUID}
                    handleClose={handleCloseEditSchedule} // Passing handleClose to the popup component
                    handleSuccessClose={handleSuccessClose}
                />
            )}


            {showToast && <ToastMessage props={
                {
                    setIsToast: setShowToast,
                    toastMessage: toastMessage,
                    toastType: toastType
                }
            } />}

        </>)
}

export default UserActivity