export enum UserRoles {
    supervisor = "SUPERVISOR",
    Operator = "OPERATOR",
    admin = "ADMIN",
    supervisors = "Supervisor",
    admins = "Admin",
    Operators = "Operator",
}

export enum WorkItemStatus {
    Completed = "COMPLETED",
    EscalatedToLane = "ESCALATED_TO_LANE",
    InProgress = "IN_PROGRESS",
    Queued = "QUEUED",
    EscalatedToCustomer = "ESCALATE_TO_CUSTOMER",
    Requeued = "RE_QUEUED"
}

export enum UserStatusType {
    DND = "DND",
    OFFLINE = "OFFLINE",
    BREAK = "BREAK",
    WORKING = "WORKING",
    IDLE = "IDLE",
    ACTIVE = "ACTIVE"
}


export enum applicationAbbrievation {
    VEM = "VEM"
}


export enum UserStatus {
    IDLE = 'IDLE',
    BREAK = 'BREAK',
    DND = 'DND',
    OFFLINE = 'OFFLINE',
    WORKING = 'WORKING',
    ACTIVE = 'ACTIVE'
}

export enum WebsocketCodes {
    NEWWORKITEM = 'NEW_WORKITEM',
    REVOKEWORKITEM = 'REVOKE_WORKITEM',
    STATUSUPDATE = 'STATUS_UPDATE',
    OVERTAKE = 'OVERTAKE',
    LANEUPDATE = 'LANE_UPDATE'
}
