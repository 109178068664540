import { useState } from 'react';

export const EscalateEquipmentCorrection = (props: any) => {
    const [reason, setReason] = useState("");

    const onChangeReason = (e: any) => {
        setReason(e.target.value);
    };

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSend = () => {
        props.onConfirm(reason);
    };


    return (
        <>
            <div
                className="modal show custom-popup"
                id="escalatePopup"
                tabIndex={-1}
                aria-labelledby="escalatePopup"
                aria-hidden="true"
                style={{ display: 'block', paddingLeft: '0px' }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title font-16 font-bold color-black">
                                Escalate - Equipment Correction
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleCancel()}
                            />
                        </div>
                        <div className="modal-body custom-popup-form">
                            <div className="row justify-content-between">
                                <div className="col-md-12">
                                    <div className="operator-details d-flex justify-content-between mb-4">
                                        <span className="font-13 font-regular color-pale-black px-2">
                                            Customer:{" "}
                                            <span className="font-semibold color-black">{props?.customerName}</span>
                                        </span>
                                        <span className="border-right" />
                                        <span className="font-13 font-regular color-pale-black px-2">
                                            Site:{" "}
                                            <span className="font-semibold color-black">
                                                {props?.siteName}
                                            </span>
                                        </span>
                                        <span className="border-right" />
                                        <span className="font-13 font-regular color-pale-black px-2">
                                            Lane/CHE:{" "}
                                            <span className="font-semibold color-black"> {props?.groupCode}</span>
                                        </span>
                                    </div>
                                    <label
                                        htmlFor="reason"
                                        className="m-0 font-semibold font-13 color-black-v1 mb-2"
                                    >
                                        Reason for Escalation
                                        <span className="color-red">*</span>
                                    </label>
                                    <textarea
                                        name="reason"
                                        id="reason"
                                        rows={4}
                                        className="form-control theme-input textarea-custom shadow-none"
                                        placeholder="Enter reason"
                                        value={reason}
                                        onChange={(e: any) => onChangeReason(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-end gap-3 mt-2 mb-3">
                                    <button
                                        className="secondary-btn rounded-3"
                                        type="button"
                                        data-bs-dismiss="modal"
                                        onClick={() => handleCancel()}
                                    >
                                        <span className="d-inline-block my-1">Cancel</span>
                                    </button>
                                    <button
                                        className="primary-btn rounded-3"
                                        type="button"
                                        onClick={() => handleSend()}
                                    >
                                        <span className="d-inline-block my-1">Send</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show">
            </div>
        </>
    );
}