import { EncryptStorage } from "encrypt-storage";
import { endpoints } from "./Interfaces/login_interface";
import { breakEndpoints } from "./Interfaces/break_templates_interface";
import { EquipmentCorrectionEndpoints } from "./Interfaces/work_item_interface";

export const baseURL: string | undefined = process.env.REACT_APP_BE_URL;

export const authentication: endpoints = {
  generateAuthToken: "/auth/token",
  generateAuthTokenByRefreshToken: "/auth/token/refresh",
};

export const router = {
  applications: "/applications",
};

export const encryptKeys = {
  profile: "yar64876-dhghd6-854687-hjbdfjidhu",
  userDetails: "yar85-8jhgfj-76843-79hfhj-hhipathi",
  role: "enna-y8648h-jguyfu-t75yhe-rvela",
};

const secret: any = process.env.REACT_APP_ENCRYPT_SECRET;

export const encryptStorage = new EncryptStorage(secret ? secret : "", {
  stateManagementUse: true,
  storageType: "sessionStorage",
});

export const operatorConsoleService = {
  getQueuedWorkItems: "/vem/work-item/get-work-items",
  deleteWorkItem: "/vem/work-item/escalate-to-lane",
  escalateToCustomer: "/vem/work-item/escalate-to-customer",
  takeWorkItem: "/vem/work-item/take",
  takeOverWorkItem: "/vem/work-item/takeover",
  assignQueuedWorkItem: "/vem/work-item/assign-work-item",
  getAvailableOperators: "/vem/work-item/get-active-operators",
  getLiveWorkItemsNotification: "/vem-notification/subscribe?topics=WorkItemQueue",
  reArrangeWorkItem: "/vem-work-item-tracker/rearrange-work-item",
  getUserRecentStatus: "/vem/user/status",
  getStatus: "/vem/user-activity/status",
  assignWorkItem: "/vem/work-item/take",
  updateSchedule: "/vem/user-activity/update-schedule",
  getSchedule: "/vem/user-activity/get-current-shift-break",
  getShiftBreakAndTimeZoneDetails : "/vem/break-templates/get-shift-break-timezone-details"
};

export const breakTemplate: breakEndpoints = {
  createBreak: "/vem/break-templates/create",
  editBreak: "/vem/break-templates/update",
  deleteBreak: "/vem/break-templates/delete",

  getBreakDetails: "/vem/break-templates/get",
  getAssociatedTeams: "/vem/break-templates/associated-teams"
};  



//PS_15-Function to formate the description
export const formatDescription = (description: string, size: number) => {
  if (description?.length > size) {
    return (description?.slice(0, size + 1) + "...")
  }
  return description
} 


export const userActivityService = {
  getAvailableTeams: "/vem/user-activity/teams",
  getUserActivity: "/vem/user-activity",
  getTeamCreatorsInfo: "/vem/user-activity/teams-creation",
  setOperatorStatus: "/vem/user-activity/update-status",
  getOperatorBreakSchedule: "/vem/user-activity/get-current-shift-break",
  overrideOperatorBreakSchedule: "/vem/user-activity/get-current-shift-break",
  getIndividualUserActivity: "/vem/user-activity/user",
  getUserActivityKPI: "/vem/user-activity-kpi",
  getIndividualUserActivityKPI: "/vem/user-activity/user/kpi"
}


export const userActivityNotifications = {
  getIndividualUserActivity: "?topics=IndividualUserActivity"
}

export const operatorNotifications = {
  getLiveWorkItemsNotification: "?topics=WorkItemQueue"
}

export const notificationSubscribe = {
  getLiveSubscribeNotification: "/vem-notification/subscribe"
}

export const userStatus = {
  IDLE: "Idle",
  WORKING: "Working",
  DND: "Dnd",
  BREAK: "Break",
  OFFLINE: "Offline"
}



export const equipmentCorrection: EquipmentCorrectionEndpoints = {
  escalatetoLane: "/vem/work-item/escalate-to-lane",
  fetchLayoutJson: "/orca-config/get-screen-layout",
  requeueWorkItem: "/requeue-work-item",
  websocket: "/vem-notification/subscribe",
  cameraFootage: "/camera-footage",
  getStatus: "/vem/user-activity/status",
  updateUserActivityStatus: "/vem/user-activity/update-status",
  completeWorkItem: "/vem/complete-work-item",
};

export const workItems = {
  getScheduleDetails: "/vem/user-activity/get-current-shift-break",
  updateActivityStatus: "/vem/user-activity/update-status",
  publishNotification: "/vem-notification/publish"
}

export const notificationWebSocket = {
  connectWebSocket: "/vem-notification/subscribe"
}
