// Import the necessary modules and types.
import Client from "../client/client";
import {  baseURL, teams } from "../constant";
import { CreateTeamsRequest, DeleteTeamRequest, fetchProfileImageRequest, fetchUserNamesRequest, GetAllTeamsRequest, GetTeamByIdRequest, UpdateTeamRequest } from "../Interfaces/team_interface";

export const getUserService = async (payload: fetchUserNamesRequest) => {
    // PS_BT_05: Invoke clientService function with method 'POST', endpoint, and payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${teams.getUsers}`,
            data: payload,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        return err;
    }
}

export const getProfileImageService = async (payload: fetchProfileImageRequest) => {
    // PS_BT_05: Invoke clientService function with method 'POST', endpoint, and payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${teams.getProfileImg}`,
            data: payload,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        return err;
    }
}

export const postTeamService= async (payload: CreateTeamsRequest) => {
    // PS_BT_05: Invoke clientService function with method 'POST', endpoint, and payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${teams.postTeam}`,
            data: payload,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        return err;
    }
}

export const getAllTeamsService= async (payload: GetAllTeamsRequest) => {
    // PS_BT_05: Invoke clientService function with method 'POST', endpoint, and payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${teams.getAllTeams}`,
            data: payload,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response?.data;
    }
    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        throw err;
    }
}

export const getTeamByIdService= async (payload: GetTeamByIdRequest) => {
    // PS_BT_05: Invoke clientService function with method 'POST', endpoint, and payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${teams.getTeamById}`,
            data: payload,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response?.data;
    }         

    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        return err;
    }
}


export const updateTeamService= async (payload: UpdateTeamRequest) => {
    // PS_BT_05: Invoke clientService function with method 'POST', endpoint, and payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${teams.updateTeam}`,
            data: payload,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        return err;
    }
}

export const deleteTeamService= async (payload: DeleteTeamRequest) => {
    // PS_BT_05: Invoke clientService function with method 'POST', endpoint, and payload.
    try {
        const config = {
            method: "post",
            url: `${baseURL}${teams.deleteTeam}`,
            data: payload,
        };

        // PS_BT_09: Return the response from the backend which includes status and break templates data.
        // PS_BT_11: Return the response (success or error) from the service layer back to the component for further processing.
        const response = await Client(config);
        return response;
    }
    catch (err) {
        // PS_BT_10: If an error occurs in the service layer, catch it and return an error response to the component.
        return err;
    }
}