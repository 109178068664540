import { baseURL, equipmentCorrection, } from "../constant";

function WebsocketClient(userId: string, topics: string[]): WebSocket {
  const queryParams = topics.map(topic => `topics=${encodeURIComponent(topic)}`).join("&");
  const url: any = baseURL
  const wsURL = url.replace(/^http(s)?:\/\//, "wss://");
  const conn = new WebSocket(
    `${wsURL}${equipmentCorrection.websocket}?userID=${encodeURIComponent(userId)}&${queryParams}`
  );
  return conn;
}

export default WebsocketClient;
