
/**
 * PS_53 - Component to render the toast Messages based on the toastType
 */
import { useEffect } from "react";

const ToastMessage = (props: any) => {
  const { setIsToast, toastType, toastMessage } = props.props;
  useEffect(() => {
    setTimeout(() => {
      setIsToast(false);
    }, 3000);
  })

  return (
    <>
      <div className={`container-fluid position-fixed toastContainer ${toastType === 'Error' ? 'warning-toast' : toastType === 'Success' ? 'success-toast' : ''}`}>
        <div className="p-4 toast-content d-flex align-items-center gap-3">
          {toastType === 'Error' ? (
            <img src="img/warning-icon-lg.svg" alt="warning" />
          ) : toastType === 'Success' ? (
            <img src="img/success-mark-icon.svg" alt="info" />
          ) : (
            <img src="img/info-icon-lg.svg" alt="info" />
          )}    <p className="m-0 font-bold font-16 color-white">{toastMessage}</p>
        </div>
      </div>
    </>
  );
};

export default ToastMessage;
