
const NoNotificationsFound: React.FC = () => {

    return (
        <div className="noti-container-empty d-flex align-items-center justify-content-center flex-column gap-1 py-5 w-100" style={{ height: "65vh" }}>
            <img src="img/notification-bell-icon-lg.svg" alt="notification" />
            <h4 className="font-semibold font-18 color-black m-0">
                No Notifications
            </h4>
            <p className="font-semibold font-13 color-grey-v1 m-0">
                Stay tuned for the updates and alerts.
            </p>
        </div>
    )
}
export default NoNotificationsFound